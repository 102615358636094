/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/extensions
import moment, { Moment } from 'moment';
import { IUserToken } from 'typings/store/generalTypes';
// import { parseDateToManage } from 'xpand-ui/utils/dates';
import { removeTimezone } from 'xpand-ui/utils/dates';

const parseDateToManage = (date: Date | Moment | string, clearHour = false): string =>
	clearHour
		? `${moment(removeTimezone(date)).format('YYYY/MM/DD')} 00:00:00`
		: moment(removeTimezone(date)).format('YYYY/MM/DD HH:mm:ss');

export const parseStartDateToString = (date: Date | string) => parseDateToManage(date, true);
export const parseEndDateToString = (date: Date | string) =>
	parseDateToManage(date, true).replace('00:00:00', '23:59:00');

export enum EventColors {
	UserValid = '#69c01a', // user + ok -> verde vivo
	UserNotValid = '#f6db18', // user + not OK -> amarelo
	NotUserValid = '#69c01a5e', // not user + OK -> verde claro
	NotUserNotValid = '#f6db185e', // not user + not OK -> amarelo claro
	BlockedSeat = '#ec5353' // Blocked Seat -> vermelho
}
export enum EventStyle {
	Validated = 'border',
	NotValidated = 'border'
}
const eventSchedulerDefaultProps = {
	resizable: false,
	milestoneWidth: 0,
	draggable: false,
	allDay: false,
	validated: true
};

export const getRandomInt = (min = 500000, max = 500000) => Math.floor(Math.random() * max) + min;

/* eslint-disable no-param-reassign */
export const sortDates = (s: Date, e: Date) => [s, e].sort((a: Date, b: Date) => a.valueOf() - b.valueOf());

export const parseEventData = (payload: any, loggedUsername: string, fromBackEnd = false) => {
	const startDate = new Date(parseStartDateToString(payload.startDate));
	const endDate = new Date(parseEndDateToString(payload.endDate));

	const belongsToUser = loggedUsername.toLowerCase() === payload.username.toLowerCase();
	const createdByUser = loggedUsername.toLowerCase() === payload.createdBy.toLowerCase();
	/* 
	 * The VALIDATED value comes from:
	 ? 1 - from the Backend -> then payload.validated
	 ? 2 - from a new booking -> if its the own user booking to itself
	*/

	const isValidated = (fromBackEnd && payload.validated) || (!fromBackEnd && belongsToUser && createdByUser);
	const isBlockedSeat = fromBackEnd && payload.blockedSeat;

	// UserValid = '#9ccc65', // verde
	// UserNotValid = '#ffd54f', // amarelo
	// NotUserValid = '#bedd9a', // verde claro
	// NotUserNotValid = '#ffe69b',// amarelo claro
	const event = {
		...payload,
		...eventSchedulerDefaultProps,
		validated: isValidated,
		allDay: true,
		duration: payload.duration,
		start: startDate,
		end: endDate,
		resource: payload.resourceId,
		resize: payload.resizable,
		title: payload.username,
		id: fromBackEnd ? payload.id : `${getRandomInt()}`,
		customId: fromBackEnd ? payload.id : `${getRandomInt()}`,
		color:
			(isBlockedSeat && EventColors.BlockedSeat) ||
			(belongsToUser && isValidated && EventColors.UserValid) ||
			(belongsToUser && !isValidated && EventColors.UserNotValid) ||
			(!belongsToUser && isValidated && EventColors.NotUserValid) ||
			(!belongsToUser && !isValidated && EventColors.NotUserNotValid)
	};
	return event;
};
