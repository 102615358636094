/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//* EXTERNAL LIBS --> MUI
import { Grid, IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { Select, InfoField } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';
import { Dialog } from 'xpand-ui/core';

//* TYPINGS
import { IUpdateCompanyAllocation, IUserProfile } from 'typings/store/personalInfoTypes';
import { HelperProps } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { prepareCompanyData } from 'lib/utils/erpUtils';
import { addNotification } from 'lib/utils/notifications';

//* LOCAL COMPONENT IMPORTS
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { CompanyAllocationProps } from '.';
import { getLSField } from 'lib/utils/cookies';

interface ICompanyAllocation extends CompanyAllocationProps {
	isAdmin?: string;
	userProfile: IUserProfile;
	handleApiSubmit: any;
}

const CompanyAllocation: FC<ICompanyAllocation> = ({
	projectsAndClients,
	getNewProjectData,
	isAdmin,
	userProfile,
	handleApiSubmit
}) => {
	const classes = useStyles();
	const { fetchedNewProjectData } = projectsAndClients;
	const [editCompany, setEditCompany] = useState<boolean | null>(null);
	const [newProjectHelper, setNewProjectHelper] = useState<HelperProps>({
		company: null,
		businessUnit: null,
		division: null,
		department: null
	});

	// form
	const {
		handleSubmit,
		control,
		reset,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(() => {
		if (isAdmin) {
			if (!fetchedNewProjectData) {
				getNewProjectData(true);
			} else {
				setNewProjectHelper({
					company: userProfile?.user?.company?.searchKey,
					businessUnit: userProfile?.user?.erpData?.xPHRAdOrgBunit,
					division: userProfile?.user?.erpData?.xphrAdOrgDivision,
					department: userProfile?.user?.erpData?.xphrAdOrgDepartment
				});

				reset({
					company: userProfile?.user?.company?.searchKey,
					businessUnit: userProfile?.user?.erpData?.xPHRAdOrgBunit,
					division: userProfile?.user?.erpData?.xphrAdOrgDivision,
					department: userProfile?.user?.erpData?.xphrAdOrgDepartment
				});
			}
		}
	}, [fetchedNewProjectData]);

	const onSubmit = (data: IUpdateCompanyAllocation) => {
		const originalData = {
			company: userProfile?.user?.company?.searchKey,
			businessUnit: userProfile?.user?.erpData?.xPHRAdOrgBunit,
			division: userProfile?.user?.erpData?.xphrAdOrgDivision,
			department: userProfile?.user?.erpData?.xphrAdOrgDepartment
		};

		if (JSON.stringify(data) !== JSON.stringify(originalData)) {
			handleApiSubmit([
				{
					type: 'companyAllocation',
					data
				}
			]);
		} else {
			// eslint-disable-next-line no-alert
			addNotification('info', 'Nothing to Update');
		}

		setEditCompany(false);

		return null;
	};

	// Data related to the Company option
	const companySelectOptions = useMemo(() => {
		if (!fetchedNewProjectData) {
			return {
				company: [],
				businessUnit: [],
				division: [],
				department: []
			};
		}
		return prepareCompanyData(newProjectHelper, fetchedNewProjectData);
	}, [fetchedNewProjectData, newProjectHelper?.company, newProjectHelper?.businessUnit, newProjectHelper?.division]);

	const companyModalActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setNewProjectHelper({
					company: userProfile?.user?.company?.searchKey,
					businessUnit: userProfile?.user?.erpData?.xPHRAdOrgBunit,
					division: userProfile?.user?.erpData?.xphrAdOrgDivision,
					department: userProfile?.user?.erpData?.xphrAdOrgDepartment
				});

				reset({
					company: userProfile?.user?.company?.searchKey,
					businessUnit: userProfile?.user?.erpData?.xPHRAdOrgBunit,
					division: userProfile?.user?.erpData?.xphrAdOrgDivision,
					department: userProfile?.user?.erpData?.xphrAdOrgDepartment
				});
				setEditCompany(false);
			}
		},
		{
			id: 'submit',
			label: 'Save',
			color: 'primary',
			type: 'submit',
			form: 'form-edit-user-department',
			variant: 'contained',
			onClick: () => ({})
		}
	];

	return (
		<Grid item {...sizes[12]} className={classes.leftCardSection}>
			{isAdmin && (
				<Dialog
					title="Edit user department"
					actions={companyModalActions}
					scroll="body"
					modal={{
						// Sending Edit or Add mode
						open: Boolean(editCompany),
						handleClose: (event: unknown, reason: string) => {
							if (reason !== 'backdropClick') setEditCompany(false);
						},
						content: (
							<form id="form-edit-user-department" onSubmit={handleSubmit(onSubmit)}>
								<Grid container spacing={2} style={{ padding: '10px 5px 40px 5px' }}>
									<Grid item xs={12}>
										<Select
											name="businessUnit"
											label="Business Unit"
											options={companySelectOptions.businessUnit}
											additionalOnChange={(item: { value: string }) => {
												setNewProjectHelper(prev => ({
													...prev,
													businessUnit: item?.value,
													division: null,
													department: null
												}));
												setValue('division', '');
												setValue('department', '');
											}}
											control={control}
											errors={errors}
											required
										/>
									</Grid>
									<Grid item xs={12}>
										<Select
											name="division"
											label="Division"
											options={companySelectOptions.division}
											additionalOnChange={(item: { value: string }) => {
												setNewProjectHelper(prev => ({
													...prev,
													division: item.value,
													department: null
												}));
												setValue('department', '');
											}}
											control={control}
											errors={errors}
											required
										/>
									</Grid>
									<Grid item xs={12}>
										<Select
											name="department"
											label="Department"
											options={companySelectOptions.department}
											additionalOnChange={(item: { value: string }) => {
												setNewProjectHelper(prev => ({
													...prev,
													department: item.value
												}));
											}}
											control={control}
											errors={errors}
											required
										/>
									</Grid>
								</Grid>
							</form>
						)
					}}
				/>
			)}
			<InfoField
				fontSize="lg"
				label="OFFICE"
				value={userProfile?.user?.erpData?.xphrEmployeeWorkOfficeDescription || 'N/A'}
			/>
			<br />

			<InfoField
				fontSize="lg"
				label="COMPANY"
				value={
					userProfile?.external && userProfile?.user?.ldapData
						? userProfile.user.ldapData.companyAzureDescription
						: userProfile?.user?.company?.name
				}
			/>
			<br />
			<InfoField
				fontSize="lg"
				label={
					<>
						BUSINESS UNIT
						{isAdmin && !getLSField('impersonate_userInfo') && !userProfile?.external && (
							<IconButton
								color="yale"
								aria-label="editCompanyAndBusinessUnit"
								className={classes.editButton}
								onClick={() => setEditCompany(true)}>
								<Edit />
							</IconButton>
						)}
					</>
				}
				value={userProfile?.user?.erpData?.xPHRAdOrgBunit$_identifier || 'N/A'}
			/>
			<br />
			<InfoField
				fontSize="lg"
				label="DIVISION"
				value={userProfile?.user?.erpData?.xphrAdOrgDivision$_identifier || 'N/A'}
			/>
			<br />
			<InfoField
				fontSize="lg"
				label="DEPARTMENT"
				value={userProfile?.user?.erpData?.xphrAdOrgDepartment$_identifier || 'N/A'}
			/>
		</Grid>
	);
};

export default CompanyAllocation;
