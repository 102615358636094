/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Control } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { Grid, Button, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import Adicionar from '../../../../../../assets/icons/Adicionar.svg';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { PageTitle } from 'xpand-ui/core';
import { Select } from 'xpand-ui/forms';

//* TYPINGS
import { IGrantAccessControlInfo, IOrgAccessesCompare } from 'typings/store/admin/administration';
import { IChoosableBaseInfo } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

// import { prepareCompanyData } from 'lib/utils/erpUtils';
import { prepareCompanyData } from './utils';
import { useStyles } from './styles';

interface IOrgAccesses {
	control: Control;
	errors: Record<string, Record<string, Record<string, string>>>;
	watch: any;
	reset: (fields: any) => void;
	setValue: (key: string, payload: unknown, configs?: any) => void;
	payload: IGrantAccessControlInfo;
}

const OrgAccesses: FC<IOrgAccesses> = ({ control, errors, watch, reset, setValue, payload }) => {
	const classes = useStyles();
	// sempre que houver actualizações no form, a info vai para esta variavel (isto nao causa novo render)
	const orgAccessesList: IOrgAccessesCompare[] = watch('orgAccesses', []);

	// helper para controlar a company tree de cada linha
	const [companyHelper, setCompanyHelpers] = useState({});

	useEffect(() => {
		if (payload?.user?.orgAccesses) {
			// mutate initial value by removing 'id', fix company assignment, and add uniqueId
			const initialValue = payload.user.orgAccesses.map(({ id, ...e }) => ({
				...e,
				company: e?.company?.searchKey,
				uniqueId: uuidv4()
			}));

			// set fixed array from API to form field
			setValue('orgAccesses', initialValue);

			const newCompanyHelper = {};
			// loop through all the items from the API
			initialValue.forEach(orgAcc => {
				// assign each individual field/attribute to the company helper
				newCompanyHelper[orgAcc.uniqueId] = {};
				// get the keys, remove the uniqueId, and assign each individual value to the helper
				Object.keys(orgAcc)
					.filter(key => key !== 'uniqueId')
					.forEach(key => {
						newCompanyHelper[orgAcc.uniqueId][key] = orgAcc[key];
					});
			});

			setCompanyHelpers(newCompanyHelper);
		}
	}, [payload]);

	//If exists older business unit resets fields related to it
	useEffect(() => {
		orgAccessesList.map((item, index) => {
			const name = `orgAccesses[${index}].`;
			if (
				watch(`${name}company`) == 'PT' &&
				companySelectOptions[item.uniqueId]?.businessUnit
					?.filter(
						e =>
							e.id !== '557D9484E0C145948EA158D44DB3CB69' &&
							e.id !== '35AE5D04172749B58BD5655E65B9FAD2' &&
							e.id !== '08696BAD1A8F41FC9DF0165D31C65D63' &&
							e.id !== 'C41D5025CD334655A0C066447E022621' &&
							e.id !== 'AE10005AA2FF4265BBCF39D19C6A3CE5' &&
							e.id !== '6C0EBDB505CF4C0EA4EE172BBCD4953B' &&
							e.id !== '11D835BCD8174D3B8A65D2D9313A1A4B'
					)
					.find(e => e.id == watch('businessUnit')) == undefined
			) {
				setValue(`${name}businessUnit`, '0');
				setValue(`${name}division`, '0');
				setValue(`${name}department`, '0');
			}
		});
	}, [orgAccessesList]);

	// adicionar ou remover elementos do form, com um reset pcausa dos ID's
	const updateFormState = (newPayload: IOrgAccessesCompare[]) => {
		const form = watch();
		reset({ ...form, orgAccesses: newPayload });
	};

	// adicionar novo elemento ao form
	const addOrgAccess = () => {
		// helper Id
		const newId = uuidv4();
		// add new default values for company tree helper (selects)
		setCompanyHelpers(prev => ({
			...prev,
			[newId]: { company: '0', businessUnit: '0', division: '0', department: '0' }
		}));
		// update form
		updateFormState([
			...orgAccessesList,
			{ uniqueId: newId, company: '0', businessUnit: '0', division: '0', department: '0' }
		]);
	};

	// remover elemento do form
	const removeOrgAccess = (uniqueId: string | number) => {
		// new list without deleted value
		const newPayload: IOrgAccessesCompare[] = orgAccessesList.filter(e => e.uniqueId !== uniqueId);
		// remove values on company tree helper (selects)
		setCompanyHelpers(prev => {
			const newState = { ...prev };
			delete newState[uniqueId];
			return newState;
		});

		// update form
		updateFormState(newPayload);
	};

	// generate select tree for each row
	const companySelectOptions = useMemo(() => {
		const selectOptions = {};

		// loop through all lines
		Object.keys(companyHelper).forEach(uniqueId => {
			// generate company tree for current line identified by 'uniqueID'
			selectOptions[uniqueId] = prepareCompanyData(companyHelper[uniqueId], payload);
		});

		return selectOptions;
	}, [payload, companyHelper, orgAccessesList]);
	return (
		<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
			{/* ADD MORE ORG ACCESSES */}
			<PageTitle title="Organizational Access" />
			{/* ADD MORE ORG ACCESSES */}
			<Grid item {...sizes[12]}>
				<Button
					className={classes.addButton}
					color="primary"
					variant="text"
					endIcon={<Adicionar />}
					onClick={() => addOrgAccess()}>
					Add Organizational Access
				</Button>
			</Grid>
			{/* ORG ACCESSES */}
			<Grid item {...sizes[12]}>
				{orgAccessesList.map((item, index) => {
					const name = `orgAccesses[${index}].`;
					return (
						<Grid
							container
							key={item.uniqueId}
							className={classes.inputsGroup}
							direction="row"
							justifyContent="flex-start"
							spacing={2}
							alignItems="flex-start">
							<Grid item {...sizes[2]}>
								<Select
									label="Company"
									name={`${name}company`}
									options={
										(companySelectOptions[item?.uniqueId] &&
											companySelectOptions[item.uniqueId].company) ||
										[]
									}
									additionalOnChange={({ value }: { value: string }) => {
										setCompanyHelpers(prev => ({
											...prev, // other lines
											[item.uniqueId]: {
												// copy current line, and change what is necessary
												...prev[item.uniqueId],
												company: value,
												businessUnit: '0',
												division: '0',
												department: '0'
											}
										}));
										setValue(`${name}businessUnit`, '0');
										setValue(`${name}division`, '0');
										setValue(`${name}department`, '0');
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[2]}>
								<Select
									label="Business Unit"
									name={`${name}businessUnit`}
									options={
										(companySelectOptions[item.uniqueId] && watch(`${name}company`) == 'PT'
											? companySelectOptions[item.uniqueId].businessUnit.filter(
													e =>
														e.id !== '557D9484E0C145948EA158D44DB3CB69' &&
														e.id !== '35AE5D04172749B58BD5655E65B9FAD2' &&
														e.id !== '08696BAD1A8F41FC9DF0165D31C65D63' &&
														e.id !== 'C41D5025CD334655A0C066447E022621' &&
														e.id !== 'AE10005AA2FF4265BBCF39D19C6A3CE5' &&
														e.id !== '6C0EBDB505CF4C0EA4EE172BBCD4953B' &&
														e.id !== '11D835BCD8174D3B8A65D2D9313A1A4B'
											  )
											: companySelectOptions[item.uniqueId].businessUnit) || []
									}
									additionalOnChange={({ value }: { value: string }) => {
										setCompanyHelpers(prev => ({
											...prev, // other lines
											[item.uniqueId]: {
												// copy current line, and change what is necessary
												...prev[item.uniqueId],
												businessUnit: value,
												division: '0',
												department: '0'
											}
										}));
										setValue(`${name}division`, '0');
										setValue(`${name}department`, '0');
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[2]}>
								<Select
									label="Division"
									name={`${name}division`}
									options={
										(companySelectOptions[item.uniqueId] &&
											companySelectOptions[item.uniqueId].division) ||
										[]
									}
									additionalOnChange={({ value }: { value: string }) => {
										setCompanyHelpers(prev => ({
											...prev, // other lines
											[item.uniqueId]: {
												// copy current line, and change what is necessary
												...prev[item.uniqueId],
												division: value,
												department: '0'
											}
										}));
										setValue(`${name}department`, '0');
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[2]}>
								<Select
									label="Department"
									name={`${name}department`}
									options={
										(companySelectOptions[item.uniqueId] &&
											companySelectOptions[item.uniqueId].department) ||
										[]
									}
									additionalOnChange={({ value }: { value: string }) => {
										setCompanyHelpers(prev => ({
											...prev, // other lines
											[item.uniqueId]: {
												// copy current line, and change what is necessary
												...prev[item.uniqueId],
												department: value
											}
										}));
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[2]}>
								<IconButton
									className={classes.orgAccessDeleteIcon}
									onClick={() => removeOrgAccess(item.uniqueId as string)}>
									<Delete />
								</IconButton>
							</Grid>
						</Grid>
					);
				})}
			</Grid>
		</Grid>
	);
};

export default withLayout(OrgAccesses);
