import * as yup from 'yup';
import moment from 'moment';

import { parseDateToShow } from 'xpand-ui/utils/dates';

import { string, booleanRequired, dateDefault, stringRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	startDate: dateDefault
		.typeError('Start date must be a valid date')
		.min(new Date('1900-01-01'), 'Invalid Start Date')
		.max(new Date('2099-12-31'), 'Invalid Start Date')
		.max(yup.ref('endDate'), ({ max }) =>
			moment(max).isValid() ? `Start date must be before ${parseDateToShow(max as string)}` : 'Invalid Start Date'
		),
	endDate: dateDefault
		.typeError('End date must be a valid date')
		.min(new Date('1900-01-01'), 'Invalid End Date')
		.max(new Date('2099-12-31'), 'Invalid End Date')
		.min(yup.ref('startDate'), ({ min }) =>
			moment(min).isValid() ? `End date must be after ${parseDateToShow(min as string)}` : 'Invalid End Date'
		),
	offices: yup.array().of(string).nullable(),
	projects: yup.array().of(string).nullable(),
	users: yup.array().of(string).nullable(),
	skills: yup.array().of(string).nullable(),
	showAvailableOnly: booleanRequired,
	showTimeOff: booleanRequired,
	project: string,
	projectLabel: string,
	allocation: yup
		.number()
		.typeError('Allocation must be a number')
		.required('Allocation is required')
		.min(0, 'Allocation must be at least 0')
		.max(100, 'Allocation must not exceed 100'),
	color: stringRequired,
	startDateFilter: yup
		.date()
		.nullable()
		.notRequired()
		.transform((value, originalValue) => {
			// If original value is empty or invalid, return null
			const isValidDate = originalValue && !isNaN(new Date(originalValue).getTime());
			return isValidDate ? new Date(originalValue) : null;
		})
		.min(new Date('1900-01-01'), 'Invalid Start Date')
		.max(new Date('2099-12-31'), 'Invalid Start Date'),
	endDateFilter: yup
		.date()
		.nullable()
		.notRequired()
		.transform((value, originalValue) => {
			// If original value is empty or invalid, return null
			const isValidDate = originalValue && !isNaN(new Date(originalValue).getTime());
			return isValidDate ? new Date(originalValue) : null;
		})
		.min(new Date('1900-01-01'), 'Invalid End Date')
		.max(new Date('2099-12-31'), 'Invalid End Date')
});

export const defaultValues = {
	startDate: '',
	endDate: '',
	offices: [] as string[],
	projects: [] as string[],
	users: [] as string[],
	skills: [] as number[],
	showAvailableOnly: false,
	project: 'project', //just to prevent error message after form being opened
	projectLabel: 'New event',
	showTimeOff: true,
	allocation: 100,
	color: 'red',
	startDateFilter: '',
	endDateFilter: ''
};
