// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		root: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			gap: '20px',
			height: 'calc(100vh - 200px)'
		},
		// Tooltip styles
		mdsTooltip: {
			backgroundColor: '#4e4e4e',
			color: '#fff',
			borderRadius: '4px',
			padding: '8px',
			fontFamily: 'Arial, sans-serif',
			fontSize: '14px',
			maxWidth: '350px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		},
		mdsTooltipHeader: {
			fontWeight: 'bold',
			paddingBottom: '5px',
			backgroundColor: '#4e4e4e'
		},
		mdsTooltipContent: {
			display: 'flex',
			flexDirection: 'column'
		},
		mdsTooltipContentItem: {
			display: 'flex',
			alignItems: 'center',
			gap: '8px',
			marginTop: '4px'
		},
		mdsTooltipIcon: {
			width: '18px',
			height: '18px',
			color: '#87cefa'
		}
	})
);

export { useStyles };
