//* EXTERNAL LIBS
import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import generator from 'generate-password-browser';
import { yupResolver } from '@hookform/resolvers/yup';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Control, FieldErrors, useForm, UseFormWatch } from 'react-hook-form';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter, FormSteps, TabPanel } from 'xpand-ui/core';

//* TYPINGS
import { INewEmployee } from 'typings/store/admin/users';

//* PROJECT IMPORTS [LIB / PAGES ]
import { calculateVacationDays } from 'lib/utils/calcFormulasUtils';
import { isTouchedFieldsUsed } from 'lib/utils/formUtils';
//* LOCAL COMPONENT IMPORTS
import { handleErrorPage } from 'lib/hocs/withLayout';
import Prompt from '../../../../components/Prompt/Prompt';
import { HelperProps } from 'typings/store/generalTypes';

// Tabs for StepsForm
import BasicInfo from './tabs/BasicInfo';
import EmployeeCompanyProfile from './tabs/EmployeeCompanyProfile';
import WhereEmployeeBelongs from './tabs/WhereEmployeeBelongs';
import { schema, defaultValues, tabs } from './yupSchema';

import { useStyles } from './styles';
import { NewEmployeeProps } from '.';
import { getLSField } from 'lib/utils/cookies';

export interface INewEmployeeTabs {
	// eslint-disable-next-line
	[x: string]: any;
	control: Control<INewEmployee>;
	errors: FieldErrors<INewEmployee>;
	watch: UseFormWatch<INewEmployee>;
	// eslint-disable-next-line
	companySelectOptions: any;
	actions: Array<{ id: string; label: string }>;
	setCompanyHelper: React.Dispatch<React.SetStateAction<HelperProps>>;
}

//* COMPONENT
const NewEmployee: FC<NewEmployeeProps> = ({ users, getUsersAdd, sendUsersAdd, clearUsersError }) => {
	const classes = useStyles();
	const { reducerUsersAdd, loading, error, responseCode } = users;
	const [tabSelected, setTabSelected] = useState(0);
	const [submitClick, setSubmitClick] = useState(false);
	const [formWarnings, setFormWarnings] = useState<Array<number>>([]);
	const scrollToTopRef = useRef<HTMLDivElement | any>(null);
	const scrollbarRef = React.createRef();

	useEffect(() => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollToTop();
		}
	}, [tabSelected]);

	useEffect(() => {
		scrollToTopRef?.current?.scrollIntoView(0, 0);
	});

	const [companyHelper, setCompanyHelper] = useState<{
		company: string | null;
		businessUnit: string | null;
		division: string | null;
		department: string | null;
	}>({
		company: null,
		businessUnit: null,
		division: null,
		department: null
	});

	const {
		handleSubmit,
		control,
		reset,
		resetField,
		watch,
		getValues,
		setValue,
		formState: { errors, touchedFields }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const genPassword = () => {
		const pwd = generator.generate({
			length: 10,
			lowercase: true,
			uppercase: true,
			numbers: true,
			symbols: true,
			strict: true,
			exclude: '«»çÇ€£§´`^~'
		});
		setValue('password', pwd);
	};

	const setVacationDays = (hiredDate?: string) => {
		const vacationDays = calculateVacationDays(hiredDate);
		setValue('vacationDays', vacationDays.toString(), { shouldValidate: true });
	};

	useEffect(() => {
		if (!error && !reducerUsersAdd) {
			getUsersAdd();
		} else {
			reset({ ...getValues(), userNames: reducerUsersAdd?.users });
		}
	}, [reducerUsersAdd]);

	const hiredDate = watch('hiredDate');

	useEffect(() => {
		setVacationDays(hiredDate);
	}, [hiredDate]);

	const onSubmit = () => {
		const payload: INewEmployee = watch();
		delete payload.userNames;
		delete payload.employeeManagers;
		sendUsersAdd(payload);
	};

	//Refresh all information of the form when is submitted correctly
	//If is not submitted currently the information remains the same
	useEffect(() => {
		if (responseCode === 200) {
			reset(defaultValues);
			setFormWarnings([]);
			setTabSelected(0);
		}
	}, [responseCode]);

	const companySelectOptions = useMemo(() => {
		if (!reducerUsersAdd) {
			return {
				company: [],
				businessUnit: [],
				division: [],
				department: []
			};
		}
		if (companyHelper.division) {
			const company = reducerUsersAdd.companies.find(e => e.searchKey === companyHelper.company);
			const businessUnits = company ? company.companyTree : null;

			const divisions =
				(businessUnits && businessUnits.childNodes.find(e => e.organization === companyHelper.businessUnit)) ||
				null;

			const departments =
				(divisions && divisions.childNodes.find(e => e.organization === companyHelper.division)) || null;

			if (businessUnits && divisions && departments) {
				return {
					company: reducerUsersAdd.companies.map(e => ({ id: e.searchKey, label: e.name })),
					businessUnit: businessUnits.childNodes.map(e => ({
						id: e.organization,
						label: e.organization$_identifier
					})),
					division: divisions.childNodes.map(e => ({
						id: e.organization,
						label: e.organization$_identifier
					})),
					department: departments.childNodes.map(e => ({
						id: e.organization,
						label: e.organization$_identifier
					}))
				};
			}
		}
		if (companyHelper.businessUnit) {
			const company = reducerUsersAdd.companies.find(e => e.searchKey === companyHelper.company);

			const businessUnits = company ? company.companyTree : null;

			const divisions =
				(businessUnits && businessUnits.childNodes.find(e => e.organization === companyHelper.businessUnit)) ||
				null;

			if (businessUnits && divisions) {
				return {
					company: reducerUsersAdd.companies.map(e => ({ id: e.searchKey, label: e.name })),
					businessUnit: businessUnits.childNodes.map(e => ({
						id: e.organization,
						label: e.organization$_identifier
					})),
					division: divisions.childNodes.map(e => ({
						id: e.organization,
						label: e.organization$_identifier
					})),
					department: []
				};
			}
		}
		if (companyHelper.company) {
			const company = reducerUsersAdd.companies.find(e => e.searchKey === companyHelper.company);
			const businessUnits = company ? company.companyTree : null;

			if (businessUnits) {
				return {
					company: reducerUsersAdd.companies.map(e => ({ id: e.searchKey, label: e.name })),
					businessUnit: businessUnits.childNodes.map(e => ({
						id: e.organization,
						label: e.organization$_identifier
					})),
					division: [],
					department: []
				};
			}
		}
		return {
			company: reducerUsersAdd.companies.map(e => ({ id: e.searchKey, label: e.name })),
			businessUnit: [],
			division: [],
			department: []
		};
	}, [reducerUsersAdd, companyHelper.company, companyHelper.businessUnit, companyHelper.division]);

	const formSelectOptions = useMemo(
		() => ({
			prefixes: reducerUsersAdd?.prefixes?.map(e => ({ id: e.id, label: e.name })) || [],
			locations: reducerUsersAdd?.locations?.map(e => ({ id: e.id, label: e.name })) || [],
			holidayTypes: reducerUsersAdd?.holidayTypes?.map(e => ({ id: e.id, label: e.name })) || [],
			timeOffApprovalTypes: reducerUsersAdd?.timeOffApprovalTypes?.map(e => ({ id: e.id, label: e.name })) || [],
			timeoffPolicies: reducerUsersAdd?.timeoffPolicies?.map(e => ({ id: e.id, label: e.name })) || [],
			employeeManagers: reducerUsersAdd?.employeeManagers?.map(e => ({ id: e.id, label: e.name })) || []
		}),
		[reducerUsersAdd]
	);

	// 	CONSTANTS
	const footerActions = [
		...(tabSelected !== 0
			? [
					{
						id: 'previous',
						label: 'Previous',
						// left: true,
						onClick: () => {
							setTabSelected(tabSelected - 1);
							if (formWarnings.includes(tabSelected)) {
								setFormWarnings(formWarnings.filter(e => e !== tabSelected));
							}
							scrollToTopRef.current.scrollIntoView({
								behavior: 'smooth',
								block: 'start'
							});
						},
						variant: 'outlined',
						disabled: false
					}
			  ]
			: []),
		...(tabSelected !== 2
			? [
					{
						id: 'next',
						label: 'Next',
						onClick: () => {
							setTabSelected(tabSelected + 1);
							/* Checking if the tabSelected is in the formWarnings array. If it is, it is removing it from
							the array. */

							if (formWarnings.includes(tabSelected)) {
								setFormWarnings(formWarnings.filter(e => e !== tabSelected));
							}
							scrollToTopRef.current.scrollIntoView({
								behavior: 'smooth',
								block: 'start'
							});
						},
						variant: 'contained',
						disabled: false
					}
			  ]
			: []),
		...(tabSelected === 2
			? [
					{
						id: 'submit',
						endIcon: 'submit',
						label: 'Submit',
						onClick: () => {
							setSubmitClick(true);
						},
						type: 'submit',
						form: 'form-new-user',
						variant: 'contained',
						disabled: getLSField('impersonate_userInfo')
					}
			  ]
			: [])
	];

	if (error) return handleErrorPage(error, clearUsersError);

	const isLoading = reducerUsersAdd === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<div className={classes.root}>
				<form ref={scrollToTopRef} id="form-new-user" onSubmit={handleSubmit(onSubmit)}>
					<FormSteps steps={tabs} selected={tabSelected} errors={formWarnings} style={{ width: '90%' }} />
					<Scrollbars
						ref={scrollbarRef}
						style={{ height: 'calc(95vh - 280px)', overflowX: 'hidden', width: '90%' }}
						renderTrackHorizontal={(props: any) => (
							<div {...props} style={{ display: 'none' }} className="track-horizontal" />
						)}>
						{tabs.map(tab => {
							const commonProps = {
								// react-hook-form helpers
								control,
								errors,
								reset,
								resetField,
								getValues,
								setValue,
								watch
							};

							return (
								<TabPanel key={`proposal_tab_container_${tab.id}`} value={tabSelected} index={tab.id}>
									{tab.id === 0 && <BasicInfo {...commonProps} />}
									{tab.id === 1 && (
										<WhereEmployeeBelongs
											companySelectOptions={companySelectOptions}
											setCompanyHelper={setCompanyHelper}
											{...commonProps}
										/>
									)}
									{tab.id === 2 && (
										<EmployeeCompanyProfile
											additionalErrors={errors}
											formSelectOptions={formSelectOptions}
											genPassword={genPassword}
											setVacationDays={setVacationDays}
											handleWarning={(names: unknown, tabsWithWarn: number[]) =>
												setFormWarnings(tabsWithWarn)
											}
											submitClick={submitClick}
											setSubmitClick={setSubmitClick}
											{...commonProps}
										/>
									)}
								</TabPanel>
							);
						})}
					</Scrollbars>
				</form>
			</div>
			<ActionFooter actions={footerActions} />
			<Prompt
				when={isTouchedFieldsUsed(touchedFields, getValues)}
				message="Are you sure you want to leave without saving?"
			/>
		</>
	);
};

export default NewEmployee;
