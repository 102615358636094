import React, { ComponentType, Suspense } from 'react';
import { Navigate } from 'react-router';
import { getLSField } from 'lib/utils/cookies';
import { useIdToken } from 'pages/General/Login/utils';

const checkRolesNeeded = (roles: string[], perms: Record<string, string>[]): boolean =>
	roles.some(roleNeeded => perms.some(({ displayName }) => displayName.toLowerCase() === roleNeeded.toLowerCase()));

interface AuthProps {
	path: string;
	redirect?: string;
	roles?: string[];
	title?: string;
	component: ComponentType<any>; // Ensure component is passed as a reference
}

const AuthRoute: React.FC<AuthProps> = ({ redirect, roles, component: Component, title }: AuthProps) => {
	const isAuthed = useIdToken();
	const userPermStorage = getLSField('impersonate_userPermissions')
		? getLSField('impersonate_userPermissions')
		: getLSField('userPermissions');

	const userPerms = (userPermStorage && JSON.parse(userPermStorage)) || null;

	// Update document title if provided
	if (title) {
		document.title = title;
	}

	// Redirect immediately if a redirect path is specified
	if (redirect) return <Navigate to={redirect} />;

	// Check user permissions if authenticated and roles are specified
	if (isAuthed && roles && !checkRolesNeeded(roles, userPerms)) {
		return <Navigate to="/403" />;
	}

	// Render the component if the user is authenticated and has the correct roles
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Component />
		</Suspense>
	);
};

export default AuthRoute;
