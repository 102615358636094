//* EXTERNAL LIBS
import React, { useState, useCallback, Dispatch, SetStateAction, FC, useEffect, useMemo, ChangeEvent } from 'react';

//* EXTERNAL LIBS --> MUI
import { FormControl, FormGroup, FormControlLabel, Checkbox, Button, Grid, List, Typography } from '@mui/material';
import {
	RadioButtonUnchecked,
	CheckCircle,
	CancelOutlined,
	HourglassEmpty,
	StarBorder,
	Check
} from '@mui/icons-material';

import Adicionar from '../../../../assets/icons/Adicionar.svg';
import Joyride from 'react-joyride-react-19';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog } from 'xpand-ui/core';
import { DatePicker, Select as LibSelect, Input, Numeric } from 'xpand-ui/forms';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { IUserToken } from 'typings/store/generalTypes';
import { ITitle } from 'components/App/TitleProvider';
import { ITableAction, ITableColumn, ITableColumnsFilter } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { MyExpensesProps } from '.';
import { getLSField } from 'lib/utils/cookies';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './Expenses.css';
import { IAdmissionFoundStatus } from 'typings/store/admin/admissions';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ExpenseSheetAudit from './ExpenseSheetAudit';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues, schema } from './yupSchema';
import { format, isAfter, isBefore } from 'date-fns';
import { cleanGuides } from 'store/guides/actions';
import { hasPermission } from './utils';
import { Roles } from 'lib/roles';

//* COMPONENT INTERFACES

//* COMPONENT INTERFACES
const approvalStatusOptions = [
	{ id: 6, label: 'All Expenses' },
	{ id: 1, label: 'All Open Expenses' },
	{ id: 2, label: 'Not Submitted' },
	{ id: 3, label: 'Submitted' },
	{ id: 4, label: 'Approved' },
	{ id: 5, label: 'Rejected' }
];

// Interface for MyExpenses component props
interface IMyExpenses extends MyExpensesProps {
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
	title: ITitle;
	guides: any;
	setTitle: Dispatch<SetStateAction<ITitle>>;
}

interface IEmployeeSelect {
	id: string;
	label: string;
}

// Prepare the dates for the new Expense Sheet Date select (Last day of current and previous month)
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1; // Month starts from 0
const lastDayOfCurrentMonth = new Date(currentYear, currentMonth, 0);
const lastDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 0);

const formatDate = date => {
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
};

const startDateOptions = [
	{
		id: formatDate(lastDayOfCurrentMonth),
		label: format(lastDayOfCurrentMonth, 'dd/MM/yyyy')
	},
	{
		id: formatDate(lastDayOfPreviousMonth),
		label: format(lastDayOfPreviousMonth, 'dd/MM/yyyy')
	}
];

//* COMPONENT
const MyExpenses: FC<IMyExpenses> = ({
	loggedUser,
	personalInfo,
	filters,
	guides,
	getExpensesData,
	deleteExpenseData,
	submitNewExpenseSheet,
	copyExpenseSheet,
	getGuideData,
	getUserGuideData,
	setUserGuideAsRead,
	setMyExpensesPageFilter
}) => {
	const classes = useStyles();
	const { myExpensesFilter } = filters;
	const { guide, userGuide } = guides;
	const dispatch = useDispatch();
	const expensesFilters = JSON.parse(localStorage.getItem('expensesFilters'));
	const [filterUser, setFilterUser] = useState<string>(
		localStorage.getItem('expensesFilters') ? expensesFilters.employee : loggedUser.username.toUpperCase()
	);
	const [filterStatus, setFilterStatus] = useState(6);
	const [statusFilter, setStatusFilter] = useState({ 0: false, 1: false, 2: false, 3: false });
	const [mainExpensesData, setMainExpensesData] = useState();
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [employeesList, setEmployeesList] = useState<IEmployeeSelect[]>([]);

	// New Expense Related
	const [openModalNewExpenseSheet, setOpenModalNewExpenseSheet] = useState(false);
	const [newSheetEmployee, setNewSheetEmployee] = useState(loggedUser.username.toUpperCase());
	const [newSheetStartDate, setNewSheetStartDate] = useState(formatDate(lastDayOfCurrentMonth));
	const [auditModal, setAuditModalModal] = useState<String | null>(null);
	const [newExpenseSheetConfirmation, setNewExpenseSheetConfirmation] = useState(false);
	// Copy Expense Related
	const [openModalCopyExpenseSheet, setOpenModalCopyExpenseSheet] = useState(false);
	const [copyExpenseSheetId, setCopyExpenseSheetId] = useState('');

	const { expensesData, newExpenseSheet, copiedExpenseSheetId, loading } = personalInfo;
	const userInfoStorage = getLSField('userInfo');
	const userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;
	// Delete Warning Modal
	const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
	const [expenseSheetToBeDeleted, setExpenseSheetToBeDeleted] = useState({});

	const navigate = useNavigate();
	const goToPage = (path: string) => navigate(path);

	useEffect(() => {
		// Clean the guides when the component unmounts or when a new page is loaded
		return () => {
			dispatch(cleanGuides());
		};
	}, [dispatch]);

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		getValues,
		setValue,
		reset
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues: defaultValues,
		shouldUnregister: false
	});

	// Status value helper
	const getStatusValue = status => {
		switch (status) {
			case 'Not Submitted':
				return 0;
			case 'Submitted':
				return 1;
			case 'Approved':
				return 2;
			case 'Rejected':
				return 3;
			default:
				return 0; // Handle any unexpected status value
		}
	};

	useEffect(() => {
		getGuideData('MyExpenses');
	}, []);

	useEffect(() => {
		getUserGuideData(loggedUser.username, 'MyExpenses');
	}, []);

	const [{ run, steps }, setState] = useState({
		run: true,
		steps: []
	});

	const handleJoyrideCallback = data => {
		const { action, index, type } = data;

		if (action === 'reset') {
			// Joyride finished
			setUserGuideAsRead(loggedUser.username, 'MyExpenses');
		}
	};

	// Get Page Guide
	useEffect(() => {
		if (guide && userGuide?.data?.userGuide?.active) {
			setState(prevState => ({
				...prevState,
				steps:
					guide?.data?.guide?.guideSteps?.map((step, index) => ({
						content: <div dangerouslySetInnerHTML={{ __html: step.content }} />,
						placement: step.placement,
						target: step.target,
						title: step.title,
						disableScrolling: step.disableScrolling,
						spotlightClicks: step.spotlightClicks,
						key: index
					})) || []
			}));
		}
	}, [guide, userGuide]);

	// Loads expenses
	useEffect(() => {
		if (expensesData) {
			setMainExpensesData(
				expensesData.results?.expensesList?.map(expense => ({
					...expense,
					expensesStatus: getStatusValue(expense.status)
				}))
			);
			if (localStorage.getItem('expensesFilters')) {
				const expensesFilters = JSON.parse(localStorage.getItem('expensesFilters'));
				setValue('employee', expensesFilters.employee);
				setValue('approvalStatus', expensesFilters.approvalStatus);
				setValue('newSheetEmployee', expensesFilters.newSheetEmployee);
				setValue('newSheetStartDate', expensesFilters.newSheetStartDate);
				setValue('startDate', expensesFilters.startDate);
				setValue('endDate', expensesFilters.endDate);
				setFilterUser(expensesFilters.employee);
				setStartDate(new Date(expensesFilters.startDate));
				setEndDate(new Date(expensesFilters.endDate));
				setFilterStatus(expensesFilters.approvalStatus);
				localStorage.removeItem('expensesFilters');
			}
			if (!localStorage.getItem('expensesFilters')) {
				setValue('employee', filterUser);
				setValue('approvalStatus', filterStatus);
				setValue('newSheetEmployee', watch('employee'));
				setValue('newSheetStartDate', formatDate(lastDayOfCurrentMonth));
			}
		}
	}, [expensesData]);
	// Redirect after copy
	useEffect(() => {
		if (copiedExpenseSheetId) {
			goToPage(`/admin/financial/my-expenses/sheet/${copiedExpenseSheetId}`);
		}
	}, [copiedExpenseSheetId]);

	const handleStatusFilterPropsChange = (event: ChangeEvent<HTMLInputElement>) => {
		setStatusFilter({ ...statusFilter, [event.target.name]: event.target.checked });
	};

	// On user filter change
	useEffect(() => {
		getExpensesData({
			accountEmployeeId: filterUser,
			expenseSheetStatudId: 6,
			includeDateRange: false,
			startDate: '',
			endDate: '',
			loggedUser: loggedUser.username.toUpperCase()
		});
	}, [filterUser]);

	const AVAILABLE_STATUS = {
		NOTSUBMITTED: { id: 0, listPosition: 1 },
		SUBMITTED: { id: 1, listPosition: 2 },
		APPROVED: { id: 2, listPosition: 3 },
		REJECTED: { id: 3, listPosition: 4 }
	};

	// ADMISSION MANAGEMENT STATUS HELPER
	const expensesStatus = (classes: Record<string, string>): any[] => [
		{
			id: AVAILABLE_STATUS.NOTSUBMITTED.id,
			listPosition: AVAILABLE_STATUS.NOTSUBMITTED.listPosition,
			icon: <HourglassEmpty color="primary" className={classes.statusIcon} />,
			name: 'notSubmitted',
			label: 'NOT SUBMITTED'
		},
		{
			id: AVAILABLE_STATUS.SUBMITTED.id,
			listPosition: AVAILABLE_STATUS.SUBMITTED.listPosition,
			icon: <StarBorder color="primary" className={classes.statusIcon} />,
			name: 'submitted',
			label: 'SUBMITTED'
		},
		{
			id: AVAILABLE_STATUS.APPROVED.id,
			listPosition: AVAILABLE_STATUS.APPROVED.listPosition,
			icon: <Check color="primary" className={classes.statusIcon} />,
			name: 'approved',
			label: 'APPROVED'
		},
		{
			id: AVAILABLE_STATUS.REJECTED.id,
			listPosition: AVAILABLE_STATUS.REJECTED.listPosition,
			icon: <CancelOutlined color="primary" className={classes.statusIcon} />,
			name: 'rejected',
			label: 'REJECTED'
		}
	];

	// ADMISSIONS STATUS HELPER
	const statusHelper = useMemo(() => expensesStatus(classes), []);

	// TABLE CUSTOM FILTER CONTENT
	const statusFilterComponent = () => (
		<FormControl component="fieldset" style={{ padding: '25px' }}>
			<FormGroup>
				{statusHelper
					.sort((a: IAdmissionFoundStatus, b: IAdmissionFoundStatus) => b.listPosition - a.listPosition)
					.map(e => (
						<FormControlLabel
							key={e.name}
							control={
								<Checkbox
									color="primary"
									icon={<RadioButtonUnchecked />}
									checkedIcon={<CheckCircle />}
									checked={statusFilter[e.id]}
									onChange={handleStatusFilterPropsChange}
									name={`${e.id}`}
								/>
							}
							label={e.label}
						/>
					))}
			</FormGroup>
		</FormControl>
	);

	const getStatusIdByListPosition = (position: string) => {
		// With the list position, we're able to get the status id that will be used by the filter
		const result = statusHelper.find(status => status.id === parseInt(position));
		return result.label;
	};

	// TABLE CUSTOM FILTER
	const tableColumnsFilter: ITableColumnsFilter = useMemo(
		() => ({
			expensesStatus: {
				anyToFilter: Object.values(statusFilter).includes(true),
				filterComponent: statusFilterComponent(),
				handleFilter: (info: any[]) => {
					const payloadToSearch = Object.keys(statusFilter)
						.filter(field => statusFilter[field])
						.map(e => getStatusIdByListPosition(e));

					return info.filter(item => payloadToSearch.includes(item.expensesStatus));
				}
			}
		}),
		[statusFilter]
	);

	// Set Employees List
	useEffect(() => {
		let employeesOptions: IEmployeeSelect[] = [];
		expensesData?.results?.Employees?.forEach((e: { username: string; firstName: string; lastName: string }) => {
			employeesOptions.push({
				id: e.username,
				label: e.username + ' - ' + e.firstName + ' ' + e.lastName
			});
		});

		// Remove duplicates based on the 'id' property
		const uniqueEmployeesOptions = employeesOptions.filter(
			(item, index, self) => self.findIndex(e => e.id === item.id) === index
		);

		// Sort unique employeesOptions alphabetically by the label property
		uniqueEmployeesOptions.sort((a, b) => a.label.localeCompare(b.label));

		setEmployeesList(uniqueEmployeesOptions);
	}, [expensesData]);

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(myExpensesFilter ? myExpensesFilter : '');

	// Update filters whenever necessary
	useEffect(() => {
		setMyExpensesPageFilter(searchValue);
	}, [searchValue]);

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<any>[] = [
		{
			label: 'Date',
			id: 'date',
			format: (row, text: string) => (text ? text.split(' ')[1] : '')
		},
		{ label: 'Description', id: 'description' },
		{ label: 'Payment Status', id: 'paymentStatus' },
		{
			label: 'Total Amount (EUR)',
			id: 'totalAmount',
			format: (row, text: string) => <Numeric readOnly money value={text} />
		},
		{
			label: 'Status',
			id: 'expensesStatus',
			width: '25%',
			format: (row: any) => {
				if (row.foundStatus) {
					return (
						<div className={classes.statusBar}>
							{row.foundStatus.icon} {row.foundStatus.label}
						</div>
					);
				}

				return <div className={classes.statusBar}>N/A</div>;
			}
		}
	];

	//Removes summer time from dates
	function toUTCDate(date) {
		return new Date(
			Date.UTC(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				date.getHours(),
				date.getMinutes(),
				date.getSeconds(),
				date.getMilliseconds()
			)
		);
	}
	const tableData = useMemo(
		() =>
			mainExpensesData
				?.filter(e => {
					const expenseDate = new Date(e.expenseSheetDate);
					const start = startDate ? toUTCDate(startDate) : new Date('1753-01-01');
					const end = endDate ? toUTCDate(endDate) : new Date('9999-12-31');

					if (isBefore(expenseDate, start) || isAfter(expenseDate, end)) {
						return false;
					}

					switch (filterStatus) {
						case 1:
							return (!e.approved && !e.rejected) || e.rejected;
						case 2:
							return !e.submitted && !e.approved && !e.rejected;
						case 3:
							return e.submitted && !e.approved && !e.rejected;
						case 4:
							return e.submitted && e.approved && !e.rejected;
						case 5:
							return !e.submitted && !e.approved && e.rejected;
						case 6:
							return true;
						default:
							return false;
					}
				})
				.map(e => {
					const foundStatus = statusHelper.find(st => st.id === e.expensesStatus);

					return {
						...e,
						id: e.accountEmployeeExpenseSheetId,
						date: e.expenseSheetDate + ' ' + parseDateToShow(e.expenseSheetDate),
						paymentStatusId: e.paymentStatusId,
						totalAmount: Number(e.amount.toFixed(2)),
						paymentStatus: e.paymentStatusState,
						status: foundStatus.label,
						expensesStatus: foundStatus.label,
						foundStatus
					};
				}),
		[mainExpensesData, filterStatus, startDate, endDate]
	);

	const tableActions: ITableAction<any>[] = [
		{
			id: 'expensesMenu',
			type: 'menu',
			render: () => true,
			disabled: () => false,
			options: [
				{
					id: 'editButton',
					label: 'Edit',
					render: () => true,
					onClick: row => {
						const expensesFilters = watch();
						localStorage.setItem('expensesFilters', JSON.stringify(expensesFilters));

						goToPage(`/admin/financial/my-expenses/sheet/${row.accountEmployeeExpenseSheetId}`);
					}
				},
				{
					id: 'deleteButton',
					label: 'Delete',
					render: row => (row.submitted || row.approved ? false : true),
					disabled: row =>
						row.accountEmployeeExpenseSheetProcessId != null &&
						expensesData?.results?.loggedUserRole != 1165 &&
						!hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN),
					onClick: row => {
						{
							setExpenseSheetToBeDeleted({
								accountEmployeeExpenseSheetId: row.id
							});
							setShowDeleteWarningModal(true);
						}
					}
				},
				{
					id: 'copyButton',
					label: 'Copy',
					render: row =>
						row?.accountEmployeeExpenseSheetProcessId === undefined ||
						row?.accountEmployeeExpenseSheetProcessId === null,
					onClick: row => {
						setCopyExpenseSheetId(row.id);
						const expenseSheetDate = new Date(row.expenseSheetDate);
						expenseSheetDate.setHours(0, 0, 0, 0);
						if (
							expenseSheetDate.getTime() == lastDayOfPreviousMonth.getTime() ||
							expenseSheetDate.getTime() == lastDayOfCurrentMonth.getTime()
						) {
							setNewSheetStartDate(formatDate(new Date(expenseSheetDate)));
							setValue('newSheetStartDate', formatDate(new Date(expenseSheetDate)));
						} else {
							setNewSheetStartDate(formatDate(lastDayOfCurrentMonth));
							setValue('newSheetStartDate', formatDate(lastDayOfCurrentMonth));
						}
						setValue('newSheetDescription', row.description);
						setOpenModalCopyExpenseSheet(true);
					}
				},
				{
					id: 'auditLogButton',
					label: 'Audit Log',
					render: () => true,
					onClick: row => {
						setAuditModalModal(row.id);
					}
				}
			]
		}
	];

	const pageTitleActions = useMemo(
		() => [
			{
				id: 'addNewExpense',
				onClick: () => {
					setValue('newSheetEmployee', watch('employee'));
					setOpenModalNewExpenseSheet(true);
				},
				icon: <Adicionar />,
				label: 'Add New Expense Sheet',
				color: 'primary'
			}
		],
		[]
	);
	const confirmActionsNewExpenseSheet = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setOpenModalNewExpenseSheet(false);
				setOpenModalCopyExpenseSheet(false);
				reset(
					{
						...watch(),
						newSheetEmployee: loggedUser.username.toUpperCase(),
						newSheetStartDate: formatDate(lastDayOfCurrentMonth),
						newSheetDescription: ''
					},
					{ keepErrors: false }
				);
			}
		},
		{
			id: 'submit',
			label: 'Confirm',
			color: 'primary',
			type: 'submit',
			form: 'form-new-expense-sheet',
			variant: 'contained',
			onClick: () => {}
		}
	];

	useEffect(() => {
		if (newExpenseSheet && newExpenseSheetConfirmation) {
			goToPage(
				`/admin/financial/my-expenses/sheet/${newExpenseSheet?.results?.createdExpenseSheet.accountEmployeeExpenseSheetId}`
			);
			setNewExpenseSheetConfirmation(false);
		}
	}, [newExpenseSheet]);

	//Clear variables when start date or end date are empty
	useEffect(() => {
		if (watch('startDate') == 'Invalid date') {
			setValue('startDate', '');
			setStartDate(null);
		}
		if (watch('endDate') == 'Invalid date') {
			setValue('endDate', '');
			setEndDate(null);
		}
	}, [getValues('startDate'), getValues('endDate')]);

	//Actions for Delete Warning
	const deleteWarningActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setExpenseSheetToBeDeleted({});
				setShowDeleteWarningModal(false);
			}
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				deleteExpenseData(expenseSheetToBeDeleted);
				setShowDeleteWarningModal(false);
				getExpensesData({
					accountEmployeeId: filterUser,
					expenseSheetStatudId: 6,
					includeDateRange: false,
					startDate: '',
					endDate: '',
					loggedUser: loggedUser.username.toUpperCase()
				});
			}
		}
	];

	const isLoading = expensesData === null || loading || userGuide === undefined || guide === undefined || undefined;
	if (isLoading) return <LoadingOverlay />;

	// MyExpenses component return
	return (
		<>
			<Grid
				container
				justifyContent="space-between"
				spacing={2}
				style={{ marginBottom: '50px' }}
				id={'filtersID'}>
				{userGuide?.data?.userGuide?.active ? (
					<Joyride
						continuous
						callback={handleJoyrideCallback}
						run={run}
						steps={steps}
						hideCloseButton
						showSkipButton
						showProgress
						styles={{
							options: {
								primaryColor: '#3F80EF'
							}
						}}
					/>
				) : (
					''
				)}
				<Grid item xs={3}>
					<LibSelect
						name="employee"
						label="EMPLOYEE NAME"
						labelBold
						options={employeesList || []}
						onChange={(e: any) => {
							setFilterUser(e);
							setValue('employee', e);
						}}
						control={control}
						errors={errors}
						hasSearchOption={true}
					/>
				</Grid>
				<Grid item xs={3}>
					<LibSelect
						name="approvalStatus"
						label="Approval Status"
						labelBold
						options={approvalStatusOptions}
						onChange={(e: any) => {
							setFilterStatus(e);
							setValue('approvalStatus', e);
						}}
						control={control}
						errors={errors}
					/>
				</Grid>
				<Grid item xs={2} style={{ marginTop: '-8px' }}>
					<DatePicker
						format="DD/MM/YYYY"
						name="startDate"
						label="Start Date"
						placeholder="Start Date"
						labelBold
						changeIconToArrow
						onAccept={e => {
							setStartDate(new Date(e));
							setValue('startDate', e);
						}}
						control={control}
						errors={errors}
					/>
				</Grid>
				<Grid item xs={2} style={{ marginTop: '-8px' }}>
					<DatePicker
						format="DD/MM/YYYY"
						name="endDate"
						label="End Date"
						placeholder="End Date"
						labelBold
						changeIconToArrow
						onAccept={e => {
							setEndDate(new Date(e));
							setValue('endDate', e);
						}}
						control={control}
						errors={errors}
					/>
				</Grid>
			</Grid>
			<Grid id={'tableID'}>
				<Table
					tableData={tableData}
					columns={tableColumns}
					actions={tableActions}
					headerActions={pageTitleActions}
					tableColumnsFilter={tableColumnsFilter}
					defaultSortColumn="expenseSheetDate"
					defaultOrder="desc"
					handleSearch={{ searchValue, setSearchValue }}
				/>
			</Grid>
			{/* Dialog for New Expense Sheet */}
			<Dialog
				modal={{
					open: Boolean(openModalNewExpenseSheet || openModalCopyExpenseSheet),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setOpenModalNewExpenseSheet(false);
							setOpenModalCopyExpenseSheet(false);
						}
					},
					content: (
						<>
							<form
								id="form-new-expense-sheet"
								onSubmit={handleSubmit((data: any) => {
									if (openModalCopyExpenseSheet) {
										copyExpenseSheet({
											expenseSheetId: copyExpenseSheetId,
											username: userInfo.username,
											expenseSheetDate: newSheetStartDate,
											description: watch('newSheetDescription')
										});
										reset();
										setCopyExpenseSheetId('');
										setOpenModalCopyExpenseSheet(false);
									} else {
										submitNewExpenseSheet({
											accountEmployeeId: watch('newSheetEmployee'),
											expenseSheetDate: newSheetStartDate,
											description: watch('newSheetDescription')
										});
									}
									setOpenModalNewExpenseSheet(false);
									setNewExpenseSheetConfirmation(true);
								})}>
								<Grid container justifyContent="space-between" spacing={2}>
									{openModalNewExpenseSheet && (
										<Grid item xs={12}>
											<LibSelect
												name="newSheetEmployee"
												label="EMPLOYEE NAME"
												options={employeesList || []}
												onChange={(e: any) => {
													setNewSheetEmployee(e);
												}}
												control={control}
												errors={errors}
												hasSearchOption={true}
											/>
										</Grid>
									)}
									<Grid item xs={12}>
										<LibSelect
											name="newSheetStartDate"
											label="Date"
											options={startDateOptions || []}
											onChange={(e: any) => {
												setNewSheetStartDate(e);
											}}
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={12}>
										<Input
											name="newSheetDescription"
											label="Description"
											required
											control={control}
											errors={errors}
										/>
									</Grid>
								</Grid>
							</form>
						</>
					)
				}}
				title={openModalCopyExpenseSheet ? 'Copy Expense Sheet' : 'New Expense Sheet'}
				actions={confirmActionsNewExpenseSheet}
				scroll="body"
			/>
			{/* Diaglog for Audit Log */}
			<Dialog
				fullScreen
				title=""
				scroll="body"
				modal={{
					open: Boolean(auditModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setAuditModalModal(null);
					},
					content:
						(auditModal && <ExpenseSheetAudit id={auditModal} handleClose={setAuditModalModal} />) || ''
				}}
			/>
			{/* Dialog for Delete Warning */}
			<Dialog
				modal={{
					open: Boolean(showDeleteWarningModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setShowDeleteWarningModal(false);
					},
					content: (
						<>
							<Typography gutterBottom>Are you sure you want to delete this expense sheet?</Typography>
						</>
					)
				}}
				title="Delete Warning"
				actions={deleteWarningActions}
				scroll="body"
			/>
		</>
	);
};

export default withLayout(MyExpenses);
