//* EXTERNAL LIBS
import React, { Dispatch, SetStateAction, FC, useState, useEffect, useMemo, useRef, ChangeEvent } from 'react';

//* EXTERNAL LIBS --> MUI
import {
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Button,
	Grid,
	List,
	Typography,
	Tooltip
} from '@mui/material';
import {
	RadioButtonUnchecked,
	CheckCircle,
	HourglassEmpty,
	StarBorder,
	Check,
	CancelOutlined,
	Download,
	Delete,
	AttachFile,
	AttachmentSharp
} from '@mui/icons-material';
import { Input as CoreInput } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Joyride from 'react-joyride-react-19';
import { useDispatch } from 'react-redux';

//* EXTERNAL LIBS --> XPAND-UI
import { Select as LibSelect, Input, InfoField, Numeric } from 'xpand-ui/forms';

import { LoadingOverlay, Table, Dialog, BackPage } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { IUserToken } from 'typings/store/generalTypes';
import { ITableAction, ITableColumn, ITableColumnsFilter } from 'typings/store/ComponentLib';
import { ITitle } from 'components/App/TitleProvider';
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import Print from '../../../../../assets/icons/print.svg';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { MyExpensesNewProps } from '.';
import { schema } from './yupSchema';
import { useNavigate, useParams } from 'react-router-dom';
import Adicionar from '../../../../../assets/icons/Adicionar.svg';
import { IAdmissionFoundStatus } from 'typings/store/admin/admissions';
import { addNotification } from 'lib/utils/notifications';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Roles } from 'lib/roles/index';

import ApprovalDetailsModal from './ApprovalDetailsModal/ApprovalDetailsModal';
import NewExpenseLine from './NewExpenseLine';
import { format, isAfter } from 'date-fns';
import { getLSField } from 'lib/utils/cookies';
import { cleanGuides } from 'store/guides/actions';
import { hasPermission } from '../utils';

//* COMPONENT INTERFACES

// Prepare the dates for the new Expense Sheet Date select (Last day of current and previous month)
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1; // Month starts from 0
const lastDayOfCurrentMonth = new Date(currentYear, currentMonth, 0);
const lastDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 0);

// Date Format function for Date select Options
const formatDate = date => {
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	return format(new Date(date), 'dd/MM/yyyy');
};

// Date Format function for Date option recieved from backend
function formatDate2(dateString) {
	const dateObj = new Date(dateString);
	const day = dateObj.getDate();
	const month = dateObj.getMonth() + 1; // Months are zero-based
	const year = dateObj.getFullYear();

	return format(dateObj, 'dd/MM/yyyy');
}

const startDateOptions = [
	{
		id: formatDate(lastDayOfPreviousMonth),
		label: formatDate(lastDayOfPreviousMonth)
	},
	{
		id: formatDate(lastDayOfCurrentMonth),
		label: formatDate(lastDayOfCurrentMonth)
	}
];

// Interface for MyExpensesNew component props
interface IMyExpensesNew extends MyExpensesNewProps {
	match: Match<{
		token: string;
		proposalId: string | number;
		hash: string;
	}>;
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
	title: ITitle;
	setTitle: Dispatch<SetStateAction<ITitle>>;
}

let formDataAttachment: FormData = new FormData();

//* COMPONENT
const MyExpensesNew: FC<IMyExpensesNew> = ({
	loggedUser,
	personalInfo,
	match,
	guides,
	getExpenseSheetData,
	addAttachExpenses,
	deleteAttachmentExpenses,
	downloadAttachmentExpenses,
	attachmentListExpenses,
	deleteExpenseEntry,
	printExpenseSheet,
	submitUpdateExpenseSheet,
	submitExpenseSheet,
	getGuideData,
	getUserGuideData,
	setUserGuideAsRead,
	setClearExpenseSheetData
}) => {
	const classes = useStyles();
	const params = useParams();
	const dispatch = useDispatch();
	const { guide, userGuide } = guides;
	const navigate = useNavigate();
	const goToPage = (path: string) => navigate(path);

	const [statusFilter, setStatusFilter] = useState({ 0: false, 1: false, 2: false, 3: false });
	const [shouldOpenModal, setShouldOpenModal] = useState(false);

	const [isEdit, setIsEdit] = useState(false);
	const [editData, setEditData] = useState(null);

	const [mainExpenseSheetData, setMainExpenseSheetData] = useState(null);
	const [currentDescription, setCurrentDescription] = useState();
	const [totalReimbursement, setTotalReimbursement] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const { expenseSheetData, loading, expenseAttachList, expenseSheetSubmitMessage, updateExpenseSheet } =
		personalInfo;

	// Approval Details
	const [openApprovalDetailsModal, setOpenApprovalDetailsModal] = useState(false);
	// New Expense Line
	const [openModalNewExpenseLine, setOpenModalNewExpenseLine] = useState(false);
	// Delete Warning Modal
	const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
	const [expenseEntryToBeDeleted, setExpenseEntryToBeDeleted] = useState<number>(0);

	const initForm = {
		newSheetStartDate: '',
		newSheetDescription: ''
	};

	useEffect(() => {
		// Clean the guides when the component unmounts or when a new page is loaded
		return () => {
			dispatch(cleanGuides());
		};
	}, [dispatch]);

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setValue,
		reset
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues: initForm,
		shouldUnregister: false
	});

	//Attachments Variables
	const [openAttachModal, setOpenAttachModal] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [attachmentRowInfo, setAttachmentRowInfo] = useState();
	const [searchValueAttach, setSearchValueAttach] = useState('');

	useEffect(() => {
		setClearExpenseSheetData();
		getExpenseSheetData({ accountEmployeeExpenseSheetId: params?.id, loggedUsername: loggedUser?.username });
	}, []);

	//Used after submitting the expense sheet to reload info
	useEffect(() => {
		if (expenseSheetSubmitMessage) {
			getExpenseSheetData({ accountEmployeeExpenseSheetId: params?.id, loggedUsername: loggedUser?.username });
		}
	}, [expenseSheetSubmitMessage]);

	const handleDeleteExpenseEntry = async (accountExpenseEntryId: number) => {
		if (await deleteExpenseEntry({ accountEmployeeExpenseEntryId: accountExpenseEntryId })) {
			getExpenseSheetData({ accountEmployeeExpenseSheetId: params?.id, loggedUsername: loggedUser?.username });
		}
	};

	// // TABLE CUSTOM FILTER STATUS CHANGER
	const handleStatusFilterPropsChange = (event: ChangeEvent<HTMLInputElement>) => {
		setStatusFilter({ ...statusFilter, [event.target.name]: event.target.checked });
	};

	useEffect(() => {
		getGuideData('ExpenseSheet');
	}, []);

	useEffect(() => {
		getUserGuideData(loggedUser.username, 'ExpenseSheet');
	}, []);

	const [{ run, steps }, setState] = useState({
		run: true,
		steps: []
	});

	const handleJoyrideCallback = data => {
		const { action, index, type } = data;

		if (action === 'reset') {
			// Joyride finished
			setUserGuideAsRead(loggedUser.username, 'ExpenseSheet');
		}
	};

	// Get Page Guide
	useEffect(() => {
		if (guide && userGuide?.data?.userGuide?.active) {
			setState(prevState => ({
				...prevState,
				steps:
					guide?.data?.guide?.guideSteps?.map((step, index) => ({
						content: <div dangerouslySetInnerHTML={{ __html: step.content }} />,
						placement: step.placement,
						target: step.target,
						title: step.title,
						disableScrolling: step.disableScrolling,
						spotlightClicks: step.spotlightClicks,
						key: index
					})) || []
			}));
		}
	}, [guide, userGuide]);

	const AVAILABLE_STATUS = {
		NOTSUBMITTED: { id: 0, listPosition: 1 },
		SUBMITTED: { id: 1, listPosition: 2 },
		APPROVED: { id: 2, listPosition: 3 },
		REJECTED: { id: 3, listPosition: 4 }
	};

	const getMonthYear = dateString => {
		const [day, month, year] = dateString.split('/');
		return { month: parseInt(month, 10), year: parseInt(year, 10) };
	};

	// Function to calculate the difference in months between two dates
	const getMonthDifference = (date1, date2) => {
		const totalMonths1 = date1.year * 12 + date1.month;
		const totalMonths2 = date2.year * 12 + date2.month;
		return Math.abs(totalMonths1 - totalMonths2);
	};

	useEffect(() => {
		if (expenseSheetData) {
			setMainExpenseSheetData(
				expenseSheetData?.results?.accountExpenseEntryWithLastStatus.map(expense => ({
					...expense,
					expensesStatus: expense.status
				}))
			);

			setValue('newSheetDescription', expenseSheetData?.results?.accountEmployeeExpenseSheet?.description);

			const currentMonthYear = { month: currentDate.getMonth() + 1, year: currentDate.getFullYear() };
			const expenseSheetDate = expenseSheetData?.results?.accountEmployeeExpenseSheet?.expenseSheetDate;
			const formattedExpenseSheetDate = formatDate2(expenseSheetDate);
			const modifiedDate = getMonthYear(formattedExpenseSheetDate);

			const monthDifference = getMonthDifference(currentMonthYear, modifiedDate);

			if (monthDifference > 1) {
				// Assuming the 2nd option in startDateOptions has an id that should be set
				const secondOption = startDateOptions[1];
				setValue('newSheetStartDate', secondOption.id);
			} else {
				setValue('newSheetStartDate', formattedExpenseSheetDate);
			}
		} else {
			setMainExpenseSheetData(null);
		}
	}, [expenseSheetData]);

	//Forces refresh after update expense sheet date, to present the correct expense sheet entries
	useEffect(() => {
		if (updateExpenseSheet) {
			setClearExpenseSheetData();
			getExpenseSheetData({ accountEmployeeExpenseSheetId: params?.id, loggedUsername: loggedUser?.username });
		}
	}, [updateExpenseSheet]);

	const getSquareColor = paymentStatusLabel => {
		switch (paymentStatusLabel) {
			case 'Not Submitted':
				return '#FAC15F';
			case 'Submitted':
				return '#2BAAFF';
			case 'Approved':
				return '#21BA45';
			case 'Rejected':
				return '#D1345E';
			default:
				return ''; // Default color
		}
	};

	// ADMISSION MANAGEMENT STATUS HELPER
	const expensesStatus = (classes: Record<string, string>): any[] => [
		{
			id: AVAILABLE_STATUS.NOTSUBMITTED.id,
			listPosition: AVAILABLE_STATUS.NOTSUBMITTED.listPosition,
			icon: <HourglassEmpty color="primary" className={classes.statusIcon} />,
			name: 'notSubmitted',
			label: 'NOT SUBMITTED'
		},
		{
			id: AVAILABLE_STATUS.SUBMITTED.id,
			listPosition: AVAILABLE_STATUS.SUBMITTED.listPosition,
			icon: <StarBorder color="primary" className={classes.statusIcon} />,
			name: 'submitted',
			label: 'SUBMITTED'
		},
		{
			id: AVAILABLE_STATUS.APPROVED.id,
			listPosition: AVAILABLE_STATUS.APPROVED.listPosition,
			icon: <Check color="primary" className={classes.statusIcon} />,
			name: 'approved',
			label: 'APPROVED'
		},
		{
			id: AVAILABLE_STATUS.REJECTED.id,
			listPosition: AVAILABLE_STATUS.REJECTED.listPosition,
			icon: <CancelOutlined color="primary" className={classes.statusIcon} />,
			name: 'rejected',
			label: 'REJECTED'
		}
	];

	// ADMISSIONS STATUS HELPER
	const statusHelper = useMemo(() => expensesStatus(classes), []);

	// TABLE CUSTOM FILTER CONTENT
	const statusFilterComponent = () => (
		<FormControl component="fieldset" style={{ padding: '25px' }}>
			<FormGroup>
				{statusHelper
					.sort((a: IAdmissionFoundStatus, b: IAdmissionFoundStatus) => b.listPosition - a.listPosition)
					.map(e => (
						<FormControlLabel
							key={e.name}
							control={
								<Checkbox
									color="primary"
									icon={<RadioButtonUnchecked />}
									checkedIcon={<CheckCircle />}
									checked={statusFilter[e.id]}
									onChange={handleStatusFilterPropsChange}
									name={`${e.id}`}
								/>
							}
							label={e.label}
						/>
					))}
			</FormGroup>
		</FormControl>
	);

	const getStatusIdByListPosition = (position: string) => {
		// With the list position, we're able to get the status id that will be used by the filter
		const result = statusHelper.find(status => status.id === parseInt(position));
		return result.label;
	};

	// TABLE CUSTOM FILTER
	const tableColumnsFilter: ITableColumnsFilter = useMemo(
		() => ({
			expensesStatus: {
				anyToFilter: Object.values(statusFilter).includes(true),
				filterComponent: statusFilterComponent(),
				handleFilter: (info: any[]) => {
					const payloadToSearch = Object.keys(statusFilter)
						.filter(field => statusFilter[field])
						.map(e => getStatusIdByListPosition(e));

					return info.filter(item => payloadToSearch.includes(item.expensesStatus));
				}
			}
		}),
		[statusFilter]
	);

	useEffect(() => {
		let reimbursementSum = 0;
		let amountSum = 0;

		mainExpenseSheetData?.forEach(row => {
			if (row.reimburse) {
				reimbursementSum += row.amountOnEUR;
			}
			amountSum += row.amountOnEUR;
		});

		setTotalReimbursement(reimbursementSum);
		setTotalAmount(amountSum);
	}, [mainExpenseSheetData]);

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState('');

	const tableData = useMemo(
		() =>
			mainExpenseSheetData?.map(e => {
				const foundStatus = statusHelper.find(st => st.id === e.expensesStatus);
				return {
					...e,
					id: e.accountExpenseEntryId,
					date: e.accountExpenseEntryDate + ' ' + parseDateToShow(e.accountExpenseEntryDate),
					projectName: e.projectName,
					expenseName: e.accountExpenseName,
					description: e.description ? e.description : '',
					paymentStatus: e.paymentStatusState,
					netAmount1: e.amount.toFixed(2),
					netAmount: Number(e.amount.toFixed(2)),
					amount1: e.amountOnEUR.toFixed(2),
					amount: Number(e.amountOnEUR.toFixed(2)),
					reimburse: e.reimburse === 1 ? 'Yes' : 'No',
					billable: e.isBillable === true ? 'Yes' : 'No',
					destinationCountry: e.customField1,
					status: statusHelper.find(st => st.id === e.expensesStatus).label,
					expensesStatus: foundStatus.label,
					foundStatus
				};
			}),
		[expenseSheetData, mainExpenseSheetData]
	);

	const currencyFormat = num => {
		return (
			Number(num)
				.toFixed(2)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
				.replace('.', ',')
				.replace(/\./g, '.') // Replace all dots with commas
				.replace(',', '.') + ' €' // Replace all commas with dots // Replace dot with comma
		);
	};

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<any>[] = [
		{
			label: '',
			id: 'hasAttachments',
			width: '1%',
			format: (row, text: string) => (row.attachments.length > 0 ? <AttachFile color="disabled" /> : '')
		},
		{ label: 'Date', id: 'date', width: '5%', format: (row, text: string) => (text ? text.split(' ')[1] : '') },
		{
			label: 'Project Name',
			id: 'projectName',
			width: '15%',
			format: row => (
				<Tooltip title={row.projectName} classes={{ tooltip: classes.tooltip }} placement="top">
					<div className={classes.projectNameCell}>{row.projectName}</div>
				</Tooltip>
			)
		},
		{
			label: 'Expense Name',
			id: 'expenseName',
			width: '15%',
			format: row => (
				<Tooltip title={row.expenseName} classes={{ tooltip: classes.tooltip }} placement="top">
					<div className={classes.expenseNameCell}>{row.expenseName}</div>
				</Tooltip>
			)
		},
		{
			label: 'Description',
			id: 'description',
			width: '20%',
			format: row => (
				<Tooltip title={row.description} classes={{ tooltip: classes.tooltip }} placement="top">
					<div className={classes.descriptionCell}>{row.description}</div>
				</Tooltip>
			)
		},
		{
			label: 'Payment Status',
			id: 'paymentStatus',
			width: '5%'
		},
		{
			label: 'Net Amount',
			id: 'netAmount',
			width: '5%',
			format: (row, text: string) => (
				<Numeric currencySymbol={`${row.currencyCode}`} readOnly money value={row.netAmount} />
			)
		},
		{
			label: 'Amount (EUR)',
			id: 'amount',
			width: '5%',
			format: (row, text: string) => <Numeric currencySymbol={'EUR'} readOnly money value={row.amount1} />
		},
		{
			label: 'Reimburse',
			id: 'reimburse',
			width: '5%'
		},
		{
			label: 'Billable',
			id: 'billable',
			width: '5%'
		},
		{
			label: 'Country',
			id: 'destinationCountry'
		},
		{
			label: 'Status',
			id: 'expensesStatus',
			width: '25%',
			format: (row: any) => {
				if (row.foundStatus) {
					return (
						<div className={classes.statusBar}>
							{row.foundStatus.icon} {row.foundStatus.label}
						</div>
					);
				}

				return <div className={classes.statusBar}>N/A</div>;
			}
		}
	];

	useEffect(() => {
		if (shouldOpenModal) {
			setOpenModalNewExpenseLine(true);
		}
	}, [shouldOpenModal, openModalNewExpenseLine]);

	const pageTitleActions = useMemo(
		() => [
			{
				id: 'addNewExpense',
				onClick: () => {
					setIsEdit(false);
					setOpenModalNewExpenseLine(true);
				},
				icon: <Adicionar />,
				disabled:
					expenseSheetData?.results?.accountEmployeeExpenseSheet.approved ||
					expenseSheetData?.results?.accountEmployeeExpenseSheet.submitted ||
					(expenseSheetData?.results?.accountEmployeeExpenseSheet?.accountEmployeeExpenseSheetProcessId !=
						null &&
						expenseSheetData?.results?.loggedUserRole != 1165 &&
						!hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN)),
				label: 'Add New Expense'
			},
			{
				id: 'printExpense',
				onClick: () => {
					preparePrintTimesheetData();
				},
				icon: <Print />,
				label: 'Print'
			},
			{
				id: 'expenseApprovalDetail',
				icon: (
					<>
						<CheckIcon htmlColor="#3f80ef" />
					</>
				),
				onClick: () => setOpenApprovalDetailsModal(true),
				label: 'Expense Approval Detail'
			}
		],
		[expenseSheetData, mainExpenseSheetData, totalReimbursement]
	);

	const tableActions: ITableAction<any>[] = [
		{
			id: 'expenseRowMenu',
			type: 'menu',
			render: () => true,
			options: [
				{
					id: 'editButton',
					label: 'Edit',
					render: () => true,
					onClick: e => {
						setIsEdit(true);
						setEditData({
							...e,
							reimburse2: e.reimburse ? e.reimburse == 'Yes' : false,
							billable2: e.billable ? e.billable == 'Yes' : false
						});
						setOpenModalNewExpenseLine(true);
					},
					disabled: e =>
						e.submitted == 1 ||
						e.approved == 1 ||
						(expenseSheetData?.results?.accountEmployeeExpenseSheet?.accountEmployeeExpenseSheetProcessId !=
							null &&
							expenseSheetData?.results?.loggedUserRole != 1165 &&
							!hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN))
				},
				{
					id: 'deleteButton',
					label: 'Delete',
					render: () => true,
					disabled: e =>
						e.submitted == 1 ||
						e.approved == 1 ||
						(expenseSheetData?.results?.accountEmployeeExpenseSheet?.accountEmployeeExpenseSheetProcessId !=
							null &&
							expenseSheetData?.results?.loggedUserRole != 1165 &&
							!hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN)),
					onClick: e => {
						setExpenseEntryToBeDeleted(e.accountExpenseEntryId);
						setShowDeleteWarningModal(true);
					}
				},
				{
					id: 'attachmentButton',
					label: 'Attachment',
					render: () => true,
					onClick: e => {
						setAttachmentRowInfo(e);
						setOpenAttachModal(true);
						attachmentListExpenses({ expenseEntryId: e.accountExpenseEntryId });
					}
				}
			]
		}
	];

	//If modal of attachments is open and expenseAttachment is empty after add or deleting an attachment
	useEffect(() => {
		if (openAttachModal && !expenseAttachList) {
			attachmentListExpenses({ expenseEntryId: attachmentRowInfo?.accountExpenseEntryId });
		}
	}, [expenseAttachList]);

	//Actions for attach modal
	const attachModalAction = [
		{
			id: 'cancel',
			label: 'Close',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setInputValue('');
				setOpenAttachModal(false);
				setClearExpenseSheetData();
				getExpenseSheetData({
					accountEmployeeExpenseSheetId: params?.id,
					loggedUsername: loggedUser?.username
				});
			}
		}
	];

	//Adds a attachment to time entry
	const saveAttachment = () => {
		if (formDataAttachment.has('attachment')) {
			const fileSize = formDataAttachment.get('attachment');
			const MaxFileSize = 4097000;
			if (fileSize.size <= MaxFileSize) {
				if (fileSize?.type == '' && !fileSize?.name?.endsWith('.msg')) {
					addNotification('danger', 'File type is invalid, please insert a valid file.');
				} else {
					formDataAttachment.append('sessionEmployeeId', attachmentRowInfo.accountEmployeeId);
					formDataAttachment.append('expenseEntryId', attachmentRowInfo.accountExpenseEntryId);
					formDataAttachment.append(
						'accountEmployeeTimeEntryPeriodId',
						attachmentRowInfo?.accountEmployeeTimeEntryPeriodId
					);
					//Call for expenses
					addAttachExpenses(formDataAttachment);
					formDataAttachment = new FormData();
					setInputValue('');
				}
			} else {
				addNotification('danger', 'File size exceeds the maximum limit (4Mb).');
			}
		} else {
			addNotification('danger', 'Please insert a file.');
		}
	};

	//Gets input file
	const changeHandler = event => {
		setInputValue(event.target.value);
		formDataAttachment = new FormData();
		formDataAttachment.append('attachment', event.target.files[0]);
	};

	// TABLE DATA - for attachments dialog table
	const tableDataAttachments = useMemo(
		() =>
			expenseAttachList?.listAttachment?.map(e => ({
				...e,
				id: e.accountAttachmentId,
				attachmentName: e.attachmentName,
				fileName: e.attachmentLocalPath
			})),
		[expenseAttachList]
	);

	const tableColumnsAttachments: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Id',
				id: 'id',
				width: '5%'
			},
			{
				label: 'Attachment Name',
				id: 'attachmentName',
				width: '25%'
			},
			{
				label: 'File Name',
				id: 'fileName',
				width: '25%'
			}
		],
		[]
	);

	//Actions for attachment table rows
	const tableActionsAttachments: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'downloadAttachment',
				type: 'button',
				icon: <Download />,
				render: () => true,
				disabled: () => false,
				onClick: row => {
					setInputValue('');
					downloadAttachmentExpenses({
						expenseEntryId: row.accountExpenseEntryId,
						employeeId: row.createdByEmployeeId,
						attachmentName: row.attachmentLocalPath
					});
				}
			},
			{
				id: 'deleteAttachment',
				type: 'button',
				icon: <Delete />,
				render: () => true,
				disabled: () =>
					expenseSheetData?.results?.accountEmployeeExpenseSheet?.accountEmployeeExpenseSheetProcessId !=
						null &&
					expenseSheetData?.results?.loggedUserRole != 1165 &&
					!hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN),
				onClick: row => {
					deleteAttachmentExpenses({ attachmentId: row.id, expenseEntryId: row.accountExpenseEntryId });
				}
			}
		],
		[expenseAttachList]
	);

	// Print expense sheet data
	const preparePrintTimesheetData = () => {
		printExpenseSheet({
			expenseEntryList: mainExpenseSheetData,
			expenseSheetData: {
				employeeName: `${expenseSheetData?.results?.userData.employeeCode} - ${expenseSheetData?.results?.userData.firstName} ${expenseSheetData?.results?.userData.lastName}`,
				description: watch('newSheetDescription'),
				date: watch('newSheetStartDate'),
				expenseSheetStatus: expenseSheetData?.results?.accountEmployeeExpenseSheet.statusLabel,
				paymentStatus: expenseSheetData?.results?.accountEmployeeExpenseSheet.paymentStatusLabel,
				totalReimbursement: totalReimbursement,
				totalAmount: totalAmount
			}
		});
	};

	//Actions for Delete Warning
	const deleteWarningActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setExpenseEntryToBeDeleted(0);
				setShowDeleteWarningModal(false);
			}
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				handleDeleteExpenseEntry(expenseEntryToBeDeleted);
				setShowDeleteWarningModal(false);
			}
		}
	];

	//Removes summer time from dates
	function toUTCDate(date) {
		return new Date(
			Date.UTC(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				date.getHours(),
				date.getMinutes(),
				date.getSeconds(),
				date.getMilliseconds()
			)
		);
	}

	const isLoading =
		tableData === null ||
		loading ||
		mainExpenseSheetData === null ||
		expenseSheetData === null ||
		expenseSheetData === undefined ||
		userGuide === undefined ||
		guide === undefined ||
		expenseSheetSubmitMessage;

	if (isLoading) return <LoadingOverlay />;

	// MyExpensesNew component return
	return (
		<>
			{' '}
			<BackPage path={'/admin/financial/my-expenses'} label={'Back to Expenses'} action={goToPage} />
			<>
				{userGuide?.data?.userGuide?.active ? (
					<Joyride
						continuous
						callback={handleJoyrideCallback}
						run={run}
						steps={steps}
						hideCloseButton
						showSkipButton
						showProgress
						styles={{
							options: {
								primaryColor: '#3F80EF'
							}
						}}
					/>
				) : (
					''
				)}
				<Grid
					container
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					style={{ marginBottom: '10px' }}>
					<Grid item xs={5} style={{ display: 'flex' }}>
						<Typography className={classes.labelTitles} gutterBottom>
							Employee Name{' '}
						</Typography>
						<Typography className={classes.labelValue} gutterBottom>
							{expenseSheetData?.results?.userData.employeeCode} -{' '}
							{expenseSheetData?.results?.userData.firstName}{' '}
							{expenseSheetData?.results?.userData.lastName}
						</Typography>
					</Grid>
					<Grid item xs={4}></Grid>
					<Grid item xs={3}>
						{!expenseSheetData?.results?.accountEmployeeExpenseSheet?.submitted ? (
							<LibSelect
								name="newSheetStartDate"
								label="Date"
								disabled={expenseSheetData?.results?.accountEmployeeExpenseSheet?.submitted}
								control={control}
								errors={errors}
								options={startDateOptions || []}
							/>
						) : (
							<>
								<Typography className={classes.labelTitles} gutterBottom>
									Date{' '}
								</Typography>
								<Typography className={classes.labelValue} gutterBottom>
									{formatDate2(
										expenseSheetData?.results?.accountEmployeeExpenseSheet?.expenseSheetDate
									)}
								</Typography>
							</>
						)}
					</Grid>
				</Grid>
				<Grid container justifyContent="space-between" spacing={2} style={{ marginBottom: '25px' }}>
					<Grid item xs={8} style={{ display: 'flex' }}>
						<Typography className={classes.labelTitles} style={{ marginTop: '10px' }} gutterBottom>
							Description
						</Typography>
						<Input
							name="newSheetDescription"
							label=""
							control={control}
							errors={errors}
							disabled={expenseSheetData?.results?.accountEmployeeExpenseSheet?.submitted}
						/>
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={3} id={'updateButtonID'}>
						{' '}
						<Button
							variant="contained"
							disabled={
								expenseSheetData?.results?.accountEmployeeExpenseSheet?.submitted ||
								(expenseSheetData?.results?.accountEmployeeExpenseSheet
									?.accountEmployeeExpenseSheetProcessId != null &&
									expenseSheetData?.results?.loggedUserRole != 1165 &&
									!hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN))
							}
							onClick={() => {
								const date = watch('newSheetStartDate').split('/');
								let chosedDate = new Date(date[2], date[1] - 1, date[0]);
								chosedDate = toUTCDate(chosedDate);
								let isSubmittable = true;
								for (let e of mainExpenseSheetData) {
									let expenseEntryDate = toUTCDate(new Date(e.accountExpenseEntryDate));
									if (chosedDate < expenseEntryDate) {
										addNotification(
											'danger',
											'Expense Sheet date must be superior to expense entries dates!'
										);
										isSubmittable = false;
										break; // Break the loop
									}
								}
								if (isSubmittable) {
									submitUpdateExpenseSheet({
										accountEmployeeExpenseSheetId: params?.id,
										accountEmployeeId: expenseSheetData?.results?.userData.employeeCode,
										expenseSheetDate: watch('newSheetStartDate'),
										description: watch('newSheetDescription')
									});
								}
							}}>
							Update
						</Button>
					</Grid>
				</Grid>
				<Grid
					container
					justifyContent="space-around"
					alignItems={'center'}
					style={{ marginBottom: '10px', height: '70px', backgroundColor: '#F8F9FC' }}>
					<Grid item xs={3.5} style={{ display: 'flex', marginTop: '6px' }}>
						<Typography className={classes.labelTitles} gutterBottom>
							Expense Sheet Status{' '}
						</Typography>
						<Typography className={classes.labelValue} gutterBottom style={{ display: 'flex' }}>
							<Grid item style={{ marginRight: '15px' }}>
								<div
									style={{
										width: '20px',
										height: '20px',
										backgroundColor: getSquareColor(
											expenseSheetData?.results?.accountEmployeeExpenseSheet.statusLabel
										),
										borderRadius: '5px',
										marginRight: '-3px'
									}}></div>
							</Grid>

							{expenseSheetData?.results?.accountEmployeeExpenseSheet.statusLabel}
						</Typography>
					</Grid>
					<Grid item xs={2} style={{ display: 'flex', marginTop: '6px' }}>
						<Typography className={classes.labelTitles} gutterBottom>
							Payment Status{' '}
						</Typography>
						<Typography className={classes.labelValue} gutterBottom>
							{expenseSheetData?.results?.accountEmployeeExpenseSheet.paymentStatusLabel}
						</Typography>
					</Grid>
					<Grid item xs={2} style={{ display: 'flex', marginTop: '6px' }}>
						<Typography className={classes.labelTitles2} gutterBottom>
							Total Amount:
						</Typography>
						<Typography className={classes.labelValue2} gutterBottom>
							<Numeric
								readOnly
								money
								className={classes.numericCustomColor}
								value={totalAmount.toFixed(2)}
							/>
						</Typography>
					</Grid>
					<Grid
						item
						xs={4}
						style={{
							display: 'flex',
							marginTop: '6px',
							paddingRight: '24px'
						}}>
						<Typography className={classes.labelTitles2} gutterBottom>
							Total Reimbursement:
						</Typography>
						<Typography className={classes.labelValue2} gutterBottom>
							<Numeric
								className={classes.numericCustomColor}
								readOnly
								money
								value={totalReimbursement.toFixed(2)}
							/>{' '}
							(Currency EUR)
						</Typography>
					</Grid>
				</Grid>

				<Grid id={'tableID'}>
					<Table
						tableData={tableData}
						columns={tableColumns}
						actions={tableActions}
						headerActions={pageTitleActions}
						tableColumnsFilter={tableColumnsFilter}
						defaultSortColumn="date"
						defaultOrder="desc"
						handleSearch={{ searchValue, setSearchValue }}
					/>
				</Grid>
			</>
			<Grid
				container
				direction="row"
				id={'footerButtonsID'}
				style={{
					position: 'sticky',
					bottom: '0',
					left: '0',
					backgroundColor: 'white',
					paddingLeft: '30px',
					paddingRight: '30px',
					paddingBottom: '10px',
					paddingTop: '20px'
				}}
				alignItems="center">
				<Grid item xs={11}></Grid>
				<Grid item xs={1} id={'submitID'}>
					<Button
						color="primary"
						size="large"
						variant="contained"
						style={{
							borderRadius: '10px',
							opacity: '0.8',
							width: '150px',
							marginRight: '10px'
						}}
						icon={<Print />}
						disabled={
							expenseSheetData?.results?.accountEmployeeExpenseSheet?.statusLabel == 'Approved' ||
							expenseSheetData?.results?.accountEmployeeExpenseSheet?.statusLabel == 'Submitted'
						}
						onClick={() => {
							submitExpenseSheet({
								accountEmployeeExpenseSheetId: params.id,
								submittedBy: loggedUser.username
							});
						}}>
						Submit <CheckIcon style={{ marginLeft: '10px' }} />
					</Button>
				</Grid>
			</Grid>
			<ApprovalDetailsModal
				openApprovalDetailsModal={openApprovalDetailsModal}
				setOpenApprovalDetailsModal={setOpenApprovalDetailsModal}
				mainExpenseSheetData={mainExpenseSheetData}
				expenseSheetData={expenseSheetData}
			/>
			{openModalNewExpenseLine ? (
				<NewExpenseLine
					loggedUser={loggedUser}
					setOpenModalNewExpenseLine={setOpenModalNewExpenseLine}
					openModalNewExpenseLine={openModalNewExpenseLine}
					expenseSheetData={expenseSheetData}
					accountExpenseSheetId={params?.id}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
					editData={editData}
					accountEmployeeId={expenseSheetData?.results?.userData.id}
					accountEmployeeCode={expenseSheetData?.results?.userData.employeeCode}
					description={watch('newSheetDescription')}
					dateOptions={startDateOptions}
					shouldOpenModal={shouldOpenModal}
					setShouldOpenModal={setShouldOpenModal}
				/>
			) : (
				<></>
			)}
			{/* Dialog for Time Entry Attachments */}
			<Dialog
				maxWidth="lg"
				fullWidth
				modal={{
					open: Boolean(openAttachModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenAttachModal(false);
					},
					content: (
						<>
							{isLoading && <LoadingOverlay />}
							<Table
								tableData={tableDataAttachments}
								columns={tableColumnsAttachments}
								actions={tableActionsAttachments}
								defaultSortColumn="name"
								handleSearch={{ searchValue: searchValueAttach, setSearchValue: setSearchValueAttach }}
							/>
							<Grid container direction="row" justifyContent="center" alignItems="center">
								<CoreInput
									value={inputValue}
									disabled={
										expenseSheetData?.results?.accountEmployeeExpenseSheet
											?.accountEmployeeExpenseSheetProcessId != null &&
										expenseSheetData?.results?.loggedUserRole != 1165 &&
										!hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN)
									}
									onChange={changeHandler}
									type="file"
									inputProps={{ accept: 'file' }}
								/>
								<Button
									onClick={saveAttachment}
									disabled={
										expenseSheetData?.results?.accountEmployeeExpenseSheet
											?.accountEmployeeExpenseSheetProcessId != null &&
										expenseSheetData?.results?.loggedUserRole != 1165 &&
										!hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN)
									}
									variant="contained"
									style={{ marginLeft: '20px' }}>
									Save Attachment
								</Button>
							</Grid>
						</>
					)
				}}
				title="Expenses Attachments"
				actions={attachModalAction}
				scroll="body"
			/>
			{/* Dialog for Delete Warning */}
			<Dialog
				modal={{
					open: Boolean(showDeleteWarningModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setShowDeleteWarningModal(false);
					},
					content: (
						<>
							<Typography gutterBottom>Are you sure you want to delete this expense entry?</Typography>
						</>
					)
				}}
				title="Delete Warning"
				actions={deleteWarningActions}
				scroll="body"
			/>
		</>
	);
};

export default withLayout(MyExpensesNew);
