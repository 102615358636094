/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useCallback, useMemo, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import moment, { Moment } from 'moment';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-INTERNAL-APPS-LIB
import { DatePicker, InfoField, Select, Input, Checkbox } from 'xpand-ui/forms';
// import { parseDateToManage } from 'xpand-ui/utils/dates';
import { removeTimezone, parseDateToManageSaf } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { Dialog } from 'xpand-ui/core';

//* TYPINGS
import { IUserToken } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';
import { Roles } from 'lib/roles';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { schema } from './yupSchema';
import { getRandomInt, parseEventData, sortDates } from '..';
import { IDeskEvent } from '../../@types';
import { getLSField } from 'lib/utils/cookies';

const parseDateToManage = (date: Date | Moment | string, clearHour = false): string =>
	clearHour
		? `${moment(removeTimezone(date)).format('YYYY/MM/DD')} 00:00:00`
		: moment(removeTimezone(date)).format('YYYY/MM/DD HH:mm:ss');

//* COMPONENT INTERFACES
interface IEventModal {
	closePopup: any;
	schedulerRef: any;
	isAdmin: boolean;
	loggedUser: IUserToken;
	eventRecord: any;
	eventAction: any;
	resources: any;
	users: any[];
	parkingBookingsLimit: number;
	userParkingBookingsCount: number;
	seatRequiredToBookParking: boolean;
	toolbarSelected: {
		currentUser: string | null;
		company: string | null;
		office: string | null;
		companyName: string | null;
		officeName: string | null;
	};
	isEdit: boolean;
	setIsEdit: any;
	events: any;
	tempEventData: any;

	// resourceStore: any;
}

export const checkIfNewEventDateOverlap = (
	events: any,
	values: any,
	seatOptions: any,
	parkingBookingsLimit: number,
	userParkingBookingsCount: number,
	seatRequiredToBookParking: boolean,
	isBookingsAdmin: boolean
): boolean => {
	let result = events?.filter((record: any) => !record.blockedSeat && values.internalId !== record.id);
	result = result?.map((e: any) => ({ ...e.data, endDate: new Date(new Date(e.endDate).getTime() - 60) }));
	const requestedSeat = seatOptions.find((seat: any) => seat.id === values.resourceId);
	const hasReachedMaxParkingBookings =
		(requestedSeat.type === 'PARKING' || requestedSeat.type === 'BICYCLE') &&
		!requestedSeat.restricted &&
		userParkingBookingsCount + 1 > parkingBookingsLimit;

	const reservationOnSameDay = result?.find(
		(seat: any) =>
			parseDateToManage(seat.startDate, true) === parseDateToManage(values.startDate, true) &&
			seat.username === values.username
	);

	//Gets the seat type of the reservation done in the respective day
	const seatType = seatOptions?.find((seat: any) => seat?.id == reservationOnSameDay?.resourceId);

	//Checks if has already reservation marked in the respective day and checks if its not parking or its not bicycle
	//if so then it has seat in the same day if false it does not have seat on same day
	const hasSeatInSameDayAndSameUser =
		reservationOnSameDay && seatType?.type !== 'PARKING' && seatType?.type !== 'BICYCLE';

	const isSeatTaken = result?.find(
		(seat: any) =>
			parseDateToManage(seat.startDate, true) === parseDateToManage(values.startDate, true) &&
			seat.resourceId == values.resourceId
	);

	const hasSeatInSameDayAndSameUserAndSameEvent = result?.find(
		(seat: any) =>
			parseDateToManage(seat.startDate, true) === parseDateToManage(values.startDate, true) &&
			seat.username === values.username &&
			seat.id === values.id
	);
	if (!isBookingsAdmin) {
		if ((requestedSeat.type === 'PARKING' || requestedSeat.type === 'BICYCLE') && result === null) {
			return true;
		}
		if (isSeatTaken) {
			return true;
		}
		if (hasSeatInSameDayAndSameUserAndSameEvent) {
			return false;
		}

		if (parkingBookingsLimit == 0) {
			if (seatRequiredToBookParking) {
				if (requestedSeat.type === 'PARKING' || requestedSeat.type === 'BICYCLE') {
					return hasSeatInSameDayAndSameUser;
				} else {
					return hasSeatInSameDayAndSameUser;
				}
			} else {
				if (requestedSeat.type === 'PARKING' || requestedSeat.type === 'BICYCLE') {
					return false;
				} else {
					return hasSeatInSameDayAndSameUser;
				}
			}
		} else {
			if (seatRequiredToBookParking) {
				if (requestedSeat.type === 'PARKING' || requestedSeat.type === 'BICYCLE') {
					if (hasSeatInSameDayAndSameUser && !hasReachedMaxParkingBookings) {
						return false;
					} else {
						return true;
					}
				} else {
					return hasSeatInSameDayAndSameUser;
				}
			} else {
				if (requestedSeat.type === 'PARKING' || requestedSeat.type === 'BICYCLE') {
					return hasReachedMaxParkingBookings;
				} else {
					if (hasSeatInSameDayAndSameUser) {
						return true;
					}
					return false;
				}
			}
		}
	} else {
		return false;
	}
};

const DateAux = new Date();
DateAux.setHours(0, 0, 0, 0);

// Admin Start Date (View 1 year back)
const dateStartAdmin = new Date();
dateStartAdmin.setDate(dateStartAdmin.getDate() - 365);
dateStartAdmin.setHours(0, 0, 0, 0);

const max90DaysFromNow = parseDateToManage(new Date(DateAux.setMonth(DateAux.getMonth() + 3)));

// Admin End Date
const dateEndAdmin = new Date();
dateEndAdmin.setMonth(dateEndAdmin.getMonth() + 3);
dateEndAdmin.setHours(0, 0, 0, 0);

//* COMPONENT
const EventModal: FC<IEventModal> = ({
	events,
	tempEventData,
	isAdmin,
	resources,
	toolbarSelected,
	loggedUser,
	closePopup,
	eventRecord,
	eventAction,
	users,
	parkingBookingsLimit,
	userParkingBookingsCount,
	seatRequiredToBookParking,
	isEdit,
	setIsEdit
}) => {
	const classes = useStyles();
	const [isBookingSeat, setIsBookingSeat] = useState('');
	const [initialEditData, setInitialEditData] = useState(false);
	let zone;
	// form

	resources?.forEach(r => {
		r.children.forEach(s => {
			if (s.id === eventRecord?.resource) zone = r.id;
		});
	});

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		getValues,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues: {
			name: eventRecord?.username?.toUpperCase() || loggedUser.username.toUpperCase(),
			fullName: loggedUser.name,
			createdBy: eventRecord?.username || loggedUser.username,
			username: eventRecord?.username || loggedUser.username,
			duration: eventRecord?.duration || 1,
			zoneId: zone || '',
			resourceId: eventRecord?.resource || '',
			isParkingSeatSelected: eventRecord?.isParkingSeatSelected || false,
			isBycicleSeatSelected: eventRecord?.isBycicleSeatSelected || false,
			licensePlate: eventRecord?.licensePlate || '',
			startDate: parseDateToManage(eventRecord?.start || new Date(), true).replace('00:00:00', '00:00:00'),
			endDate: parseDateToManage(eventRecord?.end || new Date(), true).replace('00:00:00', '23:59:00'),
			isAdmin,
			validated: true,
			isClient: false,
			previousId: '',
			previousStartDate: '',
			previousEndDate: '',
			isBlockedSeat: false,
			blockedReason: ''
		}
	});
	const userPermissionsStorage = getLSField('userPermissions');
	const userPermissions = (userPermissionsStorage && JSON.parse(userPermissionsStorage)) || null;
	let isBookingsAdmin = false;

	userPermissions.forEach((userPermission: any) => {
		if (userPermission.displayName === Roles.CP_ROLE_LOGISTICS) {
			isBookingsAdmin = true;
		}
	});

	useEffect(() => {
		if (isEdit && isAdmin) {
			let zoneId;
			resources.forEach((e: any) => {
				e.children.forEach((c: any) => {
					if (c.id == eventAction?.resourceId) {
						zoneId = c.zoneId;
					}
				});
			});
			setValue('name', eventAction?.username?.toUpperCase() || loggedUser.username.toUpperCase());
			setValue('fullName', eventAction?.fullName);
			setValue('createdBy', eventAction?.createdBy);
			setValue('username', eventAction?.username.toString().toLowerCase());
			setValue('duration', eventAction?.duration);
			setValue('zoneId', zoneId);
			setValue('resourceId', eventAction?.resourceId);
			setValue('isParkingSeatSelected', eventAction?.isParkingSeatSelected || false);
			setValue('isBycicleSeatSelected', eventAction?.isBycicleSeatSelected || false);
			setValue('licensePlate', eventAction?.licensePlate);
			setValue('startDate', parseDateToManage(eventAction?.startDate));
			setValue('endDate', parseDateToManage(eventAction?.startDate));
			setValue('isAdmin', isAdmin);
			setValue('validated', eventAction?.validated);
			setValue('isClient', false);
			setValue('previousId', eventAction?.id);
			setValue('previousEndDate', parseDateToManage(eventAction?.endDate));
			setValue('previousStartDate', parseDateToManage(eventAction?.startDate));
			setInitialEditData(true);
		}
	}, [isEdit]);

	/* A callback function that is used to check if the new event is overlapping with any existing events. */
	const blockAddEvent = useCallback((start: Date, end: Date, payload: any) => {
		const isWeekend = start.getDay() === 0 || start.getDay() === 6;

		const overlappedEvents = checkIfNewEventDateOverlap(
			events,
			{
				internalId: eventRecord?.id || 9999999999999999999999, // dummy id when creating the event on the modal
				id: payload.id || payload.previousId,
				resourceId:
					eventRecord?.resourceId !== payload.resourceId
						? payload.resourceId
						: eventRecord?.resourceId || payload.resourceId,
				username: payload.username,
				startDate: start,
				endDate: end
			},
			allSeats,
			parkingBookingsLimit,
			userParkingBookingsCount,
			seatRequiredToBookParking,
			isBookingsAdmin
		);
		return isWeekend || overlappedEvents;
	}, []);

	/* Splitting a multi-day event into single day events. */
	const splitDays = useCallback((event: IDeskEvent, initialEvent: { start: string | number | Date }) => {
		const events: IDeskEvent[] = [];
		if (event.duration > 1) {
			// for the duration of the original event, add new events individually through all the days
			Array.from({ length: event.duration }, (_, i) => i).forEach(plusDay => {
				// SET START DATE AT 00:00:00
				const newStart = new Date(
					new Date(parseDateToManageSaf(initialEvent.start)).getTime() + plusDay * (1000 * 3600 * 24)
				);
				// SET END DATE EQUAL TO THE START, BUT AT 23:59:00
				const newEnd = new Date(
					new Date(parseDateToManageSaf(initialEvent.start)).getTime() +
						(plusDay + 1) * (1000 * 3600 * 24) -
						60
				);
				// If new singleDayEvent is weekend OR is overlapped by some other event, dont add it
				if (!blockAddEvent(newStart, newEnd, event)) {
					events.push({
						...event,
						customId: `${getRandomInt()}`,
						duration: 1,
						startDate: newStart,
						endDate: newEnd
					});
				}
			});
		} else {
			return [event];
		}
		return events;
	}, []);

	/** handler to save the selected event payload into the store and DB */
	const saveClickHandler = async (payload: any, isBookingsAdmin: boolean) => {
		let notPossibleMessage = '';
		if (parkingBookingsLimit == 0) {
			if (seatRequiredToBookParking) {
				notPossibleMessage =
					'It was not possible to save booking. Please confirm if you: have a booking for the same day; location is unavailable; booking is being made for a weekend.';
			} else {
				notPossibleMessage =
					'It was not possible to save booking. Please confirm if you: have a booking for the same day; location is unavailable; booking is being made for a weekend; have booked the parking space before the seat.';
			}
		} else {
			if (seatRequiredToBookParking) {
				notPossibleMessage =
					'It was not possible to save booking. Please confirm if you: have a booking for the same day; location is unavailable; booking is being made for a weekend; you reached maximum parking bookings (' +
					parkingBookingsLimit +
					'); have booked the parking space before the seat.';
			} else if (parkingBookingsLimit <= userParkingBookingsCount) {
				notPossibleMessage =
					'It was not possible to save booking. Please confirm if you: have a booking for the same day; seat is occupied; location is unavailable; booking is being made for a weekend; you reached maximum parking bookings (' +
					parkingBookingsLimit +
					').';
			} else {
				notPossibleMessage =
					'It was not possible to save booking. Please confirm if you: have a booking for the same day; location is unavailable; booking is being made for a weekend.';
			}
		}
		const notPossibleSeatParkingMessage = (
			<p>
				For urgent bookings, please contact the Logistic team via{' '}
				<a href="mailto:xp.facilities@xpand-it.com"> xp.facilities@xpand-it.com</a> or via Teams (Patrícia
				Carlos and Marta Santos)
			</p>
		);
		/* Parsing the event data and returning a new event object. */
		const newEvent = parseEventData({ ...payload, isAdmin }, loggedUser.username);

		const currentMoment = new Date();

		const today = new Date();
		today.setHours(0, 0, 0, 0);

		const tomorrow = new Date();
		tomorrow.setHours(0, 0, 0, 0);
		tomorrow.setDate(tomorrow.getDate() + 1);

		const inOneWeek = new Date();
		inOneWeek.setDate(inOneWeek.getDate() + 7);
		if ((newEvent.isParkingSeatSelected || newEvent.isBycicleSeatSelected) && !isBookingsAdmin) {
			// Parking reservations must be made until previous day at 4:00 PM in advance
			if (
				newEvent.startDate?.getTime() == today.getTime() ||
				(newEvent.startDate?.getTime() == tomorrow.getTime() && currentMoment.getHours() >= 16)
			) {
				isEdit ? setIsEdit(false) : closePopup(null);
				addNotification('info', notPossibleSeatParkingMessage, 15);
				return;
			}
			// Parking reservations from Friday to the Next Monday – it is only possible to book until 4:00 pm on Friday
			const isFriday = currentMoment.getDay() === 5; // 5 = friday
			const isWeekend = [0, 6].includes(currentMoment.getDay()); // 6 = Saturday, 0 = Sunday
			const isAfter4PM = currentMoment.getHours() >= 16; // after 4:00 pm
			const isNewEventOnMonday = newEvent.start?.getDay() === 1; // 1 = friday
			const isNextMonday = newEvent.start?.getTime() < inOneWeek.getTime(); // Just the next monday

			if (((isFriday && isAfter4PM) || isWeekend) && isNewEventOnMonday && isNextMonday) {
				closePopup(null);
				addNotification('info', notPossibleSeatParkingMessage, 15);
				return;
			}
		}

		let dbChanges = null;
		let hasBlockedDays = false;

		/* if the startDate is bigger than the startDate(possible through the drag creation on mobiscroll), sort 
		those dates by destructuring the array returned by the sorter */
		const [sd, ed] = sortDates(new Date(newEvent.start), new Date(newEvent.end));
		/* Calculating the number of days between two dates. */
		const days = Math.ceil((ed.getTime() - sd.getTime()) / (1000 * 3600 * 24));
		newEvent.duration = days;
		if (!isEdit) {
			//* event is created from mobiscroll
			if (eventRecord) {
				// We need to reset this flag to tell scheduler that this is a real event
				eventRecord.isCreating = false;

				// Update the eventRecord using the default setters
				// eventRecord.beginBatch();

				// ? MULTIPLE DAYS EVENT ---> SPLIT IT TO SINGLE DAYS
				// ? (already filters if new singleDayEvent is weekend OR is overlapped by some other event, dont add it)
				const events = splitDays(newEvent, payload);
				if (newEvent.duration !== events.length) {
					hasBlockedDays = true;
				}
				dbChanges = events;
				if (events.length == 1) {
					// ? SINGLE DAY ADD
					if (blockAddEvent(new Date(newEvent.start), new Date(newEvent.end), newEvent)) {
						eventRecord.isCreating = true;
						closePopup(null);
						addNotification('info', notPossibleMessage, 15);
						return;
					}
				}
			} else {
				//* event is created from MANUALLY top right button
				// ? MULTIPLE DAYS EVENT ---> SPLIT IT TO SINGLE DAYS
				// ? (already filters if new singleDayEvent is weekend OR is overlapped by some other event, dont add it)
				const events = splitDays(newEvent, payload);
				if (newEvent.duration !== events.length) {
					hasBlockedDays = true;
				}
				dbChanges = events;
				if (events.length == 1) {
					if (blockAddEvent(new Date(newEvent.start), new Date(newEvent.end), newEvent)) {
						closePopup(null);
						addNotification('info', notPossibleMessage, 10);
						return;
					}
				}
			}
		} else {
			/* This sections occurs when its editing a booking */
			// We need to reset this flag to tell scheduler that this is a real event
			eventAction.isCreating = false;

			// Update the eventRecord using the default setters
			//eventAction.beginBatch();

			// ? MULTIPLE DAYS EVENT ---> SPLIT IT TO SINGLE DAYS
			// ? (already filters if new singleDayEvent is weekend OR is overlapped by some other event, dont add it)
			const events = splitDays(newEvent, payload);

			if (newEvent.duration !== events.length) {
				hasBlockedDays = true;
			}
			dbChanges = events;

			if (events.length == 1) {
				// ? SINGLE DAY ADD
				// If new singleDayEvent is weekend OR is overlapped by some other event, REMOVE IT
				if (blockAddEvent(new Date(newEvent.start), new Date(newEvent.end), newEvent)) {
					eventAction.isCreating = true;
					setIsEdit(false);
					addNotification('info', notPossibleMessage, 15);
					return;
				}

				// set correct values to edited event
				eventAction = { ...newEvent };
			}
		}

		/* Checking if the hasBlockedDays variable is true, if it is, it will add a notification to the
		screen. */
		if (hasBlockedDays) {
			addNotification('info', notPossibleMessage, 10);
		}
		closePopup(dbChanges);
	};

	const filtersModalActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => (isEdit ? setIsEdit(false) : closePopup(null))
		},
		{
			id: 'submit',
			label: 'Submit',
			color: 'primary',
			type: 'submit',
			form: 'form-desk-event-modal',
			variant: 'contained',
			disabled: Object.keys(errors).length !== 0 || getLSField('impersonate_userInfo'),
			onClick: () => null
		}
	];

	const currentZone = resources.find((e: any) => e.id === getValues('zoneId'));
	let seatOptions = [];
	if (currentZone) {
		seatOptions = currentZone.children.map((e: any) => ({
			id: e.id,
			label: e.name,
			type: e.type,
			restricted: e.restricted
		}));
	}

	const allSeats = useMemo(() => {
		const completeList: any = [];

		resources.forEach(currentZone => {
			currentZone.children.forEach(seat => {
				completeList.push({
					id: seat.id,
					label: seat.name,
					type: seat.type,
					restricted: seat.restricted
				});
			});
		});

		return completeList;
	}, []);

	/* Using the useWatch hook to watch the control object for changes to the resourceId property. */
	const resource = useWatch({
		control,
		name: 'resourceId',
		defaultValue: eventRecord?.resourceId
	});

	/* Filters users options according to selected seat */
	const usersOptions = useMemo(() => {
		if (!resource)
			return users.map((e: any) => ({
				id: e.username,
				label: `${e.displayName} (${e.username.toUpperCase()})`
			}));

		const currentSeat = seatOptions.find((e: any) => e.id === resource);

		if (!isAdmin && (currentSeat.type === 'PARKING' || currentSeat.type === 'BICYCLE')) {
			const userData = users.find((u: any) => u.username === loggedUser.username);
			if (userData) {
				setValue('username', loggedUser.username);
				return [{ id: userData.username, label: `${userData.displayName} (${userData.username})` }];
			}

			return [];
		} else {
			return users.map((e: any) => ({
				id: e.username,
				label: `${e.displayName} (${e.username.toUpperCase()})`
			}));
		}
	}, [resource, seatOptions]);

	/* Checks if selected seat is Parking to show licensePlate input */
	useEffect(() => {
		const currentSeat = seatOptions.find((e: any) => e.id === resource);

		if (currentSeat?.type === 'PARKING') {
			setValue('isParkingSeatSelected', true);
			setValue('isBycicleSeatSelected', false);
		} else if (currentSeat?.type === 'BICYCLE') {
			setValue('isBycicleSeatSelected', true);
			setValue('isParkingSeatSelected', false);
			setValue('licensePlate', '');
		} else {
			setValue('isParkingSeatSelected', false);
			setValue('isBycicleSeatSelected', false);
			setValue('licensePlate', '');
		}
	}, [resource]);

	useEffect(() => {
		resources?.forEach(r => {
			r.children.forEach(s => {
				if (s.id === getValues('resourceId')) setIsBookingSeat(s.type);
			});
		});
	}, []);

	return (
		<Dialog
			title={isEdit ? 'Edit Booking' : 'Create Booking'}
			actions={filtersModalActions}
			scroll="body"
			modal={{
				// Sending Edit or Add mode
				open: true,
				handleClose: () => (isEdit ? setIsEdit(false) : closePopup(null)),
				content: (
					<form
						id="form-desk-event-modal"
						onSubmit={e => handleSubmit(() => saveClickHandler(watch(), isBookingsAdmin))(e)}>
						<Grid container spacing={2} style={{ padding: '10px 5px 40px 5px' }}>
							<Grid item {...sizes[12]} className={classes.centerFieldInfo}>
								<InfoField label="Company" value={toolbarSelected.companyName} />
							</Grid>
							<Grid item {...sizes[12]} className={classes.centerFieldInfo}>
								<InfoField label="Office" value={toolbarSelected.officeName} />
							</Grid>
							<Grid item {...sizes[6]}>
								<DatePicker
									required
									desktopModeMediaQuery={undefined}
									maxDate={moment(max90DaysFromNow)}
									minDate={
										isEdit
											? moment(new Date())
											: isAdmin
											? moment(dateStartAdmin)
											: moment(DateAux.getDate(), 'DD/MM/YYYY')
									}
									name="startDate"
									label="Start Date"
									additionalOnChange={date => {
										if (!isEdit) {
											const endDateHelper = watch('endDate');
											if (date && endDateHelper && endDateHelper !== '') {
												const start = new Date(parseDateToManage(date, true));
												const end = new Date(parseDateToManage(endDateHelper, true));

												if (start.getTime() > end.getTime()) {
													setValue('endDate', '');
												}
											}
										}
										setValue('endDate', getValues('startDate'));
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<DatePicker
									required
									desktopModeMediaQuery={undefined}
									maxDate={moment(max90DaysFromNow)}
									disabled={isEdit}
									minDate={
										isEdit
											? moment(new Date())
											: isAdmin
											? moment(dateStartAdmin)
											: moment(DateAux.getDate(), 'DD/MM/YYYY')
									}
									label="End Date"
									name="endDate"
									additionalOnChange={date => {
										const startDateHelper = watch('startDate');
										if (date && startDateHelper && startDateHelper !== '') {
											const start = new Date(parseDateToManage(startDateHelper, true));
											const end = new Date(parseDateToManage(date, true));

											if (start.getTime() > end.getTime()) {
												setValue('startDate', '');
											}
										}
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Select
									required
									name="zoneId"
									label="Zone"
									options={resources.map((e: any) => ({ id: e.id, label: e.name }))}
									additionalOnChange={e => {
										setValue('resourceId', '');
										setValue('isParkingSeatSelected', false);
										setValue('isBycicleSeatSelected', false);
										setValue('licensePlate', '');
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Select
									required
									name="resourceId"
									label="Location"
									options={seatOptions}
									control={control}
									errors={errors}
								/>
							</Grid>
							{isBookingsAdmin && (
								<Grid item {...sizes[6]}>
									<Checkbox
										color="primary"
										name="isClient"
										label="Client booking ?"
										additionalOnChange={e => {
											if (e) {
												setValue('username', 'Client');
												setValue('licensePlate', '');
											} else {
												setValue('username', eventRecord?.username || loggedUser.username);
											}
										}}
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{!watch('isClient') && (
								<Grid item {...sizes[6]}>
									<Select
										required
										disabled={isEdit}
										name="username"
										label="Employee"
										options={usersOptions}
										additionalOnChange={({ value }) => {
											const user = users.find(e => e.username === value);
											setValue('fullName', user.displayName);
											if (loggedUser.username.toLowerCase() === value.toLowerCase()) {
												setValue('validated', true);
											} else {
												setValue('validated', false);
											}
										}}
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{watch('isParkingSeatSelected') && !watch('isClient') && (
								<Grid item {...sizes[6]}>
									<Input
										required
										name="licensePlate"
										label="LICENSE PLATE"
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{isBookingSeat == 'SEAT' && isEdit && (
								<Typography
									gutterBottom
									style={{ marginTop: '20px', color: 'red', fontWeight: 'bold' }}>
									Warning: If there is a parking booking for same user on same day, it'll be deleted.
								</Typography>
							)}
						</Grid>
					</form>
				)
			}}
		/>
	);
};

export default EventModal;
