/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { FC, useState, useEffect, useMemo } from 'react';

import { Grid, Typography, TextField, List, Checkbox } from '@mui/material';
import { Add, Clear, DeleteOutline } from '@mui/icons-material';

import {
	LoadingOverlay,
	PageTitle,
	NoData,
	TabPanel,
	TabHeader,
	ActionFooter,
	Dialog,
	TreeView,
	Tab
} from 'xpand-ui/core';

import { ITreeViewData } from 'xpand-ui/core/TreeView/TreeView';
import { InputLabel } from 'xpand-ui/forms';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';

import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

import { ISkillGroup, ISkillSubGroup, ISystemSkills } from 'typings/store/personalInfoTypes';
import Prompt from '../../../../components/Prompt/Prompt';
import {
	// REMOVE METHODS
	removeSkillAreaFromList,
	removeGroupFromList,
	removeSubGroupFromList,
	removeSkillFromList,
	// ADD METHODS
	AddSkillArea,
	AddGroupToSkillArea,
	AddSubGroupToGroup,
	AddSkillToSubGroup,
	// CHANGE NAME METHODS
	changeSkillAreaName,
	changeGroupName,
	changeSubGroupName,
	changeSkillName,
	getNodeIdBranch,
	SkillsTreeBranches
} from './utils';
import { useStyles } from './styles';
import Adicionar from '../../../../assets/icons/Adicionar.svg';
import { ManageSkillsProps } from '.';
import { getLSField } from 'lib/utils/cookies';

// interface IManageSkill extends ManageSkillsProps {
// 	goToPage: (path: string) => void;
// }

const ManageSkills: FC<ManageSkillsProps> = ({
	personalInfo,
	skillsAndResumes,
	getSystemSkills,
	sendPostNewSkillsTree,
	clearPersonalInfoError,
	setClearSystemSkills
}) => {
	const classes = useStyles();
	/** Redux */
	const { loading, systemSkills, error } = personalInfo;
	const { loading: loadingManageSkills, newSkillsTreeAction } = skillsAndResumes;
	const [areChangesMade, setChangesMade] = useState(false);

	/** TABS COMPONENT State */
	const [newSkillAreaName, setNewSkillAreaName] = useState('');
	const [tabSelected, setTabSelected] = useState<number>(0);
	const [addModalOpen, setAddModalOpen] = useState(false);
	const [removeModalOpen, setRemoveModalOpen] = useState(false);
	const [skillsParsed, setSkillsParsed] = useState<ISystemSkills[] | null>(null);
	const [checked, setChecked] = useState({});
	const [toDelete, setToDelete] = useState(false);
	const [paddingDimensions, setPaddingDimensions] = useState<number>(80);

	const [openConfirmationModal, setOpenConfirmationModal] = useState<{
		isOpen: boolean;
		skillsParsed: ISystemSkills[] | null;
		areaId: number | undefined;
		groupId: number | undefined;
		subGroupId?: number;
		skillsId?: number;
	}>({
		isOpen: false,
		skillsParsed: null,
		areaId: undefined,
		groupId: undefined,
		subGroupId: undefined,
		skillsId: undefined
	});

	const { width } = useWindowDimensions();
	const isMobile = useMemo(() => width <= 600, [width]); // default is 600 mobile
	const isTablet = useMemo(() => width <= 900, [width]); // default is 900 tablet
	useEffect(() => {
		if (isMobile) {
			setPaddingDimensions(10);
		} else if (isTablet) {
			setPaddingDimensions(40);
		} else {
			setPaddingDimensions(80);
		}
	}, [isMobile, isTablet]);

	/** TREEVIEW COMPONENT - actions helper */
	const removeChildAction = useMemo(
		() =>
			(
				areaId: number | undefined,
				groupId: number | undefined,
				subGroupId: number | undefined,
				skillsId: number | undefined,
				contextName: string
			) => ({
				id: `${areaId}, ${groupId}, ${subGroupId}, ${skillsId}_remove_action`,
				label: `Remove ${contextName}`,
				icon: <Clear />,
				onClick: () => {
					if (skillsId) {
						return setOpenConfirmationModal({
							isOpen: true,
							skillsParsed,
							areaId,
							groupId,
							subGroupId,
							skillsId
						});
					}
					if (subGroupId) {
						return setOpenConfirmationModal({
							isOpen: true,
							skillsParsed,
							areaId,
							groupId,
							subGroupId
						});
					}
					if (groupId) {
						return setOpenConfirmationModal({
							isOpen: true,
							skillsParsed,
							areaId,
							groupId
						});
					}
					return null;
				}
			}),
		[skillsParsed]
	);

	/** TREEVIEW COMPONENT - actions helper */
	const addChildAction = useMemo(
		() =>
			(
				areaId: number | undefined,
				groupId: number | undefined,
				subGroupId: number | undefined,
				skillsId: number | undefined,
				contextName: string,
				isSection: boolean
			) => ({
				id: `${areaId}, ${groupId}, ${subGroupId}, ${skillsId}, ${isSection}_add_action`,
				label: `Add ${contextName}`,
				icon: <Adicionar />,
				onClick: () => {
					setChangesMade(true);
					if (subGroupId) {
						return setSkillsParsed(AddSkillToSubGroup(skillsParsed, subGroupId));
					}
					if (groupId) {
						return setSkillsParsed(AddSubGroupToGroup(skillsParsed, groupId, isSection));
					}
					if (areaId) {
						return setSkillsParsed(AddGroupToSkillArea(skillsParsed, areaId));
					}

					return setSkillsParsed(AddSkillArea(skillsParsed, null));
				}
			}),
		[skillsParsed]
	);

	const confirmActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setOpenConfirmationModal({ ...openConfirmationModal, isOpen: false })
		},
		{
			id: 'delete',
			label: 'Delete',
			color: 'primary',
			variant: 'contained',
			// eslint-disable-next-line consistent-return
			onClick: () => {
				setChangesMade(true);
				setOpenConfirmationModal({ ...openConfirmationModal, isOpen: false });
				if (openConfirmationModal.skillsId) {
					return setSkillsParsed(removeSkillFromList(skillsParsed, openConfirmationModal.skillsId));
				}
				if (openConfirmationModal.subGroupId) {
					return setSkillsParsed(removeSubGroupFromList(skillsParsed, openConfirmationModal.subGroupId));
				}
				if (openConfirmationModal.groupId) {
					return setSkillsParsed(removeGroupFromList(skillsParsed, openConfirmationModal.groupId));
				}
			}
		}
	];

	/** HANDLER TO ASSIGN ADD/REMOVE ACTIONS NODES */
	const createTreePayload = useMemo(
		() => (payload: ISystemSkills[]) => {
			const newPayload: ISystemSkills[] =
				payload &&
				payload.map((area: ISystemSkills) => {
					if (area.toDelete) return area;
					const newGroups = area.data?.map(group => {
						const newSubGroups = group.data?.map(subGroup => {
							const newSkills = subGroup.data?.map(skill =>
								// NEW SKILL PAYLOAD
								({
									id: skill.id,
									customId: skill.id ? skill.id + 400 : skill.customId, // 400 added in order to ensure that the id will be unique
									name: skill.name,
									editable: true,
									actions: [
										removeChildAction(
											area.customId,
											group.customId,
											subGroup.customId,
											skill.customId,
											'skill'
										)
									]
								})
							);

							// NEW SKILL SUB GROUP PAYLOAD
							return {
								id: subGroup.id,
								customId: subGroup.id ? subGroup.id + 300 : subGroup.customId, // 200 added in order to ensure that the id will be unique
								name: subGroup.section ? subGroup.name : undefined,
								// ) : (
								// 	<Typography className={classes.hiddenSubGroup}>Hidden Subgroup</Typography>
								// ),
								section: subGroup.section,
								editable: subGroup.section,
								actions: [
									addChildAction(
										area.customId,
										group.customId,
										subGroup.customId,
										undefined,
										'skill',
										false
									),
									removeChildAction(
										area.customId,
										group.customId,
										subGroup.customId,
										undefined,
										subGroup.name ? 'skill subgroup ' : 'hidden subgroup'
									)
								],
								data: newSkills
							};
						});
						const groupActions = [];

						if (newSubGroups && newSubGroups.length === 0) {
							groupActions.push(
								addChildAction(
									area.customId,
									group.customId,
									undefined,
									undefined,
									'hidden skill subgroup ',
									false
								)
							);
							groupActions.push(
								addChildAction(
									area.customId,
									group.customId,
									undefined,
									undefined,
									'visible skill subgroup',
									true
								)
							);
						}
						if (newSubGroups && newSubGroups.length > 0) {
							const some = newSubGroups.some(e => e.section);
							if (some) {
								groupActions.push(
									addChildAction(
										area.customId,
										group.customId,
										undefined,
										undefined,
										'visible skill subgroup',
										true
									)
								);
							}
						}
						groupActions.push(
							removeChildAction(area.customId, group.customId, undefined, undefined, 'skill group ')
						);

						// NEW SKILL GROUP PAYLOAD
						return {
							id: group.id,
							customId: group.id ? group.id + 200 : group.customId, // 200 added in order to ensure that the id will be unique
							name: group.name,
							editable: true,
							actions: groupActions,
							data: newSubGroups
						};
					});

					// NEW AREA PAYLOAD
					// NEW SKILL GROUP PAYLOAD
					return {
						id: area.id,
						customId: area.id ? area.id + 100 : area.customId, // 100 added in order to ensure that the id will be unique
						name: area.name,
						actions: [],
						data: newGroups
					};
				});

			return newPayload;
		},
		[skillsParsed]
	);

	const clearFormData = useMemo(
		() =>
			(data: ISystemSkills[]): ISystemSkills[] | null => {
				const payload = data.map((a: ISystemSkills, ia: number) => {
					if (a.toDelete) return a;

					const payA = { ...a };
					delete payA.actions;
					delete payA.customId;

					const dataGroups = payA.data?.map((g: ISkillGroup, ig: number) => {
						const payG = { ...g };
						delete payG.actions;
						delete payG.customId;
						delete payG.editable;

						const dataSubGroups = payG.data?.map((s: ISkillSubGroup) => {
							const payS = { ...s, name: s.section ? s.name : undefined };
							delete payS.actions;
							delete payS.customId;
							delete payS.editable;

							const dataSkills = payS.data?.map(sk => {
								const paySK = { ...sk };
								delete paySK.actions;
								delete paySK.customId;
								delete paySK.editable;
								delete paySK.data;

								return {
									...paySK
								};
							});

							return {
								...payS,
								data: dataSkills
							};
						});

						return {
							...payG,
							weight: ig + 1,
							data: dataSubGroups
						};
					});

					return {
						...payA,
						weight: ia + 1,
						data: dataGroups
					};
				});
				return payload;
			},
		[]
	);

	/** CREATE RENDER FIELD WITH ACTIONS TO ADD/REMOVE NODES */
	let treePayload = skillsParsed
		? createTreePayload(skillsParsed)
		: createTreePayload(systemSkills as ISystemSkills[]);

	/** FETCH API SKILLS AND SET IT TO STATE */
	useEffect(() => {
		setSkillsParsed(null);
		setClearSystemSkills();
	}, [newSkillsTreeAction]);

	useEffect(() => {
		if (!skillsParsed && !systemSkills) {
			getSystemSkills();
		}
		treePayload = createTreePayload(systemSkills as ISystemSkills[]);
	}, [skillsParsed, systemSkills]);

	/** FETCH API SKILLS AND SET IT TO STATE */
	useEffect(() => {
		if (!error) {
			getSystemSkills();
		}
	}, []);
	/** FETCH API SKILLS AND SET IT TO STATE */
	useEffect(() => {
		if (treePayload && !error) {
			setSkillsParsed(treePayload);
		}
	}, [systemSkills]);

	/** CHANGE NODE NAME HANDLER */
	const changeNodeName = useMemo(
		() => (areaId: number, nodeId: number, newName: string) => {
			const treeBranch = getNodeIdBranch(skillsParsed, nodeId);
			if (!treeBranch.length) return false;

			switch (treeBranch) {
				case SkillsTreeBranches.SKILLS:
					return setSkillsParsed(changeSkillName(skillsParsed, nodeId, newName));

				case SkillsTreeBranches.SUBGROUP:
					return setSkillsParsed(changeSubGroupName(skillsParsed, nodeId, newName));

				case SkillsTreeBranches.GROUP:
					return setSkillsParsed(changeGroupName(skillsParsed, nodeId, newName));
				default:
					return setSkillsParsed(changeSkillAreaName(skillsParsed, areaId, newName));
			}
		},
		[skillsParsed]
	);

	/** PAGE TITLE ACTIONS ADD AND REMOVE SKILL AREA */
	const pageTitleActions = useMemo(
		() => [
			{
				id: 'addSkilAreal',
				onClick: () => setAddModalOpen(true),
				icon: <Adicionar />,
				label: 'Add Skills Area'
			},
			{
				id: 'removeSkillArea',
				onClick: () => setRemoveModalOpen(true),
				color: 'secondary',
				icon: <DeleteOutline />,
				label: 'Remove Skills Area'
			},
			{
				id: 'addSkillGroup',
				onClick: () => {
					setChangesMade(true);
					skillsParsed &&
						setSkillsParsed(AddGroupToSkillArea(skillsParsed, skillsParsed[tabSelected].customId));
				},

				icon: <Adicionar />,
				label: 'Add Skill Group'
			}
		],
		[skillsParsed, tabSelected]
	);

	/** ADD SKILL AREA MODAL */
	const addSkillAreaModal = useMemo(
		() => (
			<Dialog
				modal={{
					open: addModalOpen,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setAddModalOpen(false);
					},
					content: (
						<Grid container spacing={2} style={{ padding: '10px 5px 40px 5px' }}>
							<Grid item xs={12}>
								<InputLabel required>NAME</InputLabel>
								<TextField
									fullWidth
									autoFocus
									value={newSkillAreaName}
									onChange={event => {
										setNewSkillAreaName(event.target.value);
									}}
									size="small"
									placeholder="Skill Area Name"
									variant="outlined"
								/>
							</Grid>
						</Grid>
					)
				}}
				maxWidth="sm"
				title="ADD SKILL AREA"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => {
							setAddModalOpen(false);
							setNewSkillAreaName('');
						}
					},
					{
						id: 'submit',
						label: 'Add',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							setChangesMade(true);
							setAddModalOpen(false);
							setSkillsParsed(AddSkillArea(skillsParsed, newSkillAreaName));
							setNewSkillAreaName('');
							setTabSelected(skillsParsed ? skillsParsed.length : 0);
						}
					}
				]}
				scroll="body"
			/>
		),
		[skillsParsed, newSkillAreaName, addModalOpen, setTabSelected]
	);

	//Removes every false key that are in the "to remove" object
	useEffect(() => {
		if (toDelete === true) {
			setChecked(prevState => {
				const copy = { ...prevState };

				Object.keys(copy).forEach(function (key) {
					if (copy[key] === false) {
						delete copy[key];
					}
				});
				return copy;
			});
			setToDelete(false);
		}
	}, [checked, toDelete]);
	/** REMOVE SKILL AREA MODAL */
	const removeSkillAreaModal = useMemo(
		() => (
			<Dialog
				modal={{
					open: removeModalOpen,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setRemoveModalOpen(false);
					},
					content: (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<List dense component="div" role="list">
									{treePayload &&
										treePayload.map(value => {
											const labelId = `transfer-list-item-${value.customId}-label`;
											return (
												<div className={classes.listItem} key={labelId}>
													<Checkbox
														color="primary"
														checked={
															(Object.keys(checked).length > 0 &&
																value?.customId &&
																checked[value.customId]) ||
															false
														}
														disableRipple
														onClick={(e: any) => {
															if (e.target.checked === true) {
																setChecked(
																	value?.customId
																		? {
																				...checked,
																				[value.customId]:
																					!checked[value.customId]
																		  }
																		: {}
																);
															} else {
																//If the user unchecks the box it puts the respective value to false
																//later on it will be removed from the object
																setChecked(
																	value?.customId
																		? {
																				...checked,
																				[value.customId]: false
																		  }
																		: {}
																);
																//Used in the useEffect to delete the unchecked keys from the object
																setToDelete(true);
															}
														}}
													/>
													<span className={classes.itemLabel}>{value.name}</span>
												</div>
											);
										})}
								</List>
							</Grid>
						</Grid>
					)
				}}
				maxWidth="sm"
				title="REMOVE SKILL AREA"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => {
							setRemoveModalOpen(false);
							setChecked({});
						}
					},
					{
						id: 'submit',
						label: 'Remove',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							setChangesMade(true);
							setRemoveModalOpen(false);
							setChecked({});
							setSkillsParsed(removeSkillAreaFromList(treePayload, Object.keys(checked)));
							if (treePayload.length - 1 === tabSelected) setTabSelected(0);
						}
					}
				]}
				scroll="body"
			/>
		),
		[skillsParsed, treePayload, checked, removeModalOpen, tabSelected, setTabSelected]
	);

	/** FOOTER SUBMIT ACTIONS */
	const footerActions = useMemo(
		() => [
			{
				id: 'submit',
				endIcon: 'submit',
				label: 'Submit',
				onClick: () => {
					const result = skillsParsed ? clearFormData(skillsParsed) : null;
					if (result) sendPostNewSkillsTree(result);
					setChangesMade(false);
				},
				variant: 'contained',
				disabled: getLSField('impersonate_userInfo')
			}
		],
		[skillsParsed]
	);

	if (error) return handleErrorPage(error, clearPersonalInfoError);
	/** LOADING STATE AND OVERLAY */
	const isLoading = skillsParsed === null || treePayload === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{(loading || loadingManageSkills) && <LoadingOverlay />}
			<Grid
				container
				direction="row"
				alignContent="space-evenly"
				alignItems="flex-start"
				spacing={5}
				className={classes.root}>
				{/* CONTAINER TITLE AND ACTIONS */}
				<PageTitle actions={pageTitleActions} />
				{/* ADD SKILL AREA MODAL */}
				{addSkillAreaModal}
				{/* REMOVE SKILL AREA MODAL */}
				{removeSkillAreaModal}
				{/* NO DATA COMPONENT */}
				{treePayload.length === 0 && <NoData />}
				{/* TREEVIEW AREA */}
				{treePayload.length > 0 && (
					<Grid item xs={12}>
						{/* SKILLS TABS HEADER */}
						<TabHeader
							selected={tabSelected}
							handleSelected={(event: React.SyntheticEvent<Element, Event>, newValue: number) =>
								setTabSelected(newValue)
							}
							variant="scrollable"
							scrollButtons="auto">
							{treePayload.map(
								(area, index: number) =>
									!area.toDelete && (
										<Tab
											key={`${area.customId}_tab_button`}
											label={area.name as string}
											index={index}
										/>
									)
							)}
						</TabHeader>
						{/* SKILLS TABS CONTAINER */}
						{treePayload.map(
							(area, index: number) =>
								!area.toDelete && (
									<TabPanel
										key={`Resume_${area.customId}_tab_content`}
										value={tabSelected}
										index={index}>
										{/* SKILL AREA TREEVIEW (with groups > subgroups > skills) */}
										<TreeView
											payload={treePayload[tabSelected]?.data as ITreeViewData[]} // FIXME
											padding={paddingDimensions}
											noData={<NoData />}
											idField="customId"
											handleNewLabel={(nodeId: number, newLabel: string) => {
												// FIXME
												if (treePayload[tabSelected].customId) {
													setChangesMade(true);
													changeNodeName(
														treePayload[tabSelected]?.customId as number,
														nodeId,
														newLabel
													);
												}
											}}
										/>
									</TabPanel>
								)
						)}
					</Grid>
				)}
			</Grid>
			{/* FOOTER ACTIONS */}
			<ActionFooter actions={footerActions} />
			<Dialog
				modal={{
					open: Boolean(openConfirmationModal.isOpen),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick')
							setOpenConfirmationModal({ ...openConfirmationModal, isOpen: false });
					},
					content: (
						<>
							<Typography gutterBottom>Are you sure you want to delete this item?</Typography>
						</>
					)
				}}
				title="Confirm action"
				actions={confirmActions}
				scroll="body"
			/>
			<Prompt when={areChangesMade} message="Are you sure you want to leave without saving?" />
		</>
	);
};

export default withLayout(ManageSkills);
