import React from 'react';
import { Provider } from 'react-redux';
import { History } from 'history';
import { Store } from 'redux';

/** NOTIFICATIONS PROVIDER */
import { ReactNotifications } from 'react-notifications-component';

/** ROUTING PROVIDER */
import { ConnectedRouter } from 'connected-react-router';
import { useIdToken } from 'pages/General/Login/utils';
import { setCookieIdToken } from 'lib/utils/cookies';
import { AZURE_CLIENT } from 'lib/utils/constants';
import { BrowserRouter, Router } from 'react-router-dom';

interface CommonProvidersProps {
	children: React.ReactNode;
	history: History;
	store: Store;
}

const CommonProviders: React.FC<CommonProvidersProps> = ({ children, store, history }) => {
	const isAuthed = useIdToken();

	// Adding needed cookie to request method
	if (isAuthed) {
		setCookieIdToken(`msal.${AZURE_CLIENT}.idtoken`, isAuthed);
	}
	return (
		<Provider store={store}>
			{/* react-notifications-component */}
			<ReactNotifications />
			{/* Project Routing provider, needs history field provided in the redux store setup */}
			{children}
		</Provider>
	);
};

export default CommonProviders;
