/* eslint-disable indent, func-names */
//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, BackPage, ActionFooter, Dialog } from 'xpand-ui/core';
import { Input } from 'xpand-ui/forms';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';

//* TYPINGS
import { Match } from 'typings/store/generalTypes';
import {
	IAdmissionAcademicInfo,
	IAdmissionBankInfo,
	IAdmissionContactInfo,
	IAdmissionGDPRInfo,
	IAdmissionPersonalInfo,
	IAdmissionPersonalSummaryInfo
} from 'typings/store/admin/admissions';

//* PROJECT IMPORTS [LIB / PAGES ]
import { CHECK_ADMISSION_DATA_TYPES } from 'pages/Admin/AdmissionsAndResignations/utils';
import { getFormFieldsConfig } from 'store/administration/formFields/thunks';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import {
	ContactInfoCard,
	PersonalInfoCard,
	BankingInfoCard,
	PersonalSummaryCard,
	AcademicInfoCard,
	GDPRCard,
	DOCTYPES
} from './utils';

import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { AdmissionDetailsProps } from '.';
import { getLSField } from 'lib/utils/cookies';
import {
	XPAND_CONTRACTOR_ID,
	XPAND_CONTRACTOR_ID_DE,
	XPAND_CONTRACTOR_ID_HR,
	XPAND_CONTRACTOR_ID_SE,
	XPAND_CONTRACTOR_ID_UK
} from 'lib/utils/constants';
import { useParams } from 'react-router';

//* COMPONENT INTERFACES
interface IAdmissionDetailsStruct extends AdmissionDetailsProps {
	match: Match<{
		id: string;
		type: string;
	}>;
	goToPage: (path: string) => void;
}

//* COMPONENT
const AdmissionDetails: FC<IAdmissionDetailsStruct> = ({
	goToPage,
	match,
	admissions,
	getAdmissionDetailsToValidate,
	invalidateAdmission,
	approveAdmission,
	getAdmissionFile,
	deleteAdmissionFile,
	clearAdmissionDetails,
	getSubmissionInfo,
	clearAdmissionsError
}) => {
	const classes = useStyles();
	const { userAdmissionDetailsData, loading, error } = admissions;
	const params = useParams();
	const { id: admissionId, type } = params;
	// if it's 'validate' the delete document option must be visible. if not, only show document must be visible
	const isValidate = type === CHECK_ADMISSION_DATA_TYPES.VALIDATE_SUBMISSION || null;

	const { width } = useWindowDimensions();

	const isTablet = useMemo(() => width <= 900, [width]); // default is 900 tablet

	// Personal Information
	const [personalInformation, setPersonalInformation] = useState<IAdmissionPersonalInfo | null>(null);
	// Contact Information
	const [contactInformation, setContactInformation] = useState<IAdmissionContactInfo | null>(null);
	// Acedemic Information
	const [academicInformation, setAcademicInformation] = useState<IAdmissionAcademicInfo | null>(null);
	// Banking Information
	const [bankingInformation, setBankingInformation] = useState<IAdmissionBankInfo | null>(null);
	// Personal Summary
	const [personalSummary, setPersonalSummary] = useState<IAdmissionPersonalSummaryInfo | null>(null);
	// GDPR information
	const [gdprInformation, setGdprInformation] = useState<IAdmissionGDPRInfo | null>(null);
	// Confirmation dialog helper
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	// Invalid form dialog helper
	const [openInvalidFormModal, setOpenInvalidFormModal] = useState(false);
	// Confirmation delete file
	const [openConfirmationDeleteFile, setOpenConfirmationDeleteFile] = useState({ isVisible: false, docType: null });
	// Files to Delete
	const [filesToDelete, setFilesToDelete] = useState<any | null>([]);

	const companyInfo = useSelector(state => state.company);
	const formFields = useSelector(state => state.formFields.formFieldsConfig);
	const dispatch = useDispatch();

	const {
		control,
		watch,
		formState: { errors },
		setError
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const prepareData = () => {
		if (!userAdmissionDetailsData) return;
		const persInfo = {
			employeeName: userAdmissionDetailsData.admission.employeeName,
			genderName: userAdmissionDetailsData.sheet.genderName,
			dateOfBirth: userAdmissionDetailsData.sheet.dateOfBirth,
			idDocumentTypeName: userAdmissionDetailsData.sheet.idDocumentTypeName,
			citizenCardNumber: userAdmissionDetailsData.sheet.citizenCardNumber,
			citizenCardValidityDate: userAdmissionDetailsData.sheet.citizenCardValidityDate,
			fiscalNumber: userAdmissionDetailsData.sheet.fiscalNumber,
			socialSecurityNumber: userAdmissionDetailsData.sheet.socialSecurityNumber,
			pensionFundNumber: userAdmissionDetailsData.sheet.pensionFundNumber,
			licensePlate: userAdmissionDetailsData.sheet.licensePlate,
			hasHadWithoutTermContract: userAdmissionDetailsData.sheet.hasHadWithoutTermContract,
			ageOnContractStartDate: userAdmissionDetailsData.sheet.ageOnContractStartDate
		};
		setPersonalInformation(persInfo);
		const contactInfo = {
			address: userAdmissionDetailsData.sheet.address,
			city: userAdmissionDetailsData.sheet.city,
			zipCode: userAdmissionDetailsData.sheet.zipCode,
			email: userAdmissionDetailsData.sheet.email,
			mobilePhone: userAdmissionDetailsData.sheet.mobilePhone,
			nationality: userAdmissionDetailsData.sheet.nationality,
			emergencyContact: userAdmissionDetailsData.sheet.emergencyContact,
			emergencyContactName: userAdmissionDetailsData.sheet.emergencyContactName,
			placeOfBirth: userAdmissionDetailsData.sheet.placeOfBirth,
			maritalStatus: userAdmissionDetailsData.sheet.maritalStatus,
			holdersNumber:
				userAdmissionDetailsData.sheet.holdersNumber === 1 || userAdmissionDetailsData.sheet.holdersNumber === 0
					? 'Single Holder'
					: 'Two Holders',
			dependentsNumber: userAdmissionDetailsData.sheet.dependentsNumber,
			taxZone: userAdmissionDetailsData.sheet.taxZone
		};
		setContactInformation(contactInfo);
		const academicInfo = {
			courseQualificationIdName: userAdmissionDetailsData.sheet.courseQualificationIdName,
			coursePostBologna: userAdmissionDetailsData.sheet.coursePostBologna,
			educationStatus: userAdmissionDetailsData.sheet.educationStatus,
			educationCodeName: userAdmissionDetailsData.sheet.educationCodeName,
			courseName: userAdmissionDetailsData.sheet.courseName,
			courseInstitution: userAdmissionDetailsData.sheet.courseInstitution,
			courseStartDate: userAdmissionDetailsData.sheet.courseStartDate,
			courseEndDate: userAdmissionDetailsData.sheet.courseEndDate
		};
		setAcademicInformation(academicInfo);
		const bankInfo = {
			bankName: userAdmissionDetailsData.sheet.bankIdName,
			bankNib: userAdmissionDetailsData.sheet.bankNib
		};
		setBankingInformation(bankInfo);
		const personalSummaryInfo = {
			careerStartDate: userAdmissionDetailsData.sheet.careerStartDate,
			professionalExperience: userAdmissionDetailsData.sheet.professionalExperience
		};
		setPersonalSummary(personalSummaryInfo);
		const gdprInfo = {
			biometricSystemsConsent: userAdmissionDetailsData.sheet.biometricSystemsConsent,
			intranetSystemsConsent: userAdmissionDetailsData.sheet.intranetSystemsConsent,
			promotionalMediaConsent: userAdmissionDetailsData.sheet.promotionalMediaConsent
		};
		setGdprInformation(gdprInfo);
	};
	useEffect(() => {
		const isEmployee =
			userAdmissionDetailsData?.admission.employeeCategory == XPAND_CONTRACTOR_ID ||
			userAdmissionDetailsData?.admission.employeeCategory == XPAND_CONTRACTOR_ID_DE ||
			userAdmissionDetailsData?.admission.employeeCategory == XPAND_CONTRACTOR_ID_HR ||
			userAdmissionDetailsData?.admission.employeeCategory == XPAND_CONTRACTOR_ID_SE ||
			userAdmissionDetailsData?.admission.employeeCategory == XPAND_CONTRACTOR_ID_UK;
		dispatch(
			getFormFieldsConfig(
				isEmployee ? 'Employee' : 'Contractor',
				userAdmissionDetailsData?.sheet?.company,
				'Admission Sheet'
			)
		);
	}, [userAdmissionDetailsData]);

	useEffect(() => {
		if (!error && !userAdmissionDetailsData && admissionId) {
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions
			isValidate ? getAdmissionDetailsToValidate(admissionId) : getSubmissionInfo(admissionId);
		}
		if (userAdmissionDetailsData) prepareData();

		return function cleanup() {
			if (userAdmissionDetailsData) {
				// clear previous data to ensure that the next admission detail shown is correct
				clearAdmissionDetails(isValidate);
			}
		};
	}, [userAdmissionDetailsData]);

	const getFileByDocType = (documentType: string) => {
		// Since there is many different type os ID documents, we must verify all of them
		const result =
			documentType === DOCTYPES.FID
				? userAdmissionDetailsData?.admission?.files?.find(
						doc =>
							doc.fileType === DOCTYPES.CC ||
							doc.fileType === DOCTYPES.FID ||
							doc.fileType === DOCTYPES.PP ||
							doc.fileType === DOCTYPES.RP
				  )
				: userAdmissionDetailsData?.admission?.files?.find(doc => doc.fileType === documentType);

		return result;
	};

	const showDocument = (documentType: string) => {
		const file = getFileByDocType(documentType);
		if (file) getAdmissionFile(admissionId, file.id);
	};

	// Method to add documents to the filesToDelete State to delete at the end
	const preDeleteDocument = (documentType: string) => {
		const file = getFileByDocType(documentType);
		if (file) {
			setFilesToDelete([...filesToDelete, file.id]);
			// Since there is many different type os ID documents, we must verify all of them
			const fileIndex: number | undefined =
				documentType === DOCTYPES.FID
					? userAdmissionDetailsData?.admission?.files?.findIndex(
							doc =>
								doc.fileType === DOCTYPES.CC ||
								doc.fileType === DOCTYPES.FID ||
								doc.fileType === DOCTYPES.PP ||
								doc.fileType === DOCTYPES.RP
					  )
					: userAdmissionDetailsData?.admission?.files?.findIndex(doc => doc.fileType === documentType);

			if (fileIndex !== undefined && fileIndex > -1) {
				userAdmissionDetailsData?.admission?.files?.splice(fileIndex, 1);
			}
		}
	};

	// Method to actually delete said documents
	const deleteDocuments = () => {
		filesToDelete.map((fileId: number) => deleteAdmissionFile(admissionId, fileId));
	};

	// PAGE FOOTER ACTIONS
	const footerActions = useMemo(
		() => [
			{
				id: 'invalid',
				label: 'Invalid Form',
				variant: 'text',
				onClick: () => setOpenInvalidFormModal(true),
				endIcon: 'email',
				disabled: getLSField('impersonate_userInfo')
			},
			{
				id: 'approve',
				label: 'Approve Admission',
				onClick: () => setOpenConfirmationModal(true),
				variant: 'contained',
				disabled: getLSField('impersonate_userInfo'),
				endIcon: 'submit'
			}
		],
		[]
	);

	const confirmActionsApproval = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setOpenConfirmationModal(false)
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: async () => {
				await approveAdmission(admissionId);
				goToPage('/admin/admissions/manageAdmissions');
				setOpenConfirmationModal(false);
			}
		}
	];

	const confirmActionsInvalidForm = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setOpenInvalidFormModal(false)
		},
		{
			id: 'send',
			label: 'Send',
			color: 'primary',
			variant: 'contained',
			onClick: async () => {
				if (watch('errorDetails') === '') {
					setError(
						'errorDetails',
						{
							type: 'manual',
							message: 'This field is mandatory'
						},
						{ shouldFocus: true }
					);
				} else {
					deleteDocuments();
					await invalidateAdmission(admissionId, { errorDetails: watch('errorDetails') });
					goToPage('/admin/admissions/manageAdmissions');
					setOpenInvalidFormModal(false);
				}
			}
		}
	];

	const confirmActionsDeleteFile = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setOpenConfirmationDeleteFile({ isVisible: false, docType: null })
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				if (openConfirmationDeleteFile && openConfirmationDeleteFile.docType) {
					setFilesToDelete([...filesToDelete, openConfirmationDeleteFile.docType]);
					preDeleteDocument(openConfirmationDeleteFile.docType);
					setOpenConfirmationDeleteFile({ isVisible: false, docType: null });
				}
			}
		}
	];

	if (error) return handleErrorPage(error, clearAdmissionsError);

	const isLoading = userAdmissionDetailsData === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<BackPage path="/admin/admissions/manageAdmissions" action={goToPage} />
			<div className={isTablet ? classes.rootTablet : classes.root}>
				<Grid container direction="column" justifyContent="space-evenly" alignItems="center" spacing={5}>
					{!!personalInformation && (
						<PersonalInfoCard
							classes={classes}
							payload={personalInformation}
							formFieldsConfig={formFields?.formFieldsConfig}
							hasFile={
								!!userAdmissionDetailsData.admission?.files?.find(
									doc =>
										doc.fileType === DOCTYPES.CC ||
										doc.fileType === DOCTYPES.FID ||
										doc.fileType === DOCTYPES.PP ||
										doc.fileType === DOCTYPES.RP
								)
							}
							showDoc={showDocument}
							deleteDoc={isValidate ? setOpenConfirmationDeleteFile : null}
						/>
					)}
				</Grid>
				<Grid container direction="column" justifyContent="space-evenly" alignItems="center" spacing={5}>
					{!!contactInformation && (
						<ContactInfoCard
							classes={classes}
							payload={contactInformation}
							formFieldsConfig={formFields?.formFieldsConfig}
						/>
					)}
				</Grid>
				<Grid container direction="column" justifyContent="space-evenly" alignItems="center" spacing={5}>
					{!!academicInformation && (
						<AcademicInfoCard
							classes={classes}
							payload={academicInformation}
							formFieldsConfig={formFields?.formFieldsConfig}
							showDoc={showDocument}
							hasFile={userAdmissionDetailsData.admission?.files?.find(
								doc => doc.fileType === DOCTYPES.QC
							)}
							deleteDoc={isValidate ? setOpenConfirmationDeleteFile : null}
						/>
					)}
				</Grid>
				<Grid container direction="column" justifyContent="space-evenly" alignItems="center" spacing={5}>
					{userAdmissionDetailsData && !!bankingInformation && (
						<BankingInfoCard
							classes={classes}
							payload={bankingInformation}
							formFieldsConfig={formFields?.formFieldsConfig}
							showDoc={showDocument}
							hasFile={userAdmissionDetailsData.admission?.files?.find(
								doc => doc.fileType === DOCTYPES.IBAN
							)}
							deleteDoc={isValidate ? setOpenConfirmationDeleteFile : null}
						/>
					)}
				</Grid>
				<Grid container direction="column" justifyContent="space-evenly" alignItems="center" spacing={5}>
					{!!personalSummary && (
						<PersonalSummaryCard
							classes={classes}
							payload={personalSummary}
							formFieldsConfig={formFields?.formFieldsConfig}
						/>
					)}
				</Grid>
				<Grid
					container
					style={{ marginBottom: '110px' }}
					direction="column"
					justifyContent="space-evenly"
					alignItems="center"
					spacing={5}>
					{!!gdprInformation && (
						<GDPRCard
							classes={classes}
							payload={gdprInformation}
							formFieldsConfig={formFields?.formFieldsConfig}
						/>
					)}
				</Grid>
				{isValidate && <ActionFooter actions={footerActions} />}
				{userAdmissionDetailsData && (
					<Dialog
						modal={{
							open: Boolean(openConfirmationModal),
							handleClose: (event: unknown, reason: string) => {
								if (reason !== 'backdropClick') setOpenConfirmationModal(false);
							},
							content: (
								<>
									<Typography gutterBottom>
										Are you sure you want to approve{' '}
										{userAdmissionDetailsData.admission.employeeName} admission?
									</Typography>
								</>
							)
						}}
						title="Approve Admission"
						actions={confirmActionsApproval}
						scroll="body"
					/>
				)}
				<Dialog
					modal={{
						open: Boolean(openInvalidFormModal),
						handleClose: (event: unknown, reason: string) => {
							if (reason !== 'backdropClick') setOpenInvalidFormModal(false);
						},
						content: (
							<>
								<Input
									label="What is wrong with the user's form admission?"
									placeholder="Specify what is invalid"
									name="errorDetails"
									multiline
									minRows={3}
									maxRows={5}
									control={control}
									errors={errors}
									required
								/>
							</>
						)
					}}
					title="INVALID ADMISSION FORM"
					actions={confirmActionsInvalidForm}
					scroll="body"
				/>
			</div>
			{userAdmissionDetailsData && (
				<Dialog
					modal={{
						open: Boolean(openConfirmationDeleteFile.isVisible),
						handleClose: (event: unknown, reason: string) => {
							if (reason !== 'backdropClick')
								setOpenConfirmationDeleteFile({ isVisible: false, docType: null });
						},
						content: (
							<>
								<Typography gutterBottom>Are you sure you want to delete this file?</Typography>
							</>
						)
					}}
					title="Delete File"
					actions={confirmActionsDeleteFile}
					scroll="body"
				/>
			)}
		</>
	);
};

export default withLayout(AdmissionDetails);
