//* EXTERNAL LIBS
import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { yupResolver } from '@hookform/resolvers/yup';
import { Control, useForm, UseFormWatch, FieldErrors } from 'react-hook-form';
import Prompt from '../../../../../components/Prompt/Prompt';

//* EXTERNAL LIBS --> MUI

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter, TabPanel, FormSteps } from 'xpand-ui/core';
import { isTouchedFieldsUsed } from 'lib/utils/formUtils';

//* TYPINGS
import { INewClient } from 'typings/store/admin/projectsAndClients';
import { HelperProps } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import EnvelopeContacts from './tabs/EnvelopeContacts';
import Contacts from './tabs/Contacts';
import Address from './tabs/Address';
import Client from './tabs/Client';
import { schema, defaultValues, tabs } from './yupSchema';
// import { useStyles } from './styles';
import { NewClientProps } from '.';
import { getLSField } from 'lib/utils/cookies';

const actions = [
	{ id: 'Create', label: 'Create' },
	{ id: 'Update', label: 'Update' },
	{ id: 'Disable', label: 'Disable' }
];

//* COMPONENT TABS INTERFACES
export interface INewClientTabs {
	// eslint-disable-next-line
	[x: string]: any;
	control: Control<INewClient>;
	errors: FieldErrors<INewClient>;
	watch: UseFormWatch<INewClient>;
	// eslint-disable-next-line
	companySelectOptions: any;
	actions: Array<{ id: string; label: string }>;
	setCompanyHelper: React.Dispatch<React.SetStateAction<HelperProps>>;
}

//* COMPONENT
const NewClient: FC<NewClientProps> = ({
	projectsAndClients,
	getNewClient,
	sendNewClient,
	clearProjectAndClientsError
}) => {
	// const classes = useStyles();
	const { newClient, loading, error } = projectsAndClients;
	const scrollToTopRef = useRef<HTMLDivElement | any>(null);
	const scrollbarRef = React.createRef();
	const [tabSelected, setTabSelected] = useState(0);
	const [submitClick, setSubmitClick] = useState(false);
	const [isSubmited, setIsSubmited] = useState(false);
	useEffect(() => {
		scrollToTopRef?.current?.scrollIntoView(0, 0);
	});

	useEffect(() => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollToTop();
		}
	}, [tabSelected]);

	const [formWarnings, setFormWarnings] = useState<Array<number>>([]);
	const [companyHelper, setCompanyHelper] = useState<HelperProps>({ company: null });

	const {
		handleSubmit,
		control,
		watch,
		reset,
		formState: { errors, touchedFields },
		getValues
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,

		shouldUnregister: false
	});

	useEffect(() => {
		if (!newClient && !error) {
			getNewClient();
		}
	}, [newClient]);

	const onSubmit = async () => {
		const payload = watch();
		const result = await sendNewClient(payload);
		if (result) {
			setIsSubmited(result as unknown as boolean);
		}
	};

	//Only if the client is sucessfully submitted it resets the data
	useEffect(() => {
		if (isSubmited) reset();
	}, [isSubmited]);

	const companySelectOptions = useMemo(() => {
		if (!newClient) {
			return {
				company: [],
				clientGroups: [],
				salePriceLists: [],
				salesRepresentatives: [],
				paymentTerms: []
			};
		}
		if (companyHelper.company) {
			const selectedCompany = newClient.companies.find(company => company.searchKey === companyHelper.company);
			if (selectedCompany && newClient) {
				return {
					company: newClient.companies.map(e => ({ id: e.searchKey, label: e.name })),
					clientGroups: newClient.clientGroups.map(group => ({ id: group.id, label: group.name })),
					salePriceLists: selectedCompany?.salePriceLists?.map(salePrice => ({
						id: salePrice.id,
						label: salePrice.name
					})),
					salesRepresentatives: selectedCompany.salesRepresentatives?.map(salesRepresentative => ({
						id: salesRepresentative.id,
						label: salesRepresentative.name
					})),
					paymentTerms: selectedCompany.paymentTerms?.map(paymentTerm => ({
						id: paymentTerm.id,
						label: paymentTerm.name
					}))
				};
			}
		}

		return {
			company: newClient.companies.map(e => ({ id: e.searchKey, label: e.name })),
			clientGroups: [],
			salePriceLists: [],
			salesRepresentatives: [],
			paymentTerms: []
		};
	}, [newClient, companyHelper.company]);

	// 	CONSTANTS
	const footerActions = [
		...(tabSelected !== 0
			? [
					{
						id: 'previous',
						label: 'Previous',
						// left: true,
						onClick: () => {
							setTabSelected(tabSelected - 1);
							scrollToTopRef.current.scrollIntoView({
								behavior: 'smooth',
								block: 'start'
							});
						},
						variant: 'outlined',
						disabled: false
					}
			  ]
			: []),
		...(tabSelected !== 3
			? [
					{
						id: 'next',
						label: 'Next',
						onClick: () => {
							setTabSelected(tabSelected + 1);
							/* Checking if the tabSelected is in the formWarnings array. If it is, it is removing it from
							the array. */
							if (formWarnings.includes(tabSelected)) {
								setFormWarnings(formWarnings.filter(e => e !== tabSelected));
							}
							scrollToTopRef.current.scrollIntoView({
								behavior: 'smooth',
								block: 'start'
							});
						},
						variant: 'contained',
						disabled: false
					}
			  ]
			: []),
		...(tabSelected === 3
			? [
					{
						id: 'submit',
						endIcon: 'submit',
						label: 'Submit',
						onClick: () => {
							setSubmitClick(true);
						},
						type: 'submit',
						form: 'form-new-client',
						variant: 'contained',
						disabled: getLSField('impersonate_userInfo')
					}
			  ]
			: [])
	];

	if (error) return handleErrorPage(error, clearProjectAndClientsError);

	const isLoading = newClient === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<form ref={scrollToTopRef} id="form-new-client" onSubmit={handleSubmit(onSubmit)}>
				<FormSteps steps={tabs} selected={tabSelected} errors={formWarnings} />
				<Scrollbars
					ref={scrollbarRef}
					style={{ height: 'calc(100vh - 270px)', overflowX: 'hidden' }}
					// eslint-disable-next-line
					renderTrackHorizontal={(props: any) => (
						<div {...props} style={{ display: 'none' }} className="track-horizontal" />
					)}>
					{tabs.map(tab => {
						const commonProps = {
							// react-hook-form helpers
							control,
							errors,
							reset,
							watch,
							// component fields
							actions,
							setCompanyHelper,
							companySelectOptions
						};

						return (
							<TabPanel key={`proposal_tab_container_${tab.id}`} value={tabSelected} index={tab.id}>
								{tab.id === 0 && <Client {...commonProps} />}
								{tab.id === 1 && <Address {...commonProps} />}
								{tab.id === 2 && <Contacts {...commonProps} />}
								{tab.id === 3 && (
									<EnvelopeContacts
										additionalErrors={errors}
										handleWarning={(names: unknown, tabsWithWarn: number[]) =>
											setFormWarnings(tabsWithWarn)
										}
										{...commonProps}
										setSubmitClick={setSubmitClick}
										submitClick={submitClick}
									/>
								)}
							</TabPanel>
						);
					})}
				</Scrollbars>
			</form>
			<ActionFooter actions={footerActions} />
			<Prompt
				when={isTouchedFieldsUsed(touchedFields, getValues)}
				message="Are you sure you want to leave without saving?"
			/>
		</>
	);
};

export default NewClient;
