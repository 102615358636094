/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import { Grid, Typography, IconButton, Button } from '@mui/material';
import { Edit, Done } from '@mui/icons-material';

import { sizes } from 'xpand-ui/utils/handlers';
import { getLSField } from 'lib/utils/cookies';

export type IClasses = Record<string, string>;

interface IInfoCards {
	classes: IClasses;
	title: string;
	reset?: any;
	form?: string;
	editMode?: boolean | null;
	setEditMode?: any;
	children?: any;
}
const InfoCards: FC<IInfoCards> = ({
	classes,
	reset = undefined,
	title,
	children,
	form = undefined,
	editMode = undefined,
	setEditMode = undefined
}) => (
	<>
		<Grid item {...sizes[12]}>
			<Typography variant="h2">
				{title}{' '}
				{!editMode && setEditMode && !getLSField('impersonate_userInfo') && (
					<IconButton className={classes.phoneLineActionDelete} onClick={() => setEditMode(true)}>
						<Edit color="yale" />
					</IconButton>
				)}
			</Typography>
		</Grid>
		<Grid item {...sizes[12]} style={{ ...(!editMode && { marginBottom: '75px' }) }}>
			{children}
		</Grid>
		{editMode && (
			<Grid item {...sizes[12]} style={{ marginBottom: '75px' }}>
				<Button
					variant="contained"
					color="primary"
					form={form || ''}
					type={(form && 'submit') || 'button'}
					endIcon={<Done />}
					style={{ float: 'right' }}>
					Save
				</Button>
				<Button
					variant="text"
					color="secondary"
					onClick={() => {
						reset && reset();
						setEditMode(false);
					}}
					style={{ float: 'right', marginRight: '20px' }}>
					Cancel
				</Button>
			</Grid>
		)}
	</>
);

export { InfoCards };
