//* EXTERNAL LIBS
import React, { FC, useEffect } from 'react';

//* EXTERNAL LIBS --> MUI
//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay } from 'xpand-ui/core';
import { ErrorPage } from 'xpand-ui/lab';

//* TYPINGS
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { ApproveCollaborationTypeProps } from '.';
import { useDispatch } from 'react-redux';
import { resetEmployeeActions } from 'store/administration/collaborationTypesManagement/actions';
import { useParams } from 'react-router-dom';
// import { useStyles  } from './styles';

//* COMPONENT INTERFACES
interface IApproveCollaborationType extends ApproveCollaborationTypeProps {
	match: Match<{
		token: string;
		proposalId: string | number;
		manager: string;
	}>;
	goToPage: (path: string) => void;
}

const notificationPayload = {
	area: 'Salaries & Contracts',
	section: 'Collaboration Types'
};

//* COMPONENT

const ApproveCollaborationType: FC<IApproveCollaborationType> = ({
	goToPage,
	match,
	collaborationTypesManagement,
	submitCollaborationTypeApprove,
	setNotificationAsReadProposals
}) => {
	// const classes = useStyles();
	const params = useParams();
	const { employeeCategoryActions } = collaborationTypesManagement;
	const token = params?.token;
	const proposalId = params?.proposalId;
	const manager = params?.manager;
	const dispatch = useDispatch();

	useEffect(() => {
		submitCollaborationTypeApprove(token, Number(proposalId), manager);
	}, []);

	//Resets employeeCategoryActions and redirects to employees page
	useEffect(() => {
		if (employeeCategoryActions && !employeeCategoryActions.code) {
			dispatch(resetEmployeeActions());
			goToPage('/admin/salariesAndContracts/collaborationTypes');
			setNotificationAsReadProposals(proposalId.toString(), notificationPayload);
		}
	}, [employeeCategoryActions]);

	if (employeeCategoryActions && employeeCategoryActions?.code && employeeCategoryActions?.code !== 200) {
		return (
			<ErrorPage
				code={employeeCategoryActions?.code}
				message={employeeCategoryActions?.payload.message}
				//exit={() => clearProposalsFormsData()}
			/>
		);
	}

	return <LoadingOverlay />;
};

export default withLayout(ApproveCollaborationType);
