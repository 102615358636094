/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC, ReactNode } from 'react';
import generator from 'generate-password-browser';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography, Button, Popover, TextField, Input as CoreInput, FormHelperText } from '@mui/material';
import { MailOutline, VpnKey, Refresh, Add, PersonOutlineOutlined } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
//* EXTERNAL LIBS --> XPAND-UI
import { Dialog, LoadingOverlay } from 'xpand-ui/core';
import { sizes } from 'xpand-ui/utils/handlers';
import { Select, InfoField, InputLabel } from 'xpand-ui/forms';
//* TYPINGS

//* LOCAL COMPONENT IMPORTS
import { passwordSchema, passwordDefaultValues } from './yupSchema';
import { useStyles } from './styles';
import { AdminActionsProps } from '.';
import HealthCareIcon from '../../../../../../assets/icons/health-book-line.svg';
import { addNotification } from 'lib/utils/notifications';
import { clearConvocationMessageConfig } from 'store/personalInfo/actions';
import { IUserProfile } from 'typings/store/personalInfoTypes';
import { getLSField, setLSField } from 'lib/utils/cookies';
import { Roles } from 'lib/roles/index';

export const ACTIONS = {
	CHANGE_PASSWORD: 'changePassword',
	SEND_EMAIL: 'sendEmail',
	DELETE_USER: 'deleteUser',
	REFRESH_USER_DATA: 'refreshUserData',
	SEND_HEALTH_EMAIL: 'sendHealthEmail',
	IMPERSONATE_ACTION: 'impersonateAction'
};

const initPasswordStrong = {
	length: false,
	upperCase: false,
	lowerCase: false,
	numberAndSpecial: false
};

const notificationPayload = {
	area: 'My Profile',
	section: 'Personal & Fiscal Information'
};
interface IAdminActions extends AdminActionsProps {
	isAdmin: string;
	goToPage: (path: string) => void;
	handleApiSubmit: any;
	convocationsMessage: string | null;
	messageHasChanged: boolean;
	userProfile: IUserProfile;
}

interface IConfirmModal {
	type: string;
	title: string;
	message?: string | ReactNode;
	payload?: any;
}

interface IUserPermissions {
	id: number;
	displayName: string;
}

const AdminActions: FC<IAdminActions> = ({
	users,
	goToPage,
	isAdmin,
	clearCache,
	userProfile,
	sendPostImpersonateEmail
}) => {
	const classes = useStyles();

	const { loading } = users;
	// const [deleteUser, setDeleteUser] = useState<boolean | null>(null);
	const [confirmModal, setConfirmModal] = useState<IConfirmModal | null>(null);
	const [showPassword, setShowPassword] = useState({
		oldPassword: false,
		newPassword: false,
		confirmNewPassword: false
	});
	const [passwordStrong, setPasswordStrong] = useState(initPasswordStrong);
	// form
	const {
		handleSubmit: handleSubmitPassword,
		control: controlPassword,
		reset: resetPassword,
		setValue: setValuePassword,
		formState: { errors: errorsPassword }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(passwordSchema),
		reValidateMode: 'onChange',
		defaultValues: passwordDefaultValues,
		shouldUnregister: false
	});

	useEffect(() => {
		if (isAdmin) {
			resetPassword({ isAdmin });
		}
	}, []);

	const hasPermission = (permission: string) => {
		const userPermissionsStorage = getLSField('userPermissions');
		const userPermissions: IUserPermissions[] =
			(userPermissionsStorage && JSON.parse(userPermissionsStorage)) || null;
		let result: boolean = false;
		if (userPermissions != null) {
			userPermissions.forEach(userPermission => {
				if (userPermission.displayName === permission) {
					result = true;
				}
			});
		}
		return result;
	};

	const confirmAction = (action: IConfirmModal | null) => {
		if (action?.type === 'SEND_EMAIL') {
			goToPage(`/admin/employees/sendWelcomeEmail/${isAdmin}`);
			window.location.reload();
		}
		if (action?.type === 'REFRESH_USER_DATA') {
			clearCache(isAdmin);
		}
	};
	const [passAnchorEl, setPassAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
	const [emailAnchorEl, setEmailAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
	const [refreshUserDataAnchorEl, setRefreshUserDataAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(
		null
	);
	const [openImpersonateAnchorEl, setOpenImpersonateAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(
		null
	);
	const handlePopoverOpen = (action: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		switch (action) {
			case ACTIONS.CHANGE_PASSWORD:
				setPassAnchorEl(event.currentTarget);
				break;
			case ACTIONS.SEND_EMAIL:
				setEmailAnchorEl(event.currentTarget);
				break;
			// case ACTIONS.DELETE_USER:
			// 	setDeleteAnchorEl(event.currentTarget);
			// 	break;
			case ACTIONS.REFRESH_USER_DATA:
				setRefreshUserDataAnchorEl(event.currentTarget);
				break;
			case ACTIONS.IMPERSONATE_ACTION:
				setOpenImpersonateAnchorEl(event.currentTarget);
				break;
			default:
		}
	};

	const handlePopoverClose = (action: string) => {
		switch (action) {
			case ACTIONS.CHANGE_PASSWORD:
				setPassAnchorEl(null);
				break;
			case ACTIONS.SEND_EMAIL:
				setEmailAnchorEl(null);
				break;
			case ACTIONS.REFRESH_USER_DATA:
				setRefreshUserDataAnchorEl(null);
				break;
			case ACTIONS.IMPERSONATE_ACTION:
				setOpenImpersonateAnchorEl(null);
				break;
			default:
		}
	};

	const handlePasswordStrong = (val: string) =>
		setPasswordStrong({
			length: val.length > 7 && val.length < 17,
			upperCase: val.match(/[A-Z]/g) !== null,
			lowerCase: val.match(/[a-z]/g) !== null,
			numberAndSpecial: (val.match(/[0-9]/g) !== null &&
				val.match(/[ !@#$%&*()_+\-=[\]{};':"\\|,.<>/?]/)) as boolean
		});

	const genPassword = () => {
		const pwd = generator.generate({
			length: 14,
			lowercase: true,
			uppercase: true,
			numbers: true,
			symbols: true,
			strict: true,
			exclude: '«»çÇ€£§´`^~'
		});
		handlePasswordStrong(pwd);
		setShowPassword(prev => ({
			...prev,
			newPassword: true,
			confirmNewPassword: true
		}));
		setValuePassword('newPassword', pwd, { shouldValidate: true });
		setValuePassword('confirmNewPassword', pwd, { shouldValidate: true });
	};

	// To avoid scrollbar issues, the popover must be closed before the modal appears
	const closePopovers = () => {
		setPassAnchorEl(null);
		setEmailAnchorEl(null);
		setRefreshUserDataAnchorEl(null);
		setOpenImpersonateAnchorEl(null);
	};

	//Adds ordinal suffix to number of the moment
	const ordinalSuffix = (i: number) => {
		let j = i % 10,
			k = i % 100;
		if (j == 1 && k != 11) {
			return i + 'st';
		}
		if (j == 2 && k != 12) {
			return i + 'nd';
		}
		if (j == 3 && k != 13) {
			return i + 'rd';
		}
		return i + 'th';
	};

	return (
		<>
			{loading && <LoadingOverlay />}
			<Grid item {...sizes[12]} className={classes.leftCardSection}>
				{!isAdmin && !getLSField('impersonate_userInfo') && (
					<Button
						size="large"
						color="yale"
						variant="text"
						startIcon={<VpnKey />}
						onMouseEnter={event => handlePopoverOpen(ACTIONS.CHANGE_PASSWORD, event)}
						onMouseLeave={() => handlePopoverClose(ACTIONS.CHANGE_PASSWORD)}
						onClick={() => {
							closePopovers();
							window.open(
								'https://account.activedirectory.windowsazure.com/ChangePassword.aspx',
								'_blank',
								'noopener,noreferrer'
							);
						}}>
						<Typography variant="body1" className={classes.profileActionLabel}>
							Change Password
						</Typography>
					</Button>
				)}
				{isAdmin && !getLSField('impersonate_userInfo') && (
					<>
						{(hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN) ||
							hasPermission(Roles.CP_ROLE_TALENT_ENGAGEMENT) ||
							hasPermission(Roles.CP_ROLE_PAYROLL_AND_BENEFITS)) && (
							<Button
								size="large"
								color="yale"
								variant="text"
								startIcon={<MailOutline />}
								onMouseEnter={event => handlePopoverOpen(ACTIONS.SEND_EMAIL, event)}
								onMouseLeave={() => handlePopoverClose(ACTIONS.SEND_EMAIL)}
								onClick={() => {
									closePopovers();
									setConfirmModal({
										type: 'SEND_EMAIL',
										title: 'Send Welcome Email',
										message: userProfile.resetPassword ? (
											<>
												Resending the Welcome Email will <b>RESET</b> the user password, are you
												sure?
											</>
										) : (
											<>
												Password will not be reset when sending the Welcome Email. Feel free to
												test it.
											</>
										)
									});
								}}
							/>
						)}
						<Popover
							id="emailPopover"
							className={classes.popover}
							classes={{
								paper: classes.paper
							}}
							open={Boolean(emailAnchorEl)}
							anchorEl={emailAnchorEl}
							onClose={() => {
								handlePopoverClose(ACTIONS.SEND_EMAIL);
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center'
							}}
							disableRestoreFocus>
							<Typography variant="body1" className={classes.popoverLabel}>
								Send Welcome Email
							</Typography>
						</Popover>
						{(hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN) ||
							hasPermission(Roles.CP_ROLE_TALENT_ENGAGEMENT) ||
							hasPermission(Roles.CP_ROLE_PAYROLL_AND_BENEFITS)) && (
							<Button
								size="large"
								color="yale"
								variant="text"
								startIcon={<Refresh />}
								onMouseEnter={event => handlePopoverOpen(ACTIONS.REFRESH_USER_DATA, event)}
								onMouseLeave={() => handlePopoverClose(ACTIONS.REFRESH_USER_DATA)}
								onClick={() => {
									closePopovers();
									setConfirmModal({
										type: 'REFRESH_USER_DATA',
										title: 'Refresh User Data',
										message: <>Are you sure you want to refresh this user's data?</>
									});
								}}
							/>
						)}
						<Popover
							id="refreshUserData"
							className={classes.popover}
							classes={{
								paper: classes.paper
							}}
							open={Boolean(refreshUserDataAnchorEl)}
							anchorEl={refreshUserDataAnchorEl}
							onClose={() => handlePopoverClose(ACTIONS.REFRESH_USER_DATA)}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center'
							}}
							disableRestoreFocus>
							<Typography variant="body1" className={classes.popoverLabel}>
								Refresh User Data
							</Typography>
						</Popover>

						{(hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN) || hasPermission(Roles.CP_ROLE_IT_OPERATIONS)) && (
							<Button
								size="large"
								color="yale"
								variant="text"
								startIcon={<PersonOutlineOutlined />}
								onMouseEnter={event => handlePopoverOpen(ACTIONS.IMPERSONATE_ACTION, event)}
								onMouseLeave={() => handlePopoverClose(ACTIONS.IMPERSONATE_ACTION)}
								onClick={() => {
									const inTwoHours = new Date().getTime() + 120 * 60 * 1000;
									const userInfoStorage = getLSField('userInfo');
									let userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;

									setLSField(
										'impersonate_userInfo',
										JSON.stringify({
											name: userProfile.user.ldapData.displayName,
											email: userProfile.user.ldapData.email,
											username: userProfile.user.ldapData.username,
											company: userInfo.company,
											expires: inTwoHours
										})
									);
									setLSField(
										'impersonate_userPermissions',
										JSON.stringify(userProfile.user.ldapData.groups)
									);
									setLSField(
										'impersonate_userActions',
										JSON.stringify(userProfile.user.ldapData.groups)
									);
									sendPostImpersonateEmail('started', userProfile.user.ldapData.username);
									goToPage('/apps');
									addNotification('success', 'Impersonate was successfully Started!', 5);
								}}
							/>
						)}

						<Popover
							id="impersonateAction"
							className={classes.popover}
							classes={{
								paper: classes.paper
							}}
							open={Boolean(openImpersonateAnchorEl)}
							anchorEl={openImpersonateAnchorEl}
							onClose={() => handlePopoverClose(ACTIONS.IMPERSONATE_ACTION)}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center'
							}}
							disableRestoreFocus>
							<Typography variant="body1" className={classes.popoverLabel}>
								See the Control Panel as this user sees it
							</Typography>
						</Popover>
					</>
				)}

				<Dialog
					maxWidth="xs"
					modal={{
						open: Boolean(confirmModal),
						handleClose: (event: unknown, reason: string) => {
							if (reason !== 'backdropClick') setConfirmModal(null);
						},
						content: <Typography gutterBottom>{confirmModal?.message}</Typography>
					}}
					title={confirmModal?.title}
					actions={[
						{
							id: 'cancel',
							label: 'Cancel',
							color: 'secondary',
							variant: 'text',
							onClick: () => setConfirmModal(null)
						},
						{
							id: 'confirmDeleteAllCaches',
							label: 'Confirm',
							color: 'primary',
							variant: 'contained',
							onClick: () => {
								setConfirmModal(null);
								if (confirmModal?.type !== 'REFRESH_USER_DATA') goToPage('/admin/employees/list');
								confirmAction(confirmModal);
							}
						}
					]}
					scroll="body"
				/>
			</Grid>
		</>
	);
};

export default AdminActions;
