import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { TitleContext } from 'components/App/TitleProvider';
import { getLSField } from 'lib/utils/cookies';

import { ErrorPage } from 'xpand-ui/lab';
import { OriginalResponse } from 'lib/handlers/requestTypes';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleErrorPage = (error: OriginalResponse, exit: () => void) => {
	if (error.response) {
		return <ErrorPage code={error.response.status} message={error.response.statusText} exit={exit} />;
	}

	return <ErrorPage code={error.code} message={error?.payload?.message || ''} exit={exit} />;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const withLayout = (Component: React.FC<any>) => (props: any) => {
	const { title, setTitle } = useContext(TitleContext) || { title: '', setTitle: () => ({}) };
	/** Site History */
	const navigate = useNavigate();
	const goToPage = (path: string) => (navigate ? navigate(path) : null);

	const userInfoStorage = getLSField('impersonate_userInfo')
		? getLSField('impersonate_userInfo')
		: getLSField('userInfo');

	const loggedUser = (userInfoStorage && JSON.parse(userInfoStorage)) || null;

	if (loggedUser === null) {
		window.location.reload();
	}
	return <Component {...props} goToPage={goToPage} loggedUser={loggedUser} title={title} setTitle={setTitle} />;
};

export default withLayout;
