//* EXTERNAL LIBS
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter, Dialog } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { Select, DatePicker } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { IResignFormPayload } from 'typings/store/admin/admissions';
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { NewAdmissionProps } from '.';
import { getLSField } from 'lib/utils/cookies';
import { useParams } from 'react-router-dom';

interface INewAdmission extends NewAdmissionProps {
	match: Match<{
		userId: string;
	}>;
	goToPage: (path: string) => void;
}

const Resign: FC<INewAdmission> = ({
	goToPage,
	match,
	admissions,
	getResignationForm,
	submitResignationForm,
	resetResginAction
}) => {
	const classes = useStyles();
	const params = useParams();
	const userId = Number(params?.userId) || null;
	const [confirmModal, setConfirmModal] = useState<boolean>(false);

	const { loading, resignationForm, resignationAction } = admissions;

	const {
		handleSubmit,
		control,
		watch,
		reset,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(
		() => () => {
			resetResginAction();
		},
		[]
	);

	useEffect(() => {
		if (!resignationForm && userId && !resignationAction) {
			getResignationForm(userId);
		}
	}, [resignationForm]);

	useEffect(() => {
		if (resignationAction && resignationAction.code === 200) {
			resetResginAction();
			goToPage('/admin/admissions/resignations');
		}
	}, [resignationAction]);

	const onSubmit = (data: IResignFormPayload) => {
		if (userId) {
			const payload = data;
			submitResignationForm(userId, payload);
			reset(defaultValues);
		}
	};

	const confirmModalActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setConfirmModal(false)
		},
		{
			id: 'submit',
			label: 'Submit',
			color: 'primary',
			variant: 'contained',
			onClick: () => setConfirmModal(false),
			type: 'submit',
			form: 'form-resign-user'
		}
	];
	const footerActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => goToPage('/admin/admissions/resignations')
		},
		{
			id: 'submit',
			endIcon: 'submit',
			label: 'Submit',
			onClick: () => setConfirmModal(true),
			variant: 'contained',
			disabled: getLSField('impersonate_userInfo')
		}
	];

	const isLoading = resignationForm === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<Dialog
				title="Confirm resignation"
				scroll="body"
				modal={{
					open: confirmModal,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmModal(false);
					},
					content: (
						<div>
							<p>Are you sure you want to resign this employee?</p>
							<p>The resignation will trigger the following actions:</p>
							<ul>
								<li>
									Inserts end date in Collaboration Period, Contract, Career, Cost Salary Category and
									Assets tabs at ERP and in profile user termination date at Xtracker
								</li>
								<li>
									Deletes Timesheets and Time-Off records dated after the resignation date and updates
									Time-Off credits accordingly
								</li>
								<li>Export deleted Timesheets and Time-Off records to Payroll@xpand-it.com</li>
							</ul>
						</div>
					)
				}}
				actions={confirmModalActions}
			/>
			<div className={classes.root}>
				<div className={classes.userInfo}>
					<Typography className={classes.name}>{resignationForm.user.name || 'N/A'}</Typography>
					<Typography className={classes.contract}>
						{JSON.parse(resignationForm.user.summaryData).contractType || 'N/A'}
					</Typography>
					<Typography className={classes.dateLabel}>
						<span className={classes.date}>CONTRACT START DATE: </span>
						{(JSON.parse(resignationForm.user.summaryData).contractStartDate &&
							parseDateToShow(JSON.parse(resignationForm.user.summaryData).contractStartDate)) ||
							' N/A'}
					</Typography>
				</div>
				<form id="form-resign-user" onSubmit={handleSubmit(() => onSubmit(watch()))}>
					<Grid container spacing={4}>
						<Grid item {...sizes[12]}>
							<DatePicker
								required
								name="contractEndDate"
								label="Contract end date"
								helperText="The date when this employee will leave the company"
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[12]}>
							<Select
								name="endReason"
								label="End Reason"
								options={resignationForm.endReasons.map(e => ({ id: e.id, label: e.name }))}
								control={control}
								errors={errors}
								required
							/>
						</Grid>
						<Grid item {...sizes[12]}>
							<Select
								name="contractEndReason"
								label="Contract End Reason"
								options={resignationForm.contractEndReasons.map(e => ({ id: e.id, label: e.name }))}
								control={control}
								errors={errors}
								required
							/>
						</Grid>
					</Grid>
				</form>
			</div>
			<ActionFooter actions={footerActions} />
		</>
	);
};

export default withLayout(Resign);
