import React, { FC, useEffect, useState } from 'react';
import { Route, Routes as Routers } from 'react-router-dom';

/* SYSTEM */
import Login from 'pages/General/Login';

import { NotFound, UnauthorizedPage } from 'xpand-ui/lab';

/* GENERAL */
import AppsPage from 'pages/General/AppsPage';

/* PERSONAL INFORMATION */
import Booking from 'pages/PersonalInfo/Bookings';
import MyProfile from 'pages/PersonalInfo/MyProfile/Profile';
import OrgChart from 'pages/PersonalInfo/MyCompany/OrgChart';
import AllEmployees from 'pages/PersonalInfo/MyCompany/AllEmployees';
import MyTeam from 'pages/PersonalInfo/MyCompany/MyTeam';
import MyResume from 'pages/PersonalInfo/MyProfile/Resume';
import MyTraining from 'pages/PersonalInfo/MyProfile/Training';
import MyCareer from 'pages/PersonalInfo/MyProfile/Career';
import MyBenefits from 'pages/PersonalInfo/MyFinancial/Benefits';
import MySkills from 'pages/PersonalInfo/MyProfile/Skills';
import MyFinancialDocuments from 'pages/PersonalInfo/MyFinancial/Documents';

import MyTimesheets from 'pages/PersonalInfo/MyTimeTracking/MyTimesheets';
import MyExpenses from 'pages/PersonalInfo/MyFinancial/Expenses';
import MyExpensesNew from 'pages/PersonalInfo/MyFinancial/Expenses/ExpensesNew';

import MyTimeoff from 'pages/PersonalInfo/MyTimeTracking/MyTimeoff';
/* ADMINISTRATION */
// Users
import ListEmployees from 'pages/Admin/EmployeesManagement/ListEmployees';
import VPNAccess from 'pages/Admin/SystemsManagement/VPNAccess';
import NewEmployee from 'pages/Admin/EmployeesManagement/NewEmployee';
import SendWelcomeEmail from 'pages/PersonalInfo/MyProfile/Profile/SendWelcomeEmail';

// Projects and Clients
import NewClient from 'pages/Admin/EntitiesManagement/Clients/NewClient';
import NewProject from 'pages/Admin/EntitiesManagement/Projects/NewProject';

// Admissions
import NewAdmission from 'pages/Admin/AdmissionsAndResignations/NewAdmission';
import AdmissionManagement from 'pages/Admin/AdmissionsAndResignations/AdmissionManagement';
import AdmissionDetails from 'pages/Admin/AdmissionsAndResignations/AdmissionManagement/AdmissionDetails';
import CreateEmployee from 'pages/Admin/AdmissionsAndResignations/CreateEmployee';

// Resign
import ResignationMngt from 'pages/Admin/AdmissionsAndResignations/ResignationMngt';
import Resign from 'pages/Admin/AdmissionsAndResignations/ResignationMngt/Resign';
import Deactivate from 'pages/Admin/AdmissionsAndResignations/ResignationMngt/Deactivate';

// --- Proposals
import ProposalsList from 'pages/Admin/Recruitment/ProposalsList';
import NewProposal from 'pages/Admin/Recruitment/NewProposal';
import UserProposalsList from 'pages/Admin/Recruitment/UserProposalsList';
import SendProposalEmail from 'pages/Admin/Recruitment/UserProposalsList/SendProposalEmail';
import ApproveProposal from 'pages/Admin/Recruitment/ApproveProposal';
import RejectProposal from 'pages/Admin/Recruitment/RejectProposal';

// --- Employee Salary Categories
import EmployeesList from 'pages/Admin/SalariesAndContracts/EmployeesList';
import EmployeeSalaryCategoriesList from 'pages/Admin/SalariesAndContracts/EmployeeSalaryCategoriesList';
import NewEmployeeSalary from 'pages/Admin/SalariesAndContracts/NewEmployeeSalary';
import ApproveCostSalaryCategory from 'pages/Admin/SalariesAndContracts/ApproveCostSalaryCategory';
import RejectCostSalaryCategory from 'pages/Admin/SalariesAndContracts/RejectCostSalaryCategory';
import CscAuditLog from 'pages/Admin/SalariesAndContracts/EmployeeSalaryCategoriesList/CscAuditLog';
import ContractManagement from 'pages/Admin/SalariesAndContracts/ContractManagement';
import EmployeeCategoriesManagment from 'pages/Admin/SalariesAndContracts/CollaborationTypesManagement';
// Skills and Resumes
import ManageSkills from 'pages/Admin/SkillsAndResumes/ManageSkills';
import SkillsEvaluation from 'pages/Admin/SkillsAndResumes/SkillsEvaluation';
import SearchResumes from 'pages/Admin/SkillsAndResumes/SearchResumes';
import SkillsFeedback from 'pages/Admin/SkillsAndResumes/SkillsEvaluation/SkillsFeedback';

//Approvals
import Timesheet from 'pages/Admin/Approvals/Timesheet/Timesheet';
import Expense from 'pages/Admin/Approvals/Expense/Expense';
import TimeOff from 'pages/Admin/Approvals/TimeOff/TimeOff';

// Administration

import AccessControl from 'pages/Admin/SystemsManagement/AccessControl';
import AccessControlAddUser from 'pages/Admin/SystemsManagement/AccessControl/AccessControlAddUser';
import EmailTemplates from 'pages/Admin/Administration/EmailTemplates';
import FileTemplates from 'pages/Admin/Administration/FileTemplates';
// import FileTemplates from 'pages/Admin/Administration/FileTemplates';
import CacheManagement from 'pages/Admin/Administration/CacheManagement';
import RenewAuthorizationCode from 'pages/Admin/Administration/RenewAuthorizationCode';
import ReleaseNotes from 'pages/Admin/Administration/ReleaseNotes';
import SendReleaseNotesEmail from 'pages/Admin/Administration/ReleaseNotes/SendReleaseNotesEmail';
// Budget
import Budget from 'pages/Admin/FinancialManagement/Budget';
import EditBudget from 'pages/Admin/FinancialManagement/Budget/EditBudget';

// Planner
import Planner from 'pages/Admin/Planner';
import PlannerMobiscroll from 'pages/Admin/PlannerMobiscroll';

// Configuration

import { NewRoutes, pages } from 'lib/routes/pages';
import SkillsOutlined from 'assets/images/pageTitles/SkillsOutlined.svg';
import SkillsIcon from 'assets/images/sidebar/SkillsIcon.svg';

import AuthRoute from './AuthRoute';
import { useDispatch, useSelector } from 'react-redux';
import { getSystemSkills } from 'store/personalInfo/thunks';
import EditCollaborationType from 'pages/Admin/SalariesAndContracts/CollaborationTypesManagement/EditCollaborationType';
import CollaborationTypeList from 'pages/Admin/SalariesAndContracts/CollaborationTypesManagement/CollaborationTypeList';
import RejectCollaborationType from 'pages/Admin/SalariesAndContracts/CollaborationTypesManagement/RejectCollaborationType';
import ApproveCollaborationType from 'pages/Admin/SalariesAndContracts/CollaborationTypesManagement/ApproveCollaborationType';
import JobExecutions from 'pages/Admin/Administration/JobExecutions';
import Roles from 'pages/Admin/Administration/Roles';
import AppLinks from 'pages/Admin/Configurations/AppLinks/AppLinks';
import Users from 'pages/Admin/Administration/Users';
import Profile from 'pages/PersonalInfo/MyProfile/Profile/Profile';
import UserGuides from 'pages/Admin/Administration/UserGuides';

const projectName = 'Control Panel -';
const projectHomePage = '/apps';

const Routes: React.FunctionComponent = () => {
	return (
		<>
			<NewRoutes></NewRoutes>
			<Routers>
				{/* -------------- SYSTEM -------------- */}
				<Route path="/" element={<AuthRoute path="/" redirect={projectHomePage} />} />
				<Route
					path="/login"
					element={<AuthRoute title={`${projectName} Login`} path="/login" component={Login} />}
				/>

				{/* -------------- GENERAL -------------- */}
				{/* ------ COMPANY APPS				*/}
				<Route
					path={pages.apps.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.apps.label}`}
							path={pages.apps.path}
							component={AppsPage}
						/>
					}
				/>

				{/* -------------- PERSONAL INFORMATION -------------- */}
				{/* ------ DESK BOOKING			*/}
				<Route
					path={pages.bookings.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.bookings.label}`}
							path={pages.apps.path}
							component={Booking}
						/>
					}
				/>
				{/* ------ PERSONAL & FISCAL INFORMATION			*/}
				<Route
					path={pages.personalFiscal.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.personalFiscal.label}`}
							path={pages.personalFiscal.path}
							component={Profile}
						/>
					}
				/>
				{/* ------ CONSENT INFORMATION		*/}

				<Route
					path={pages.consentInformation.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.consentInformation.label}`}
							path={pages.consentInformation.path}
							component={Profile}
						/>
					}
				/>
				{/* ------ ORG CHART			*/}
				<Route
					path={pages.myCompanyOrgChart.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.myCompanyOrgChart.label}`}
							path={pages.myCompanyOrgChart.path}
							component={OrgChart}
						/>
					}
				/>

				{/* ------ ALL EMPLOYEES			*/}
				<Route
					path={pages.myCompanyAllEmployees.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.myCompanyAllEmployees.label}`}
							path={pages.myCompanyAllEmployees.path}
							component={AllEmployees}
						/>
					}
				/>

				{/* ------ MY TEAM			*/}
				<Route
					path={pages.myCompanyMyTeam.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.myCompanyMyTeam.label}`}
							path={pages.myCompanyMyTeam.path}
							component={MyTeam}
						/>
					}
				/>
				{/* ------ MY SKILLS			*/}
				<Route
					path={`${pages.skills.subPath}`}
					element={
						<AuthRoute
							title={`${projectName} ${pages.skills.label}`}
							path={`${pages.skills.subPath}`}
							component={MySkills}
						/>
					}
				/>

				{/* ------ MY RESUME			*/}
				<Route
					path={pages.resume.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.resume.label}`}
							path={pages.resume.path}
							component={MyResume}
						/>
					}
				/>

				{/* ------ MY TRAINING			*/}
				<Route
					path={pages.trainings.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.trainings.label}`}
							path={pages.trainings.path}
							component={MyTraining}
						/>
					}
				/>

				{/* ------ MY BENEFITS			*/}
				<Route
					path={pages.benefits.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.benefits.label}`}
							path={pages.benefits.path}
							component={MyBenefits}
						/>
					}
				/>

				{/* ------ MY CAREER			*/}
				<Route
					path={pages.career.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.career.label}`}
							path={pages.career.path}
							component={MyCareer}
						/>
					}
				/>
				{/* ------ MY FINANCIAL DOCUMENTS			*/}
				<Route
					path={pages.financialDocuments.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.financialDocuments.label}`}
							path={pages.financialDocuments.path}
							component={MyFinancialDocuments}
						/>
					}
				/>

				{/* -------------- XTRACKER -------------- */}
				{/* ------ MyTimesheets			*/}
				<Route
					path={pages.xtrackerTimesheets.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.xtrackerTimesheets.label}`}
							path={pages.xtrackerTimesheets.path}
							roles={pages.xtrackerTimesheets.roles}
							component={MyTimesheets}
						/>
					}
				/>

				{/* ------ MyTimeoff			*/}
				<Route
					path={pages.xtrackerTimeoffs.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.xtrackerTimeoffs.label}`}
							path={pages.xtrackerTimeoffs.path}
							roles={pages.xtrackerTimeoffs.roles}
							component={MyTimeoff}
						/>
					}
				/>

				{/* ------ MyExpenses			*/}
				<Route
					path={pages.xtrackerMyExpenses.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.xtrackerMyExpenses.label}`}
							path={pages.xtrackerMyExpenses.path}
							roles={pages.xtrackerMyExpenses.roles}
							component={MyExpenses}
						/>
					}
				/>

				{/* ------ MyExpenses			*/}
				<Route
					path={pages.xtrackerMyExpensesNew.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.xtrackerMyExpensesNew.label}`}
							path={pages.xtrackerMyExpensesNew.path}
							roles={pages.xtrackerMyExpensesNew.roles}
							component={MyExpensesNew}
						/>
					}
				/>

				{/* -------------- ADMINISTRATION -------------- */}

				{/* -------------- USERS   			*/}
				{/* ------ NEW USER        			*/}
				<Route
					path={pages.newEmployee.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.newEmployee.label}`}
							path={pages.newEmployee.path}
							component={NewEmployee}
							roles={pages.newEmployee.roles}
						/>
					}
				/>

				{/* ------ LIST USERS      			*/}
				<Route
					path={pages.listEmployees.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.listEmployees.label}`}
							path={pages.listEmployees.path}
							component={ListEmployees}
							roles={pages.listEmployees.roles}
						/>
					}
				/>

				{/* ------ PROFILE ADMIN PAGE      	*/}
				<Route
					path={pages.adminProfile.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.adminProfile.label}`}
							path={pages.adminProfile.path}
							component={MyProfile}
							roles={pages.adminProfile.roles}
						/>
					}
				/>

				{/* ------ PROFILE ADMIN PAGE - SEND WELCOME EMAIL      */}
				<Route
					path={pages.sendWelcomeEmail.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.sendWelcomeEmail.label}`}
							path={pages.sendWelcomeEmail.path}
							component={SendWelcomeEmail}
							roles={pages.sendWelcomeEmail.roles}
						/>
					}
				/>

				{/* ------ VPN ACCESS      			*/}
				<Route
					path={pages.vpnAccess.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.vpnAccess.label}`}
							path={pages.vpnAccess.path}
							component={VPNAccess}
							roles={pages.vpnAccess.roles}
						/>
					}
				/>

				{/* -------------- PROJECT AND CLIENTS  */}
				{/* ------ NEW Client        			*/}
				<Route
					path={pages.newClient.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.newClient.label}`}
							path={pages.newClient.path}
							component={NewClient}
							roles={pages.newClient.roles}
						/>
					}
				/>

				{/* ------ NEW Project        			*/}
				<Route
					path={pages.newProject.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.newProject.label}`}
							path={pages.newProject.path}
							component={NewProject}
							roles={pages.newProject.roles}
						/>
					}
				/>

				{/* -------------- ADMISSIONS           */}
				{/* ------  NEW ADMISSION               */}
				<Route
					path={pages.newAdmission.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.newAdmission.label}`}
							path={pages.newAdmission.path}
							component={NewAdmission}
							roles={pages.newAdmission.roles}
						/>
					}
				/>

				{/* ------  ADMISSION MANAGEMENT              */}
				<Route
					path={pages.admissionsManagement.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.admissionsManagement.label}`}
							path={pages.admissionsManagement.path}
							component={AdmissionManagement}
							roles={pages.admissionsManagement.roles}
						/>
					}
				/>

				{/* ------  RESIGNATION MANAGEMENT              */}
				<Route
					path={pages.resignationManagement.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.resignationManagement.label}`}
							path={pages.resignationManagement.path}
							component={ResignationMngt}
							roles={pages.resignationManagement.roles}
						/>
					}
				/>
				<Route
					path={pages.resignUser.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.resignUser.label}`}
							path={pages.resignUser.path}
							component={Resign}
							roles={pages.resignUser.roles}
						/>
					}
				/>
				<Route
					path={pages.deactivateUser.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.deactivateUser.label}`}
							path={pages.deactivateUser.path}
							component={Deactivate}
							roles={pages.deactivateUser.roles}
						/>
					}
				/>

				{/* ------  ADMISSION MANAGEMENT              */}
				<Route
					path={pages.admissionsDetails.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.admissionsDetails.label}`}
							path={pages.admissionsDetails.path}
							component={AdmissionDetails}
							roles={pages.admissionsDetails.roles}
						/>
					}
				/>
				{/* ------  CREATE EMPLOYEE		              */}
				<Route
					path={pages.createEmployee.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.createEmployee.label}`}
							path={pages.createEmployee.path}
							component={CreateEmployee}
							roles={pages.createEmployee.roles}
						/>
					}
				/>

				{/* -------------- LIST PROPOSALS 				  */}
				<Route
					path={pages.proposals.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.proposals.label}`}
							path={pages.proposals.path}
							component={ProposalsList}
							roles={pages.proposals.roles}
						/>
					}
				/>

				{/* -------------- APPROVE PROPOSAL		*/}
				<Route
					path={pages.approveProposal.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.approveProposal.label}`}
							path={pages.approveProposal.path}
							component={ApproveProposal}
							roles={pages.approveProposal.roles}
						/>
					}
				/>

				{/* -------------- REJECT PROPOSAL		*/}
				<Route
					path={pages.rejectProposal.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.rejectProposal.label}`}
							path={pages.rejectProposal.path}
							component={RejectProposal}
							roles={pages.rejectProposal.roles}
						/>
					}
				/>

				{/* ---------- USER PROPOSALS 			*/}
				<Route
					path={pages.userProposals.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.userProposals.label}`}
							path={pages.userProposals.path}
							component={UserProposalsList}
							roles={pages.userProposals.roles}
						/>
					}
				/>

				{/* ---------- SEND PROPOSAL EMAIL		*/}
				<Route
					path={pages.userProposals.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.sendProposalEmail.label}`}
							path={pages.sendProposalEmail.path}
							component={SendProposalEmail}
							roles={pages.sendProposalEmail.roles}
						/>
					}
				/>

				{/* ---------- NEW PROPOSAL				*/}
				<Route
					path={pages.newProposal.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.newProposal.label}`}
							path={pages.newProposal.path}
							component={NewProposal}
							roles={pages.newProposal.roles}
						/>
					}
				/>

				{/* ---------- NEW PROPOSAL WITH TOKEN	*/}
				<Route
					path={pages.newProposalWithToken.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.newProposalWithToken.label}`}
							path={pages.newProposalWithToken.path}
							component={NewProposal}
							roles={pages.newProposalWithToken.roles}
						/>
					}
				/>

				{/* ---------- EDIT PROPOSAL			*/}
				<Route
					path={pages.editProposal.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.editProposal.label}`}
							path={pages.editProposal.path}
							component={NewProposal}
							roles={pages.editProposal.roles}
						/>
					}
				/>

				{/* -------------- EMPLOYEE SALARY CATEGORIES 			*/}
				<Route
					path={pages.employeeSalaryCategories.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.employeeSalaryCategories.label}`}
							path={pages.employeeSalaryCategories.path}
							component={EmployeesList}
							roles={pages.employeeSalaryCategories.roles}
						/>
					}
				/>
				<Route
					path={pages.employeeSalaryCategoriesList.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.employeeSalaryCategoriesList.label}`}
							path={pages.employeeSalaryCategoriesList.path}
							component={EmployeeSalaryCategoriesList}
							roles={pages.employeeSalaryCategoriesList.roles}
						/>
					}
				/>
				<Route
					path={pages.newEmployeeSalary.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.newEmployeeSalary.label}`}
							path={pages.newEmployeeSalary.path}
							component={NewEmployeeSalary}
							roles={pages.newEmployeeSalary.roles}
						/>
					}
				/>

				<Route
					path={pages.editEmployeeSalary.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.editEmployeeSalary.label}`}
							path={pages.editEmployeeSalary.path}
							component={NewEmployeeSalary}
							roles={pages.editEmployeeSalary.roles}
						/>
					}
				/>

				<Route
					path={pages.approveCostSalaryCategory.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.approveCostSalaryCategory.label}`}
							path={pages.approveCostSalaryCategory.path}
							component={ApproveCostSalaryCategory}
							roles={pages.approveCostSalaryCategory.roles}
						/>
					}
				/>

				<Route
					path={pages.rejectCostSalaryCategory.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.rejectCostSalaryCategory.label}`}
							path={pages.rejectCostSalaryCategory.path}
							component={RejectCostSalaryCategory}
							roles={pages.rejectCostSalaryCategory.roles}
						/>
					}
				/>

				{/* ---------- CSC AUDIT LOG			*/}
				<Route
					path={pages.cscAuditLog.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.cscAuditLog.label}`}
							path={pages.cscAuditLog.path}
							component={CscAuditLog}
							roles={pages.cscAuditLog.roles}
						/>
					}
				/>

				{/* ---------- CONTRACT MANAGEMENT			*/}
				<Route
					path={pages.contractManagement.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.contractManagement.label}`}
							path={pages.contractManagement.path}
							component={ContractManagement}
							roles={pages.contractManagement.roles}
						/>
					}
				/>

				{/* ---------- EMPLOYEE CATEGORIES MANAGEMENT			*/}
				<Route
					path={pages.collaborationTypesManagment.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.collaborationTypesManagment.label}`}
							path={pages.collaborationTypesManagment.path}
							component={EmployeeCategoriesManagment}
							roles={pages.collaborationTypesManagment.roles}
						/>
					}
				/>
				<Route
					path={pages.editEmployeeCategory.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.editEmployeeCategory.label}`}
							path={pages.editEmployeeCategory.path}
							component={EditCollaborationType}
							roles={pages.editEmployeeCategory.roles}
						/>
					}
				/>

				<Route
					path={pages.editEmployeeCategoryProposal.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.editEmployeeCategoryProposal.label}`}
							path={pages.editEmployeeCategoryProposal.path}
							component={EditCollaborationType}
							roles={pages.editEmployeeCategoryProposal.roles}
						/>
					}
				/>

				<Route
					path={pages.approveEmployeeCategory.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.approveEmployeeCategory.label}`}
							path={pages.approveEmployeeCategory.path}
							component={ApproveCollaborationType}
							roles={pages.approveEmployeeCategory.roles}
						/>
					}
				/>

				<Route
					path={pages.rejectEmployeeCategory.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.rejectEmployeeCategory.label}`}
							path={pages.rejectEmployeeCategory.path}
							component={RejectCollaborationType}
							roles={pages.rejectEmployeeCategory.roles}
						/>
					}
				/>

				<Route
					path={pages.collaborationTypeList.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.collaborationTypeList.label}`}
							path={pages.collaborationTypeList.path}
							component={CollaborationTypeList}
							roles={pages.collaborationTypeList.roles}
						/>
					}
				/>

				{/* -------------- FINANTIAL MANAGEMENT	*/}
				{/* ------ BUDGET				*/}
				<Route
					path={pages.budget.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.budget.label}`}
							path={pages.budget.path}
							component={Budget}
							roles={pages.budget.roles}
						/>
					}
				/>

				{/* ------ EDIT BUDGET				*/}
				<Route
					path={pages.editBudget.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.editBudget.label}`}
							path={pages.editBudget.path}
							component={EditBudget}
							roles={pages.editBudget.roles}
						/>
					}
				/>

				{/* ------ BUDGET				*/}
				<Route
					path={pages.budget.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.budget.label}`}
							path={pages.budget.path}
							component={Budget}
							roles={pages.budget.roles}
						/>
					}
				/>

				{/* -------------- SKILLS AND RESUMES	*/}
				{/* ------ MANAGE SKILLS				*/}
				<Route
					path={pages.manageSkills.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.manageSkills.label}`}
							path={pages.manageSkills.path}
							component={ManageSkills}
							roles={pages.manageSkills.roles}
						/>
					}
				/>

				{/* ------ SKILLS EVALUATION			*/}
				<Route
					path={pages.skillsEvaluation.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.skillsEvaluation.label}`}
							path={pages.skillsEvaluation.path}
							component={SkillsEvaluation}
							roles={pages.skillsEvaluation.roles}
						/>
					}
				/>

				{/* ------ SKILLS FEEDBACK				*/}
				<Route
					path={pages.skillsFeedback.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.skillsFeedback.label}`}
							path={pages.skillsFeedback.path}
							component={SkillsFeedback}
							roles={pages.skillsFeedback.roles}
						/>
					}
				/>

				{/* ------ SEARCH RESUMES             	*/}
				<Route
					path={pages.searchResumes.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.searchResumes.label}`}
							path={pages.searchResumes.path}
							component={SearchResumes}
							roles={pages.searchResumes.roles}
						/>
					}
				/>

				{/* -------------- Approvals  		*/}
				{/* ------ Timesheet */}
				<Route
					path={pages.timesheets.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.timesheets.label}`}
							path={pages.timesheets.path}
							component={Timesheet}
							roles={pages.timesheets.roles}
						/>
					}
				/>

				{/* ------ Expense */}
				<Route
					path={pages.expenses.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.expenses.label}`}
							path={pages.expenses.path}
							component={Expense}
							roles={pages.expenses.roles}
						/>
					}
				/>

				{/* ------ TimeOff */}
				<Route
					path={pages.timeOff.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.timeOff.label}`}
							path={pages.timeOff.path}
							component={TimeOff}
							roles={pages.timeOff.roles}
						/>
					}
				/>

				{/* -------------- Administration  		*/}
				{/* ------ ACCESS CONTROL				*/}
				<Route
					path={pages.accessControl.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.accessControl.label}`}
							path={pages.accessControl.path}
							component={AccessControl}
							roles={pages.accessControl.roles}
						/>
					}
				/>

				{/* ------ ACCESS CONTROL				*/}
				<Route
					path={pages.accessControlAddUser.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.accessControlAddUser.label}`}
							path={pages.accessControlAddUser.path}
							component={AccessControlAddUser}
							roles={pages.accessControlAddUser.roles}
						/>
					}
				/>

				{/* ------ RELEASE NOTES				*/}
				<Route
					path={pages.releaseNotes.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.releaseNotes.label}`}
							path={pages.releaseNotes.path}
							component={ReleaseNotes}
							roles={pages.releaseNotes.roles}
						/>
					}
				/>

				{/* ------ SEND RELEASE NOTES EMAIL      */}
				<Route
					path={pages.sendReleaseNotesEmail.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.sendReleaseNotesEmail.label}`}
							path={pages.sendReleaseNotesEmail.path}
							component={SendReleaseNotesEmail}
							roles={pages.sendReleaseNotesEmail.roles}
						/>
					}
				/>

				{/* ------ JOB EXECUTIONS      */}
				<Route
					path={pages.jobExecutions.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.jobExecutions.label}`}
							path={pages.jobExecutions.path}
							component={JobExecutions}
							roles={pages.jobExecutions.roles}
						/>
					}
				/>

				{/* ------ APP ROLES      */}
				<Route
					path={pages.roles.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.roles.label}`}
							path={pages.roles.path}
							component={Roles}
							roles={pages.roles.roles}
						/>
					}
				/>

				{/* ------ APP LINKS      */}
				<Route
					path={pages.appLinks.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.appLinks.label}`}
							path={pages.appLinks.path}
							component={AppLinks}
							roles={pages.appLinks.roles}
						/>
					}
				/>

				{/* ------ USERS      */}
				<Route
					path={pages.users.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.users.label}`}
							path={pages.users.path}
							component={Users}
							roles={pages.users.roles}
						/>
					}
				/>

				{/* ------ EMAIL TEMPLATES				*/}
				<Route
					path={pages.emailTemplates.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.emailTemplates.label}`}
							path={pages.emailTemplates.path}
							component={EmailTemplates}
							roles={pages.emailTemplates.roles}
						/>
					}
				/>

				{/* ------ FILE TEMPLATES				*/}
				<Route
					path={pages.fileTemplates.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.fileTemplates.label}`}
							path={pages.fileTemplates.path}
							component={FileTemplates}
							roles={pages.fileTemplates.roles}
						/>
					}
				/>

				{/* ------ CACHE MANAGEMENT				*/}
				<Route
					path={pages.cacheManagement.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.cacheManagement.label}`}
							path={pages.cacheManagement.path}
							component={CacheManagement}
							roles={pages.cacheManagement.roles}
						/>
					}
				/>

				{/* ------ RENEW DIGITAL SIGN AUTHORIZATION CODE				*/}
				<Route
					path={pages.renewAuthorizationCode.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.renewAuthorizationCode.label}`}
							path={pages.renewAuthorizationCode.path}
							component={RenewAuthorizationCode}
							roles={pages.renewAuthorizationCode.roles}
						/>
					}
				/>

				{/* ------ PLANNER			*/}
				<Route
					path={pages.planner.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.planner.label}`}
							path={pages.planner.path}
							component={Planner}
							roles={pages.planner.roles}
						/>
					}
				/>
				{/* ------ PLANNER Mobiscroll	*/}
				<Route
					path={pages.plannerMobiscroll.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.plannerMobiscroll.label}`}
							path={pages.plannerMobiscroll.path}
							component={PlannerMobiscroll}
							roles={pages.plannerMobiscroll.roles}
						/>
					}
				/>

				{/* ------ USER GUIDES      */}
				<Route
					path={pages.userGuides.path}
					element={
						<AuthRoute
							title={`${projectName} ${pages.userGuides.label}`}
							path={pages.userGuides.path}
							component={UserGuides}
							roles={pages.userGuides.roles}
						/>
					}
				/>

				{/* -------------- SYSTEM -------------- */}
				<Route path="/404" element={NotFound} />
				<Route path="/403" element={UnauthorizedPage} />
				<Route
					path={pages.userGuides.path}
					element={
						<AuthRoute title={`${projectName} ${pages.userGuides.label}`} path={'*'} redirect="/404" />
					}
				/>
			</Routers>
		</>
	);
};

export default Routes;
