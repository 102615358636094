import React, { useEffect, useState } from 'react';
import { History } from 'history';
import { Store } from 'redux';

//* AZURE AD PROVIDER */
import { InteractionType } from '@azure/msal-browser';
import {
	MsalProvider,
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	MsalAuthenticationTemplate
} from '@azure/msal-react';

//* PROJECT IMPORTS */
import Login from 'pages/General/Login';

/** the App is the main project page, where the header and sidebar
 * are rendered, and the routing goes inside of that component */
import App from 'components/App';

//* AZURE AD Project ReduxStore and Connection */
import { pca } from '../../AzureMSAL/authProvider';
import { INSTANCE } from 'lib/utils/constants';
import CommonProviders from '../CommonProviders';
import { CompanyState } from 'typings/store/companyTypes';

import BigCompanyLogo from '../../../../assets/images/inetsec_nome.png';

interface IAzureWrapper {
	history: History;
	store: Store;
}

const AzureWrapper: React.FC<IAzureWrapper> = ({ store, history }) => {
	return (
		// Msal's control for authentication states
		<MsalProvider instance={pca}>
			<CommonProviders store={store} history={history}>
				<App />
			</CommonProviders>
		</MsalProvider>
	);
};

export default AzureWrapper;
