import React, { useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const Prompt = ({ when, message }) => {
	const { navigator } = React.useContext(NavigationContext);

	useEffect(() => {
		if (!when) return;
		const push = navigator.push;
		const replace = navigator.replace;
		const blocker = (method, ...args) => {
			if (window.confirm(message)) {
				method(...args);
			}
		};
		navigator.push = (...args) => blocker(push, ...args);
		navigator.replace = (...args) => blocker(replace, ...args);
		return () => {
			navigator.push = push;
			navigator.replace = replace;
		};
	}, [when, navigator]);

	useEffect(() => {
		const handleBeforeUnload = e => {
			if (when) {
				e.preventDefault();
				e.returnValue = '';
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => window.removeEventListener('beforeunload', handleBeforeUnload);
	}, [when]);

	return null; // This component renders nothing
};

export default Prompt;
