/* eslint-disable jsx-a11y/anchor-is-valid */
//* EXTERNAL LIBS
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//* EXTERNAL LIBS --> MUI
import { Grid, Button } from '@mui/material';
import { Send } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, BackPage } from 'xpand-ui/core';
import { Input, TextEditor } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { IPageTitle } from 'components/App/TitleProvider';
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { SendReleaseNoteEmailProps } from '.';
import { IReleaseNotesEmail } from 'typings/store/admin/administration';
import { getLSField } from 'lib/utils/cookies';
import { useParams } from 'react-router';

interface ISendReleaseNoteEmail extends SendReleaseNoteEmailProps, IPageTitle {
	goToPage: (path: string) => void;
	match: Match<{
		id: string;
		name: string;
		projName: string;
	}>;
}

const SendReleaseNotesEmail: FC<ISendReleaseNoteEmail> = ({
	goToPage,
	administration,
	getReleaseNotesEmail,
	sendPostReleaseNotesEmail
}) => {
	const classes = useStyles();
	const params = useParams()
	const { releaseNotesEmail, releaseNotesEmailAction, loading } = administration;
	const [isFirstLoaded, setFirstLoaded] = useState(false);
	const id = params?.id;
	const name = params?.name;
	const projName = params?.projName;
	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(() => {
		if (releaseNotesEmailAction && !releaseNotesEmailAction?.code) {
			goToPage(`/admin/administration/releaseNotes`);
		}
	}, [releaseNotesEmailAction]);

	useEffect(() => {
		if (isFirstLoaded === false) {
			getReleaseNotesEmail(id, name, projName);
			setFirstLoaded(true);
		}
		if (releaseNotesEmail) {
			setValue('mailBody', releaseNotesEmail.mailBody);
			setValue('cc', releaseNotesEmail.cc);
			setValue('subject', releaseNotesEmail.subject);
			setValue('to', releaseNotesEmail.to);
		}
	}, [releaseNotesEmail]);

	const onSubmit = (data: IReleaseNotesEmail) => {
		sendPostReleaseNotesEmail(data);

		goToPage(`/admin/administration/releaseNotes`);
	};

	const isLoading = releaseNotesEmail === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			{/* Forced window reload is necessary due to limitations with the text editor component */}
			<BackPage path={`/admin/administration/releaseNotes`} action={goToPage} />
			<div className={classes.root}>
				<form id="form-send-releaseNotes-email" onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={4}>
						<Grid item {...sizes[8]}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextEditor
										name="mailBody"
										label="Message"
										height={700}
										required
										control={control}
										errors={errors}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item {...sizes[4]}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Input name="to" label="To" required control={control} errors={errors} />
								</Grid>
								<Grid item xs={12}>
									<Input name="cc" label="CC" required control={control} errors={errors} />
								</Grid>
								<Grid item xs={12}>
									<Input name="subject" label="Subject" required control={control} errors={errors} />
								</Grid>
								<Grid item xs={12} style={{ textAlign: 'center' }}>
									<Button
										color="primary"
										style={{ margin: '100px auto' }}
										variant="contained"
										type="submit"
										endIcon={<Send />}
										disabled={getLSField('impersonate_userInfo') ? true : false}>
										Send Release Notes Email
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</div>
		</>
	);
};

export default withLayout(SendReleaseNotesEmail);
