/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useForm } from 'react-hook-form';
import moment from 'moment';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, BackPage, NoData, ActionFooter, TreeView, Dialog } from 'xpand-ui/core';
import { Input, InfoField, Checkbox } from 'xpand-ui/forms';
import { parseDateToManage } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { ErrorPage } from 'xpand-ui/lab';

//* TYPINGS
import { IDepartmentData } from 'typings/store/admin/budget';
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { Roles, actionPermission } from 'lib/roles';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { schema, defaultValues } from './yupSchema';
import { EditBudgetProps } from '.';
import {
	//* helpers
	createTreeFromCompanyTree,
	createTreeFromLines,
	//* render
	createHeader,
	createIncomeLine,
	createCostsLine,
	createEmployeeCostsLine,
	createNonEmployeeCostsLine,
	createTotalsLine
} from './utils';
import { getLSField } from 'lib/utils/cookies';
import { useParams } from 'react-router-dom';

//* COMPONENT INTERFACES
interface IEditBudget extends EditBudgetProps {
	match: Match<{
		budgetId: number;
	}>;
	goToPage: (path: string) => void;
}
export interface INodeSelected {
	company: null | string;
	businessUnit: null | string;
	division: null | string;
	department: null | string;
}

//* COMPONENT
const EditBudget: FC<IEditBudget> = ({
	match,
	goToPage,
	budget,
	clearBudgetError,
	getBudgetEditInfo,
	// getBudgetDepProjects,
	clearBudgetAction,
	clearBudgetInfo,
	submitPUTNewBudget
}) => {
	const classes = useStyles();
	const params = useParams();
	const { budgetId } = params;

	const { loading, error, budgetInfo } = budget;

	const isBudgetAdmin = useMemo(() => budgetInfo && actionPermission(Roles.CP_ROLE_BUDGET_ADMIN), [budgetInfo]);

	const [confirmModal, setConfirmModal] = useState<unknown>(null);
	const [refreshTable, setRefreshTable] = useState<boolean>(false);
	const [nodeSelected, setNodeSelected] = useState<INodeSelected>({
		company: null,
		businessUnit: null,
		division: null,
		department: null
	});
	const [currentSelected, setCurrentSelected] = useState<IDepartmentData>({
		departmentId: '',
		departmentalBudgetFlag: false,
		incomeQ1: '',
		incomeQ2: '',
		incomeQ3: '',
		incomeQ4: '',
		incomeQ5: '',
		employeeCostQ1: '',
		employeeCostQ2: '',
		employeeCostQ3: '',
		employeeCostQ4: '',
		employeeCostQ5: '',
		nonEmployeeCostQ1: '',
		nonEmployeeCostQ2: '',
		nonEmployeeCostQ3: '',
		nonEmployeeCostQ4: '',
		nonEmployeeCostQ5: ''
	});

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
		getValues,
		setValue,
		watch
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const formData = watch();

	useEffect(() => {
		clearBudgetAction();
		return () => {
			clearBudgetInfo();
		};
	}, []);

	useEffect(() => {
		if (!budgetInfo && budgetId && !error) {
			getBudgetEditInfo(budgetId);
		} else if (budgetInfo) {
			const {
				companies,
				budget: { history, lines, ...budgetPayload }
			} = budgetInfo;
			const linesAux = createTreeFromCompanyTree(companies, lines);
			reset({ ...budgetPayload, lines: linesAux, approvedFlag: Boolean(budgetPayload.approvalDate) || false });
			!nodeSelected.company &&
				setNodeSelected({
					company: Object.keys(linesAux)[0],
					businessUnit: null,
					division: null,
					department: null
				});
			setRefreshTable(true);
		}
	}, [budgetInfo]);

	useEffect(() => {
		if (
			currentSelected.departmentId !== nodeSelected.department &&
			nodeSelected.company &&
			nodeSelected.businessUnit &&
			nodeSelected.division &&
			nodeSelected.department
		) {
			const depData =
				formData.lines[nodeSelected.company].data[nodeSelected.businessUnit].data[nodeSelected.division].data[
					nodeSelected.department
				].data;
			setCurrentSelected({ ...depData, departmentId: nodeSelected.department });
		}
	}, [nodeSelected.department]);

	useEffect(() => {
		refreshTable && setRefreshTable(false);
	}, [refreshTable]);

	const prepareToSubmitEdit = (fromModal: boolean) => {
		const payload = watch();

		if (fromModal) {
			payload.approvalDate = null;
			payload.approvedFlag = false;
			setValue('approvedFlag', false);
			setValue('approvalDate', null);

			submitPUTNewBudget(payload.id, payload);
			setConfirmModal(false);
		} else if (
			payload.approvedFlag === true &&
			payload.approvedFlag !== null &&
			budgetInfo?.budget.statusId === 3
		) {
			setConfirmModal(true);
		} else if (
			payload.approvedFlag === true &&
			payload.approvedFlag !== null &&
			budgetInfo?.budget.statusId !== 3
		) {
			payload.approvedFlag = true;
			payload.approvalDate = parseDateToManage(moment());
			setValue('approvalDate', parseDateToManage(moment()));
			setValue('approvedFlag', true);

			submitPUTNewBudget(payload.id, payload);
		} else {
			submitPUTNewBudget(payload.id, payload);
		}
	};

	const onSubmit = () => {
		prepareToSubmitEdit(false);
	};

	const footerActions = [
		{
			id: 'submit',
			label: 'Submit',
			onClick: () => ({}),
			type: 'submit',
			form: 'edit-budget',
			variant: 'contained',
			disabled: getLSField('impersonate_userInfo')
		}
	];

	const createTableData = () => {
		const { company, businessUnit, division, department } = nodeSelected;
		const { lines } = watch();

		const getTotalsTableLine = (payload: any) => ({
			...payload,
			totalQ1: Number(payload.employeeCostQ1) + Number(payload.nonEmployeeCostQ1),
			totalQ2: Number(payload.employeeCostQ2) + Number(payload.nonEmployeeCostQ2),
			totalQ3: Number(payload.employeeCostQ3) + Number(payload.nonEmployeeCostQ3),
			totalQ4: Number(payload.employeeCostQ4) + Number(payload.nonEmployeeCostQ4),
			totalQ5: Number(payload.employeeCostQ5) + Number(payload.nonEmployeeCostQ5)
		});

		const INITIAL_STATE = {
			employeeCostQ1: '0',
			employeeCostQ2: '0',
			employeeCostQ3: '0',
			employeeCostQ4: '0',
			employeeCostQ5: '0',
			incomeQ1: '0',
			incomeQ2: '0',
			incomeQ3: '0',
			incomeQ4: '0',
			incomeQ5: '0',
			nonEmployeeCostQ1: '0',
			nonEmployeeCostQ2: '0',
			nonEmployeeCostQ3: '0',
			nonEmployeeCostQ4: '0',
			nonEmployeeCostQ5: '0',
			totalQ1: '0',
			totalQ2: '0',
			totalQ3: '0',
			totalQ4: '0',
			totalQ5: '0'
		};

		const GenSumDepartments = (comp: string, bu: string, div: string) => {
			const helper = lines[comp].data[bu].data[div].data;
			const sumDepartments = { ...INITIAL_STATE };

			Object.keys(helper).forEach(deps => {
				['employeeCostQ', 'incomeQ', 'nonEmployeeCostQ'].forEach(field => {
					sumDepartments[`${field}1`] =
						Number(sumDepartments[`${field}1`]) + Number(helper[deps].data[`${field}1`]);
					sumDepartments[`${field}2`] =
						Number(sumDepartments[`${field}2`]) + Number(helper[deps].data[`${field}2`]);
					sumDepartments[`${field}3`] =
						Number(sumDepartments[`${field}3`]) + Number(helper[deps].data[`${field}3`]);
					sumDepartments[`${field}4`] =
						Number(sumDepartments[`${field}4`]) + Number(helper[deps].data[`${field}4`]);
					sumDepartments[`${field}5`] =
						Number(sumDepartments[`${field}5`]) + Number(helper[deps].data[`${field}5`]);
				});
			});

			return sumDepartments;
		};

		const GenSumDivisions = (comp: string, bu: string) => {
			const helper = lines[comp].data[bu].data;
			const sumDepartments = { ...INITIAL_STATE };

			Object.keys(helper).forEach(divAux => {
				const totDiv = GenSumDepartments(comp, bu, divAux);
				['employeeCostQ', 'incomeQ', 'nonEmployeeCostQ'].forEach(field => {
					sumDepartments[`${field}1`] = Number(sumDepartments[`${field}1`]) + Number(totDiv[`${field}1`]);
					sumDepartments[`${field}2`] = Number(sumDepartments[`${field}2`]) + Number(totDiv[`${field}2`]);
					sumDepartments[`${field}3`] = Number(sumDepartments[`${field}3`]) + Number(totDiv[`${field}3`]);
					sumDepartments[`${field}4`] = Number(sumDepartments[`${field}4`]) + Number(totDiv[`${field}4`]);
					sumDepartments[`${field}5`] = Number(sumDepartments[`${field}5`]) + Number(totDiv[`${field}5`]);
				});
			});

			return sumDepartments;
		};

		const GenSumBusinessUnits = (comp: string) => {
			const helper = lines[comp].data;
			const sumDepartments = { ...INITIAL_STATE };

			Object.keys(helper).forEach(buAux => {
				const totDiv = GenSumDivisions(comp, buAux);
				['employeeCostQ', 'incomeQ', 'nonEmployeeCostQ'].forEach(field => {
					sumDepartments[`${field}1`] = Number(sumDepartments[`${field}1`]) + Number(totDiv[`${field}1`]);
					sumDepartments[`${field}2`] = Number(sumDepartments[`${field}2`]) + Number(totDiv[`${field}2`]);
					sumDepartments[`${field}3`] = Number(sumDepartments[`${field}3`]) + Number(totDiv[`${field}3`]);
					sumDepartments[`${field}4`] = Number(sumDepartments[`${field}4`]) + Number(totDiv[`${field}4`]);
					sumDepartments[`${field}5`] = Number(sumDepartments[`${field}5`]) + Number(totDiv[`${field}5`]);
				});
			});

			return sumDepartments;
		};

		if (!company && !businessUnit && !division && !department) {
			return getTotalsTableLine({
				quarters: ['no Comp', 'no Comp', 'no Comp', 'no Comp', 'no Comp'],
				...INITIAL_STATE
			});
		}

		if (company && !businessUnit && !division && !department) {
			return getTotalsTableLine({
				quarters: ['2020 - Q1', '2020 - Q2', '2020 - Q3', '2020 - Q4', '2021 - Q1'],
				...GenSumBusinessUnits(company)
			});
		}
		if (company && businessUnit && !division && !department) {
			return getTotalsTableLine({
				quarters: ['2020 - Q1', '2020 - Q2', '2020 - Q3', '2020 - Q4', '2021 - Q1'],
				...GenSumDivisions(company, businessUnit)
			});
		}
		if (company && businessUnit && division && !department) {
			return getTotalsTableLine({
				quarters: ['2020 - Q1', '2020 - Q2', '2020 - Q3', '2020 - Q4', '2021 - Q1'],
				...GenSumDepartments(company, businessUnit, division)
			});
		}
		// if (company && businessUnit && division && department) {
		const helper =
			lines[company as string].data[businessUnit as string].data[division as string].data[department as string]
				.data;
		return getTotalsTableLine({
			quarters: ['2020 - Q1', '2020 - Q2', '2020 - Q3', '2020 - Q4', '2021 - Q1'],
			...helper
		});
		// }
	};

	const tableData = createTableData();

	const formName = `lines.${nodeSelected.company}.data.${nodeSelected.businessUnit}.data.${nodeSelected.division}.data.${nodeSelected.department}.data`;

	const isLoading = budgetInfo === null;

	if (error) return handleErrorPage(error, clearBudgetError);

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<BackPage path="/admin/budget" action={goToPage} />
			<Dialog
				modal={{
					open: Boolean(confirmModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmModal(false);
					},
					content: (
						<Typography gutterBottom>
							You are about to edited an approved budget, which will require approval again. Are you sure
							you want to continue?
						</Typography>
					)
				}}
				title="Edit Budget"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setConfirmModal(false)
					},
					{
						id: 'confirmEditBudget',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => prepareToSubmitEdit(true)
					}
				]}
				scroll="body"
			/>
			<form id="edit-budget" onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={4}
					className={classes.budgetToolbar}>
					<Grid item {...sizes[3]}>
						<Input name="customName" label="Budget Name" required control={control} errors={errors} />
					</Grid>
					<Grid item {...sizes[3]}>
						<Checkbox
							color="primary"
							name="approvedFlag"
							disabled={!isBudgetAdmin}
							label="BUDGET APPROVED"
							additionalOnChange={(info: boolean) => {
								(setValue as unknown as any)('approvalDate', info ? parseDateToManage(moment()) : null);
							}}
							control={control}
							errors={errors}
						/>
					</Grid>

					<Grid item {...sizes[3]} />
					<Grid item {...sizes[1]}>
						<InfoField name="year" label="Year" getValues={getValues} />
					</Grid>
					<Grid item {...sizes[2]}>
						<InfoField name="type" label="Type" getValues={getValues} />
					</Grid>
				</Grid>
				<div />
				<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
					<Grid
						item
						{...{
							xs: 12,
							sm: 12,
							md: 12,
							lg: 5,
							xl: 5
						}}>
						<Scrollbars
							style={{ marginTop: '10px', height: 'calc(100vh - 370px)', overflowX: 'hidden' }}
							renderTrackHorizontal={(props: any) => (
								<div {...props} style={{ display: 'none' }} className="track-horizontal" />
							)}>
							<TreeView
								payload={createTreeFromLines(
									classes,
									watch().lines,
									formData,
									nodeSelected,
									(id: INodeSelected) => {
										setNodeSelected(id);
										setRefreshTable(true);
									}
								)}
								customClasses={{ items: classes.items }}
								noData={<NoData />}
								leftActions
								removeDivider // FIXME:
								icons={{
									more: <Add data-component="listItem" color="secondary" />,
									less: <Remove data-component="listItem" color="secondary" />
								}}
							/>
						</Scrollbars>
					</Grid>
					<Grid
						item
						{...{
							xs: 12,
							sm: 12,
							md: 12,
							lg: 7,
							xl: 7
						}}>
						<Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
							{refreshTable ? (
								<LoadingOverlay />
							) : (
								<>
									{createHeader(classes, budgetInfo)}
									{createIncomeLine(classes, tableData, nodeSelected, formName, control, errors)}
									{createCostsLine(classes)}
									{createEmployeeCostsLine(
										classes,
										tableData,
										nodeSelected,
										formName,
										control,
										errors
									)}
									{createNonEmployeeCostsLine(
										classes,
										tableData,
										nodeSelected,
										formName,
										control,
										errors
									)}
									{createTotalsLine(classes, tableData)}
								</>
							)}
						</Grid>
					</Grid>
				</Grid>
				<ActionFooter actions={footerActions} />
			</form>
		</>
	);
};

export default withLayout(EditBudget);
