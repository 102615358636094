/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, LegacyRef, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//* EXTERNAL LIBS --> MUI
import Radio from '@mui/material/Radio';
import { FormControlLabel, Grid } from '@mui/material';
import { RadioGroup } from '@mui/material';
//* EXTERNAL LIBS --> XPAND-UI
import { Checkbox, InfoField } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { IUpdatePrivateData, IUserProfile } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';

//* LOCAL COMPONENT IMPORTS
import { InfoCards } from '../../../utils';
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { getLSField } from 'lib/utils/cookies';

interface ITermsAndConditions {
	isAdmin?: string;
	userProfile: IUserProfile;
	handleApiSubmit: any;
}

const TermsAndConditions: FC<ITermsAndConditions> = ({ isAdmin = undefined, userProfile, handleApiSubmit }) => {
	const classes = useStyles();
	const refSubmitButton = useRef<HTMLDivElement>(null);
	const company = useSelector(state => state.company);
	const {
		handleSubmit,
		control,
		reset,
		watch,
		getValues,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const userERPData = userProfile?.user?.erpData;

	const getInitialData = () => ({
		clothingSize: userERPData?.privateData?.clothingSize || '',
		disabilities: userERPData?.privateData?.disabilities || '',
		foodAllergiesIntolerances: userERPData?.privateData?.foodAllergiesIntolerances || '',
		healthConditions: userERPData?.privateData?.healthConditions || '',
		biometricSysConsent: userERPData?.privateData?.biometricSysConsent || true,
		intranetSysConsent: userERPData?.privateData?.intranetSysConsent || true,
		promoMediaConsent: userERPData?.privateData?.promoMediaConsent || true,
		demogInfoEngConsent: userERPData?.privateData?.demogInfoEngConsent || false,
		demogInfoTraConsent: userERPData?.privateData?.demogInfoTraConsent || false,
		familyInfoConsent: userERPData?.privateData?.familyInfoConsent || false,
		lifeHistoryConsent: userERPData?.privateData?.lifeHistoryConsent || false,
		clotheSizeConsent: userERPData?.privateData?.clotheSizeConsent || false,
		sensitiveInfoConsent: userERPData?.privateData?.sensitiveInfoConsent || false,
		contactInfoConsent: userERPData?.privateData?.contactInfoConsent || false
	});
	useEffect(() => {
		if (userProfile) {
			reset(getInitialData());
		}
	}, [userProfile]);

	const onSubmit = (data: IUpdatePrivateData) => {
		if (isAdmin) return null;

		if (JSON.stringify(data) !== JSON.stringify(getInitialData())) {
			handleApiSubmit([
				{
					type: 'privateData',
					data: {
						...data,
						biometricSysConsent: true,
						intranetSysConsent: true,
						promoMediaConsent: true
					}
				}
			]);
		} else {
			// eslint-disable-next-line no-alert
			addNotification('info', 'Nothing to Update');
		}

		return null;
	};

	const isImpersonate = () => {
		if (getLSField('impersonate_userInfo')) {
			return true;
		} else {
			return false;
		}
	};

	const checkClicked = () => refSubmitButton?.current?.click();

	if (!userProfile) return null;

	return (
		<InfoCards title="" form="form-profile-terms-info" classes={classes} editMode={null} setEditMode={null}>
			<form id="form-profile-terms-info" onSubmit={handleSubmit(() => onSubmit(watch()))}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
					<Grid item {...sizes[12]}>
						<span style={{ fontSize: '16px', color: '#3D464D' }}>
							I acknowledge {company.companyText}'s legitimate interest in collecting my biometric data,
							such as fingerprints or eyepiece, for exclusive use in {company.companyText}'s facilities
							access control systems, thereby giving my express consent to the collection, processing and
							use of such data for said purposes, leaving {company.companyText} as the entity responsible
							for complying with obligations regarding the protection of personal data, in accordance with
							the applicable law, namely for its confidentiality and elimination.
						</span>
						<RadioGroup
							row
							aria-labelledby="demo-biometricSysConsent"
							name="biometricSysConsent"
							value={getValues('biometricSysConsent')}
							onChange={e => {
								if (!isAdmin) {
									checkClicked();
									setValue('biometricSysConsent', e.target.value);
								}
							}}>
							<FormControlLabel disabled value={true} control={<Radio />} label="Yes" />
							<FormControlLabel disabled value={false} control={<Radio />} label="No" />
						</RadioGroup>
					</Grid>
					<Grid item {...sizes[12]}>
						<span style={{ fontSize: '16px', color: '#3D464D' }}>
							I acknowledge {company.companyText}'s legitimate interest in collecting my personal data,
							such as facial photography and personal mobile phone, for exclusive use in its internal
							information systems, for work purposes and for internal and corporate organization and
							operation, giving to {company.companyText} my express consent to the collection, processing
							and use of such data for said purposes, leaving {company.companyText} as the entity
							responsible for complying with obligations regarding the protection of personal data, in
							accordance with the applicable law, namely for its confidentiality and elimination.
						</span>
						<RadioGroup
							row
							aria-labelledby="demo-intranetSysConsent"
							name="intranetSysConsent"
							value={getValues('intranetSysConsent')}
							onChange={e => {
								if (!isAdmin) {
									checkClicked();
									setValue('intranetSysConsent', e.target.value);
								}
							}}>
							<FormControlLabel disabled value={true} control={<Radio />} label="Yes" />
							<FormControlLabel disabled value={false} control={<Radio />} label="No" />
						</RadioGroup>
					</Grid>
					<Grid item {...sizes[12]}>
						<span style={{ fontSize: '16px', color: '#3D464D' }}>
							I acknowledge {company.companyText}'s legitimate interest in collecting of my personal data,
							such as personal image in photographs, videos or any media type, its recording, editing and
							public display in any media in Portugal and abroad, solely for the purpose of
							{company.companyText} using it for its institutional communication and promotional
							marketing, thereby giving {company.companyText}, my express consent to the collection,
							processing and use of such data for those mentioned purposes, leaving {company.companyText}{' '}
							as the entity responsible for complying with obligations regarding the protection of
							personal data, in accordance with the applicable law, namely for its confidentiality and
							elimination.
						</span>
						<RadioGroup
							row
							aria-labelledby="demo-promoMediaConsent"
							name="promoMediaConsent"
							value={getValues('promoMediaConsent')}
							onChange={e => {
								if (!isAdmin) {
									checkClicked();
									setValue('promoMediaConsent', e.target.value);
								}
							}}>
							<FormControlLabel disabled value={true} control={<Radio />} label="Yes" />
							<FormControlLabel disabled value={false} control={<Radio />} label="No" />
						</RadioGroup>
					</Grid>
					<Grid item {...sizes[12]}>
						<span style={{ fontSize: '16px', color: '#3D464D' }}>
							I give my consent to the use of my identification, contact information and demographic
							information, namely date of birth and gender, in the context of Engagement initiatives.
						</span>
						<RadioGroup
							row
							aria-labelledby="demo-demogInfoEngConsent"
							name="demogInfoEngConsent"
							value={getValues('demogInfoEngConsent')}
							onChange={e => {
								if (!isAdmin) {
									checkClicked();
									setValue('demogInfoEngConsent', e.target.value);
								}
							}}>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={true}
								control={<Radio />}
								label="Yes"
							/>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={false}
								control={<Radio />}
								label="No"
							/>
						</RadioGroup>
					</Grid>
					<Grid item {...sizes[12]}>
						<span style={{ fontSize: '16px', color: '#3D464D' }}>
							I give my consent to the use of my identification, contact information and demographic
							information, namely date of birth, gender and nationality for the purpose of issuing
							training certificates when necessary.
						</span>
						<RadioGroup
							row
							aria-labelledby="demo-demogInfoTraConsent"
							name="demogInfoTraConsent"
							value={getValues('demogInfoTraConsent')}
							onChange={e => {
								if (!isAdmin) {
									checkClicked();
									setValue('demogInfoTraConsent', e.target.value);
								}
							}}>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={true}
								control={<Radio />}
								label="Yes"
							/>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={false}
								control={<Radio />}
								label="No"
							/>
						</RadioGroup>
					</Grid>
					<Grid item {...sizes[12]}>
						<span style={{ fontSize: '16px', color: '#3D464D' }}>
							I give my consent to the use of my family information, namely marital status, information
							about next of kin, names and ages of dependents and family contact (similar to emergency
							contact), in the context of Engagement initiatives.
						</span>
						<RadioGroup
							row
							aria-labelledby="demo-familyInfoConsent"
							name="familyInfoConsent"
							value={getValues('familyInfoConsent')}
							onChange={e => {
								if (!isAdmin) {
									checkClicked();
									setValue('familyInfoConsent', e.target.value);
								}
							}}>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={true}
								control={<Radio />}
								label="Yes"
							/>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={false}
								control={<Radio />}
								label="No"
							/>
						</RadioGroup>
					</Grid>
					<Grid item {...sizes[12]}>
						<span style={{ fontSize: '16px', color: '#3D464D' }}>
							I give my consent to the use of information about my life's history, such as hobbies or
							personal interests, school history and personal life experiences, in the context of
							Engagement and Company Culture activities.
						</span>
						<RadioGroup
							row
							aria-labelledby="demo-lifeHistoryConsent"
							name="lifeHistoryConsent"
							value={getValues('lifeHistoryConsent')}
							onChange={e => {
								if (!isAdmin) {
									checkClicked();
									setValue('lifeHistoryConsent', e.target.value);
								}
							}}>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={true}
								control={<Radio />}
								label="Yes"
							/>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={false}
								control={<Radio />}
								label="No"
							/>
						</RadioGroup>
					</Grid>
					<Grid item {...sizes[12]}>
						<span style={{ fontSize: '16px', color: '#3D464D' }}>
							I give my consent to the use of information about my clothing size for Engagement and
							Company Culture initiatives.
						</span>
						<RadioGroup
							row
							aria-labelledby="demo-clotheSizeConsent"
							name="clotheSizeConsent"
							value={getValues('clotheSizeConsent')}
							onChange={e => {
								if (!isAdmin) {
									checkClicked();
									setValue('clotheSizeConsent', e.target.value);
								}
							}}>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={true}
								control={<Radio />}
								label="Yes"
							/>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={false}
								control={<Radio />}
								label="No"
							/>
						</RadioGroup>
					</Grid>
					<Grid item {...sizes[12]}>
						<span style={{ fontSize: '16px', color: '#3D464D' }}>
							I give my consent to the use of sensitive information, namely health conditions, food
							allergies, disabilities or other for the organization of Engagement and Company Culture
							initiatives.
						</span>
						<RadioGroup
							row
							aria-labelledby="demo-sensitiveInfoConsent"
							name="sensitiveInfoConsent"
							value={getValues('sensitiveInfoConsent')}
							onChange={e => {
								if (!isAdmin) {
									checkClicked();
									setValue('sensitiveInfoConsent', e.target.value);
								}
							}}>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={true}
								control={<Radio />}
								label="Yes"
							/>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={false}
								control={<Radio />}
								label="No"
							/>
						</RadioGroup>
					</Grid>
					<Grid item {...sizes[12]}>
						<span style={{ fontSize: '16px', color: '#3D464D' }}>
							I give my consent to the use of my contact information (Name, mobile, corporate e-mail and
							Address), in order to enjoy and receive discounts, merchandise and benefits, associated with
							engagement activities. This data will exclusively be used for this purpose and deleted
							afterwards.
						</span>
						<RadioGroup
							row
							aria-labelledby="demo-contactInfoConsent"
							name="contactInfoConsent"
							value={getValues('contactInfoConsent')}
							onChange={e => {
								if (!isAdmin) {
									checkClicked();
									setValue('contactInfoConsent', e.target.value);
								}
							}}>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={true}
								control={<Radio />}
								label="Yes"
							/>
							<FormControlLabel
								disabled={Boolean(isAdmin) || isImpersonate()}
								value={false}
								control={<Radio />}
								label="No"
							/>
						</RadioGroup>
					</Grid>
					{!isAdmin && (
						<Grid item {...sizes[12]}>
							<InfoField
								fontSize="lg"
								label={
									<>
										<p style={{ fontSize: '16px', color: '#3D464D', whiteSpace: 'pre-line' }}>
											You can access the Employee Privacy Statement{' '}
											<a
												style={{ fontSize: '16px' }}
												href={company.urlConfluenceProfile}
												rel="noopener noreferrer"
												target="_blank">
												here.
											</a>{' '}
											Also, you can ask for help or update your personal data via{' '}
											<a
												style={{ fontSize: '16px' }}
												href={company.urlServiceDeskCIProfile}
												rel="noopener noreferrer"
												target="_blank">
												Service Desk - Security and Data Protection.
											</a>
										</p>
									</>
								}
							/>
						</Grid>
					)}
				</Grid>

				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button hidden ref={refSubmitButton as unknown as LegacyRef<HTMLButtonElement>} type="submit" />
			</form>
		</InfoCards>
	);
};

export default TermsAndConditions;
